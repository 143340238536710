import React,{useEffect} from 'react'
import { Col, Container, Row, InputGroup, FormControl, Button } from 'react-bootstrap'
import logo from '../assets/img/bharat-token.png'
import {Link} from 'react-router-dom'
import {FaFacebookF, FaTwitter, FaInstagram, FaTelegramPlane, FaDiscord} from 'react-icons/fa'

function Footer() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

  return (
    <footer>
        <Container>
            <Row>
                <Col sm={3}>
                    <Link to='/'><img src={logo} alt="logo" className="logo img-fluid w-75" style={{filter: "invert(1)"}} /></Link>
                    
                </Col>
                <Col sm={3}>
                    <ul className='list-unstyled'>
                        <li ><Link to='/about'>About Us</Link></li>
                        <li><Link to='/contact'>Contact Us</Link></li>
                    </ul>
                </Col>
                <Col sm={3}>
                    <ul className='list-unstyled '>
                        <li><Link to='/privacy'>Privacy Policy</Link></li>
                        <li><Link to='/term'>Terms & Conditions</Link></li>
                    </ul>
                </Col>
                <Col sm={3}>
                    <ul className='social_link_head'>
                        <h5>Social Link</h5>
                    </ul>
                    <ul className='list-unstyled social_links'>
                        <li><a href=" https://www.facebook.com/Bharat-NFT-105203082074857" target="_blank"><FaFacebookF/></a></li>
                        <li><a href=" https://twitter.com/Bharattokens" target="_blank"><FaTwitter/></a></li>
                        <li><a href=" https://www.instagram.com/bharatnfttoken/" target="_blank"><FaInstagram/></a></li>
                        <li><a href=" https://t.me/bharattoken" target="_blank"><FaTelegramPlane/></a></li>
                        <li><a href=" https://discord.gg/BGqVUz3p" target="_blank"><FaDiscord/></a></li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </footer>
  )
}

export default Footer