import React, { useState, useEffect } from "react";
import Header from "./Header";
import ProductCard from "./ProductCard";
import axios from "axios";
import Footer from "./Footer";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
// import { ImSpinner7 } from 'react-icons/im'

var BASE_URL = process.env.REACT_APP_BASE_URL;

function AllCollections() {
  const [topCollections, setTopCollections] = useState("");
  const [load, setLoading] = useState(true);

  async function topCreators() {
    await axios.get(`${BASE_URL}/getAllCollection`).then(async (data) => {
      if (data.data.result) {
        setTopCollections(data.data.result);
        setLoading(false);
      } else {
        console.log("loader2");
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  }
  console.log("all Collections", topCollections);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    topCreators();
  }, []);

  // =============all Collections pagination start===============
  const [currentItemsCollections, setCurrentItemsCollections] = useState(null);
  const [pageCountCollections, setPageCountCollections] = useState(0);

  const [itemOffsetCollections, setItemOffsetCollections] = useState(0);
  const itemsPerPageCollections = 12;
  useEffect(() => {
    const endOffsetCollections = itemOffsetCollections + itemsPerPageCollections;

    setCurrentItemsCollections(topCollections.slice(itemOffsetCollections, endOffsetCollections));
    setPageCountCollections(Math.ceil(topCollections.length / itemsPerPageCollections));
  }, [itemOffsetCollections, itemsPerPageCollections, topCollections]);

  const handlePageClickCollections = (event) => {
    const newOffsetCollections =
      (event.selected * itemsPerPageCollections) % topCollections.length;
    setItemOffsetCollections(newOffsetCollections);
  };
  // =============all Collections pagination end===============

  return (
    <div>
      <Header />
      <section
        className="explore-section all_nft"
        style={{ backgroundColor: "transparent", minHeight: "90vh" }}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <Link to="/" className="GoBack_btn fw-bold">
                <FaArrowLeft className="back-icon" /> Go Back
              </Link>
            </Col>
          </Row>
          <Row className="all_nft_row">
            {load ? (
              <div className="loader_spiner">
                {/* <ImSpinner7 className='spin_icon' /> */}
              </div>
            ) : topCollections.length === 0 ? (
              <Row className="mt-4">
                <Col lg={12} md={12}>
                  <div className="filter_data_card text-center py-5">
                    <p>No Collection's Available</p>
                  </div>
                </Col>
              </Row>
            ) : currentItemsCollections ? (
              currentItemsCollections.map((e, index) => {
                var link = `/collection/${e.new_url_name}`;
                var name = e.name;
                var logo = e.logo;
                var description = e.description
                var bannerImage = e.bannerImage
                return (
                  <Col lg={3} md={6}>
                    <Card className="mt-4 creators_wrap" key={index}>
                      <Link to={link}>
                        <div className="nft_img_div">
                          <Card.Img src={bannerImage} />
                        </div>

                        <div>
                          <Card.Body>
                            <div className="user_img">
                              <img
                                src={logo}
                                alt="user_img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="user-section">
                              <h6>{name}</h6>
                              <p>{description}</p>
                            </div>
                          </Card.Body>
                        </div>
                      </Link>
                    </Card>
                  </Col>
                );
              })
            ) : (
              " "
            )}
          </Row>
          <Row>
            <Col lg={12}>
              {(() => {
                if (topCollections.length > 12) {
                  return (
                    <div className="paginate mt-4">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=" >>"
                        onPageChange={handlePageClickCollections}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCountCollections}
                        previousLabel="<< "
                        containerClassName="pagination justify-content-end"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        activeClassName="active"
                      />
                    </div>
                  );
                }
              })()}
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
}

export default AllCollections;
