import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Container, Row, Col, Form, InputGroup, FormControl } from 'react-bootstrap'
import ProfileImg from '../assets/img/Profile.png'
import { FaGlobe } from 'react-icons/fa'
import { FaDiscord } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaTiktok } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa'
import { BsCameraFill } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'
import { ImSpinner7 } from 'react-icons/im'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import $ from 'jquery'
import { FaImages, FaSpinner } from 'react-icons/fa'

// var BASE_URL = "http://148.72.244.170:5001"
var BASE_URL = process.env.REACT_APP_BASE_URL


function Edit_profile() {
    var [walletAddress, setwalletAddress] = useState("");
    var [name, setName] = useState("");
    var [username, setUserName] = useState("");
    var [bio, setBio] = useState("");
    var [website, setLink] = useState("");
    var [discord, setDiscord] = useState("");
    var [facebook, setFacebook] = useState("");
    var [twitter, setTwitter] = useState("");
    var [instagram, setInstagram] = useState("");
    // var [twitch, setTwitch] = useState("");
    var [youtube, setYoutube] = useState("");
    var [User, setUser] = useState("")
    var [createDate, setCreateDate] = useState("")
    const [imageprev, setImageprev] = useState("");
    var [profileImage, setProfileImage] = useState("")
    var [profileImagePreview, setProfileImagePreview] = useState("")

    const [coverimage, setCoverImage] = useState("");
    const [coverimageprev, setCoverImageprev] = useState("");
    const [invailidCoverImage, setInvailidCoverImage] = useState("");

    var [FollowerCount, setFollowerCount] = useState("");
    var [FollowingCount, setFollowingCount] = useState("");

    var user = JSON.parse(sessionStorage.getItem("user"))
    var loginUserAdd = sessionStorage.getItem("loginUserAdd")

    let navigate = useNavigate()
        console.log("profileImage",profileImage);
    useEffect(() => {
        $("#fullNameValid").hide();
        $("#userNameValid").hide();
        $('#profileImagePreviewValidation').hide();
        $("#userName_Valid").hide();
    })


    $(".validate").focus(function () {
        $("#fullNameValid").hide();
        $("#userNameValid").hide();
        $('#profileImagePreviewValidation').hide();
        $("#userName_Valid").hide();

    })

    async function UpdateProfile(e) {
        const pattern = /^\S*$/;
        let checkAddress = sessionStorage.getItem('loginUserAdd')

        let firstNameValid = false;
        let userNameValid = false;


        if (name.length === 0 || !/^[a-zA-Z].*[\s\.]*$/g.test(name)) {
            $("#fullNameValid").show();
            userNameValid = false;
        } else {

            firstNameValid = true
        }

        if (username.length === 0 || !(username)) {
            $("#userNameValid").show();
            userNameValid = false
        }else if (!pattern.test(username)) {
            $("#userName_Valid").show();
            $("#userNameValid").hide();
            return;
        } else {
            userNameValid = true
        }
        if (userNameValid == false || firstNameValid == false) {
            return false;
        }
        if (!name || name === "") {
            // setName(User.name)
            name = user.name ? user.name : "";
        }
        if (!username || username === "") {
            username = user.username ? user.username : "";
        }
        // if (!bio || bio === "") {
        //     bio = user.bio ?  user.bio : "";
        // }
        // if (!website || website === "") {
        //     website = user.links ?  user.links : "";
        // }
        // if (!discord || discord === "") {
        //     discord = user.discord_link ?  user.discord_link : "";
        // }
        // if (!youtube || youtube === "") {
        //     youtube = user.youtube_link ?  user.youtube_link : "";
        // }
        // if (!facebook || facebook === "") {
        //     facebook = user.facebook_link ?  user.facebook_link : "";
        // }
        // if (!twitch || twitch === "") {
        //     twitch = user.twich_link  ?  user.twich_link : "";
        // }
        // if (!tiktok || tiktok === "") {
        //     tiktok = user.tiktok_link  ?  user.tiktok_link : "";
        // }
        // if (!snapChat || snapChat === "") {
        //     snapChat = user.snapchat  ?  user.snapchat : "";
        // }

        console.log("coverimageprevcoverimageprevcoverimageprev",coverimageprev)
        console.log("coverimagepcoverimagecoverimage",coverimage)
        // console.log("coverimagepcoverimagecoverimage",  coverimage.files[0])

        // if(!empty)
        // console.log("coverimagepcoverimagecoverimagenaeeeeeeee",coverimage.files[0].name)
        document.getElementById("overlay").style.display = "block";

        const formData = new FormData();
        formData.append("user_address", checkAddress);
        formData.append("name", name);
        formData.append("username", username);
        formData.append("bio", bio);
        formData.append("links", website);
        formData.append("discord_link", discord);
        formData.append("facebook_link", facebook);
        // formData.append("snapchat", snapChat);
        // formData.append("twich_link", twitch);
        formData.append("youtube_link", youtube);
        // formData.append("tiktok_link", tiktok);
        formData.append("twitter_link", twitter);
        formData.append("instagram_link", instagram);


        var isImage = formData.getAll("image")
        var isCoverImage = formData.getAll("coverImage")
      
        if (profileImage) {
            formData.append("profileImage", profileImage, profileImage.name);
        }

       
        if(coverimage == ""){
            console.log("no cover image set");
            formData.append("coverImage","no_coverImage.jpeg");
        }else{
            console.log("already set");
            if (coverimage.name != "undefined" && coverimage.name != undefined) {
                formData.append("coverImage", coverimage, coverimage.name);
            }
        }
         

        var updateProfile = await axios.post(`${BASE_URL}/updateProfile`, formData)
        console.log("updateProfile", updateProfile);
        var status = updateProfile.data.status

        if (status === 200) {
            sessionStorage.setItem("user", JSON.stringify(updateProfile.data.result));
            toast.success('Profile Update successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored"
            });

            document.getElementById("overlay").style.display = "none";

            setTimeout(() => {
                navigate("/profile")
            }, 3000)

        }else{
            document.getElementById("overlay").style.display = "none";
            toast.error("Profile update failed !", {
                position: "top-center",
                theme: "colored",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function getUserProfile() {

        let checkAddress = sessionStorage.getItem('loginUserAdd')


        var config = {
            headers: {
                Authorization: user.token
            }
        }

        const formData = new FormData();
        formData.append("user_address", checkAddress);
        var getPRofile = await axios.post(`${BASE_URL}/getProfile`, formData)

        setUser(getPRofile.data.result)

        setUser(getPRofile.data.result.user)
        setName(getPRofile.data.result.name)
        setUserName(getPRofile.data.result.username)
        setBio(getPRofile.data.result.bio)
        setLink(getPRofile.data.result.links)
        setDiscord(getPRofile.data.result.discord_link)
        setFacebook(getPRofile.data.result.facebook_link)
        setTwitter(getPRofile.data.result.twitter_link)
        setInstagram(getPRofile.data.result.instagram_link)
        // setTwitch(getPRofile.data.result.twich_link)
        setYoutube(getPRofile.data.result.youtube_link)
        setFollowerCount(getPRofile.data.result.followerCount)
        setFollowingCount(getPRofile.data.result.followingCount)
        setProfileImagePreview(getPRofile.data.result.profileImage)
        setCreateDate(getPRofile.data.result.joined_date)
        setCoverImage(getPRofile.data.result.coverImage)

    }

    useEffect(() => {
        if (loginUserAdd) {
            setwalletAddress(loginUserAdd)
            getUserProfile()
        }

        const userDetail = JSON.parse(sessionStorage.getItem('user'))
        if (userDetail == "" || userDetail == null) {
            navigate("/")
        }
    }, [])


    const closeImg = () => {
        setCoverImageprev("")
        setCoverImage("")
    }

    const handleBack = () => {
        navigate("/profile")
    }

    const ProfileImageValidate = (e) => {
        setProfileImage(e.target.files[0])
        setImageprev(URL.createObjectURL(e.target.files[0]));
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|gif|JPEG|PNG|JPG|GIF)$/)) {
            console.log("not preview match1")
            console.log("Us1",e.target.files[0].name)
            e.target.value = null;
            setProfileImage("")
            setImageprev("")
           
            $('#profileImagePreviewValidation').show();
           
        }

    } 

    const imageValidate = (e) => {
        setCoverImage(e.target.files[0]);
        setCoverImageprev(URL.createObjectURL(e.target.files[0]));

        console.log("Shivaniiiiiiiiii",e.target.files[0])

        if(e.target.files[0].name){
            if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
                console.log("Shivaniiiiiiiiii 1")
                console.log("Us",e.target.files[0].name)
                e.target.value = null;
                setCoverImageprev("")
                setCoverImage("")
                // closeImg()
                // $('#uploadImageType').show();
                setInvailidCoverImage('Invalid File Type');
            }else{
                console.log("Shivaniiiiiiiiii 2")
                setInvailidCoverImage('');
                // $('#uploadImageType').hide();
            }
        }

    }  

    const checkUsernameValidation = () => {
        const pattern = /^\S*$/;
        if (!pattern.test(username)) {
            $("#userName_Valid").show();
            $("#userNameValid").hide();
            return;
        } else {
            $("#userName_Valid").hide();
        }
    }

    return (
        <div>
             <div id="overlay">
                <div className='loader_spiner'>
                    <ImSpinner7 />
                    <div className="loader_text">
                        <strong>Please wait while complete your processing...</strong>
                    </div>
                </div>
            </div>
            <Header />

            <section className="edit-banner">
                <Container>
                    <Row>
                        <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} className="border rounded shadow bg-white">
                            <div className='close_btn mt-2' onClick={handleBack}><MdClose /></div>

                            <div className='profile-detail text-center p-4'>

                                <div className="edit_profile_img">
                                    {imageprev ?
                                        <img src={imageprev} alt="profile_pic" className='img-fluid' />
                                        :
                                        <div>
                                            <img src={profileImagePreview} alt="profile_pic" className='img-fluid' />
                                            <div class="wrapper">
                                                <div class="file-upload">
                                                    <input type="file" id='profileImage' name='profileImage' onChange={ ProfileImageValidate }
                                                    />
                                                     <BsCameraFill />
                                                </div>
                                               
                                              </div>
                                              <p style={{textAlign: "center",color: 'red'}} id="profileImagePreviewValidation">Invalid File Type </p>
                                        </div>

                                    }

                                </div>
                                <p className='mt-2'>Change Profile Picture</p>


                                <div className='mt-4 heading-name'>
                                    <InputGroup >
                                        <FormControl
                                            placeholder="Full Name"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </InputGroup>
                                    <p className='required-text' id="fullNameValid">Name field is required.</p>


                                    <InputGroup className='mt-4'>
                                        <FormControl
                                            placeholder="Username"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            value={username}
                                            onChange={(e) => setUserName(e.target.value)}
                                            onKeyUp={checkUsernameValidation}
                                        />
                                    </InputGroup>
                                    <p className='required-text' id="userNameValid">Username field is required.</p>
                                    <p className='required-text' id="userName_Valid">Enter valid Username</p>
                                </div>

                                <div className='edit-profile mt-4'>
                                    <ul className='list-unstyled d-flex justify-content-around align-items-center'>
                                        <li className='mr-4'><strong>{FollowingCount}</strong> <span className='d-block'>Following</span></li>
                                        <li><strong>{FollowerCount}</strong> <span className='d-block'>Followers</span></li>
                                    </ul>
                                </div>

                                <div className='Bio mt-4 text-start'>
                                    <h6><strong>Cover Image</strong></h6>
                                    <hr></hr>
                                    <div className="card-body customCardBody editCover_img">
                                        {coverimageprev ?
                                            <div className="img_preview">
                                                <img src={coverimageprev} alt="profile_pic" className='img-fluid ' accept="image/png, image/gif, image/jpg, image/jpeg, image/jfif" />
                                                <div className="img_close">
                                                    <button className='img_close_btn' onClick={closeImg}>X</button>
                                                </div>
                                            </div>
                                            :
                                            <div className="card-body customCardBody">
                                                {
                                                    coverimage ? <div className="img_preview">
                                                        <img src={coverimage} alt="profile_pic" className='img-fluid ' />
                                                        <div className="img_close">
                                                            <button className='img_close_btn' onClick={closeImg}>X</button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <FaImages className='fs-1 mb-3' />

                                                        <p>or drag and drop</p>

                                                        <div className="my-4"><small>File type supported : JPG, JPEG, PNG, GIF</small></div>

                                                        <Form.Control type="file" className="uploadPostImage"  accept="image/png, image/gif, image/jpeg" onChange={ imageValidate } />
                                                    </div>
                                                }

                                            </div>
                                        }
                                    </div>
                              {invailidCoverImage ? <p className='required-text' id="userNameValid">{invailidCoverImage}</p>:<p></p>}
                                </div>


                                <div className='Bio mt-4 text-start'>
                                    <h6><strong>Bio</strong></h6>
                                    <hr></hr>
                                    {/* <InputGroup> */}
                                    <textarea
                                        className='bio_textarea'
                                        placeholder="Bio"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        value={bio}
                                        onChange={(e) => setBio(e.target.value)}
                                        rows={6}
                                    />
                                    {/* </InputGroup> */}

                                </div>

                                <div className='link text-start mt-4'>
                                    <h6><strong>Links</strong></h6>

                                    <ul className='list-unstyled mt-3'>
                                        <li className='d-flex editing_icon'> <FaGlobe />
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Website Link"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    value={website}
                                                    onChange={(e) => setLink(e.target.value)}
                                                />
                                            </InputGroup>
                                        </li>
                                        <li className='d-flex editing_icon'> <FaTwitter />
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Twitter Link"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    value={twitter}
                                                    onChange={(e) => setTwitter(e.target.value)}
                                                />
                                            </InputGroup>
                                        </li>
                                        <li className='d-flex editing_icon'> <FaInstagram />
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Instagram Link"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    value={instagram}
                                                    onChange={(e) => setInstagram(e.target.value)}
                                                />
                                            </InputGroup>
                                        </li>

                                        <li className='d-flex editing_icon'> <FaDiscord />
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Discord Link"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    value={discord}
                                                    onChange={(e) => setDiscord(e.target.value)}
                                                />
                                            </InputGroup>
                                        </li>
                                        <li className='d-flex editing_icon'> <FaFacebook />
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Facebook Link"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    value={facebook}
                                                    onChange={(e) => setFacebook(e.target.value)}
                                                />
                                            </InputGroup>
                                        </li>
                                        
                                        <li className='d-flex editing_icon'> <FaYoutube />
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Youtube Link"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    value={youtube}
                                                    onChange={(e) => setYoutube(e.target.value)}
                                                />
                                            </InputGroup>
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className='list-unstyled d-flex justify-content-between border-top border-bottom py-2'>
                                        <li><strong>Joined</strong></li>
                                        <li><strong>{createDate.slice(0, 8)}</strong></li>
                                    </ul>
                                </div>

                                <div>
                                    <button type="button" className="update_btn float-end mb-4" onClick={UpdateProfile}>Update</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </section>

            <Footer />
        </div>
    )
}

export default Edit_profile