

import React,{useState, useEffect, useRef} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Timer1 = (props) => {
   const [timerDays, setTimerDays] = useState("00")
   const [timerHours, setTimerHours] = useState("00")
   const [timerMinutes, setTimerMinutes] = useState("00")
   const [timerSeconds, setTimerSeconds] = useState("00")

   let interval = useRef();

   const startTimer = ()=>{
     const countdownDate = new Date(props.currentTime).getTime();
     interval = setInterval(()=>{
      const now = new Date().getTime();
      const distance  = countdownDate - now;

      let days = Math.floor(distance/ (1000*60*60*24));
      let hours = Math.floor((distance% (1000*60*60*24)/(1000*60*60)));
      let minutes = Math.floor((distance% (1000*60*60))/(1000*60));
      let seconds = Math.floor((distance% (1000*60))/1000);
      
      let numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      if(distance<0){
        clearInterval(interval.current);
        // document.getElementById("message").style.display = "block";
        // document.getElementById("timer").style.display = "none";
      }else{
        if (numbersToAddZeroTo.includes(days)) {
            days = `0${days}`;
        } if (numbersToAddZeroTo.includes(hours)) {
            hours = `0${hours}`;
        }  if (numbersToAddZeroTo.includes(minutes)) {
            minutes = `0${minutes}`;
        }  if (numbersToAddZeroTo.includes(seconds)) {
            seconds = `0${seconds}`;
        }

        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
        // document.getElementById("message").style.display = "none";
      }

     },1000)
   }

   useEffect(()=>{
     startTimer();
     return()=>{
      clearInterval(interval.current);
     }
   })
    return (
        <>
            <Container>
                <Row>
                    <Col lg={12} className="px-0">
                        <div>
                         <div id='message' style={{display:"none"}}>Expired</div>
                            <div className='countdown-wrapper1' style={{display:"flex"}} id="timer">
                                <div className='time-section1'>
                                    <div className='time1'>{timerDays || '00'}</div>
                                    <small className="time-text1">D</small>
                                </div>
                                <div className='time-sections1  '>
                                    <div className='time1 point1'>:</div>
                                </div>
                                <div className='time-section1'>
                                    <div className='time1'>{timerHours || '00' }</div>
                                    <small className="time-text1">H</small>
                                </div>
                                <div className='time-sections1'>
                                    <div className='time1 point1'>:</div>
                                </div>
                                <div className='time-section1'>
                                    <div className='time1'>{timerMinutes || '00'}</div>
                                    <small className="time1-text1">M</small>
                                </div>
                                <div className='time-sections1'>
                                    <div className='time1 point1'>:</div>
                                </div>
                                <div className='time-section1'>
                                    <div className='time1'>{timerSeconds || '00'}</div>
                                    <small className="time1-text1">S</small>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}



export default Timer1;