import React, { useState, useEffect, useRef } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Container, Row, Col, ListGroup, Form, Modal, Tabs, Tab, Table } from 'react-bootstrap'
import featured from '../assets/img/featured.jpg'
import ProfileImg from '../assets/img/Profile.png'
import { FaEdit } from 'react-icons/fa'
import { FaRegCopy } from 'react-icons/fa'
import axios from 'axios';
import Web3 from "web3";
import { useSearchParams } from 'react-router-dom'
import { ImSpinner7 } from 'react-icons/im'
import { HiUsers, HiUser } from 'react-icons/hi'
import { FaRegCheckCircle, FaArrowLeft } from 'react-icons/fa'
import { useNavigate, Link } from 'react-router-dom'
import ethereum from '../assets/img/ether.svg'
import bnb from '../assets/img/bnb.png'
import bharatToken from '../assets/img/bharatToken(1).png'
import { BsGrid3X2GapFill } from 'react-icons/bs';
import ReactPaginate from 'react-paginate';


// import { MINT_CONTACT_ADDRESS, MINT_Abi } from '../contract/mint';
import { ERC721_CONTACT_ADDRESS, ERC721_Abi } from '../contract/erc721';
import { ERC1155_CONTACT_ADDRESS, ERC1155_Abi } from '../contract/erc1155';
import { MARKETPLACE_CONTACT_ADDRESS, MARKETPLACE_Abi } from '../contract/marketplace';
import { ERC20_CONTACT_ADDRESS, ERC20_Abi } from '../contract/erc20';

import { GLTFModel, AmbientLight, DirectionLight } from 'react-3d-viewer'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import Timer from './Timer';
import Moment from 'moment';
import ProductCard from './ProductCard'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Timer1 from './Timer1'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ProductCollection from './subcomponents/ProductCollection'
// import ProductNftDetails from './subcomponents/productNftDetails'
// import ProductNFTDetail from './subcomponents/ProductNFTDetail'
// var BASE_URL = "http://148.72.244.170:5001"
const BASE_URL = process.env.REACT_APP_BASE_URL

const modelPath =
    "https://raw.githubusercontent.com/dwqdaiwenqi/react-3d-viewer/master/site/src/lib/model/DamagedHelmet.gltf";
const web3_Stake = new Web3(window.ethereum);

// const BUYWITHCONTRACT = new web3_Stake.eth.Contract(BUY_ABI, BUY_CONTRACT_ADDERESS);
// const BUYWITHCONTRACT = new web3_Stake.eth.Contract(MINT_Abi, MINT_CONTACT_ADDRESS);

const ERC721ABiWthiCONTRACT = new web3_Stake.eth.Contract(ERC721_Abi, ERC721_CONTACT_ADDRESS);
const ERC1155ABiWthiCONTRACT = new web3_Stake.eth.Contract(ERC1155_Abi, ERC1155_CONTACT_ADDRESS);
const MarketplaceABiWthiCONTRACT = new web3_Stake.eth.Contract(MARKETPLACE_Abi, MARKETPLACE_CONTACT_ADDRESS);
const ERC20ABiWthiCONTRACT = new web3_Stake.eth.Contract(ERC20_Abi, ERC20_CONTACT_ADDRESS);

//
console.log("MARKETPLACE_CONTACT_ADDRESSMARKETPLACE_CONTACT_ADDRESS",MARKETPLACE_CONTACT_ADDRESS)

function Prod_detail() {
    const navigate = useNavigate()
    const [walletAddress, setwalletAddress] = useState("");
    const [metaData, setmetaData] = useState("");
    const [nftInfo, setNftInfo] = useState("");
    const [buyButton, setShowBuyButton] = useState(false);
    const [resellButton, setShowResellButton] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id")
    const [ownerAddress, setOwnerAddress] = useState()
    const [modalShow, setModalShow] = useState(false);
    const [nftOwnerAddress, setNftOwner] = useState("");
    const [newPrice, setNewPrice] = useState("");
    const [newName, setNewName] = useState("");
    const [newDescription, setNewDescription] = useState("");

    const [bidButton, setShowBidButton] = useState(false);
    const [bidBuyButton, setShowBidBuyButton] = useState(false);
    const [bidResellButton, setShowBidResellutton] = useState(false);

    const [bidPriceList, setbidPriceList] = useState("")
    const [listingsList, setlistingsList] = useState("")
    const [listingsBuyList, setlistingsBuyList] = useState("")

    const userDetail = JSON.parse(sessionStorage.getItem('user'))

    const [newBidPrice, setNewBidPrice] = useState("");
    const [highestBidPrice, setSetHighestBidPrice] = useState("");
    const [fileExtension, setFileExtension] = useState("");

    const [platformFees, setPlatformFees] = useState('');
    const [noBuyImage, setNoBuyImage] = useState("");
    const [totalPrice, setTotalPrice] = useState("");

    const [multipleBuyButton, setShowMultipleBuyButton] = useState(false);
    const [multiple1155ResellButton, setShow1155MultipleResellButton] = useState(false);

    const [NoResellBuyImage, setNoResellBuyImage] = useState("");
    const [showResellSupply, setShowResellSupply] = useState("");
    const [noResellPrice, setNoResellPrice] = useState("");

    const [multipleNoBuyImage, setMultipleNoBuyImage] = useState("");
    const [MultipleTotalPrice, setMultipleTotalPrice] = useState("");

    // ===========Total owner modal start==========
    const [showOwner, setShowOwner] = useState(false);

    const handleCloseOwner = () => setShowOwner(false);
    const handleShowOwner = () => setShowOwner(true);
    // ===========Total owner modal end==========


    if (noBuyImage == "" || noBuyImage == undefined || noBuyImage == null) {
        $("#totalPriceDiv").hide();
    } else {
        var remaining_img_supply = nftInfo.remaining_img_supply

        const number_pattern = /^[0-9\b]+$/;

        if (number_pattern.test(remaining_img_supply)) {
            if (parseFloat(remaining_img_supply) < parseFloat(noBuyImage)) {
                $("#noBuyImageCheck").show();
                $("#totalPriceDiv").hide();
            } else {
                $("#totalPriceDiv").show();
            }
        }
    }

    
    if (multipleNoBuyImage == "" || multipleNoBuyImage == undefined || multipleNoBuyImage == null) {
        $("#totalMultiplePriceDiv").hide();
    } else {
        $("#totalMultiplePriceDiv").show();
    }



    const copyText = () => {
        $("#copyAddress").show()
        setTimeout(() => {
            $("#copyAddress").hide()
        }, 1000)
    }

    const handleClose = () => {
        setModalShow(false)
        navigate("/")
    }
    //  ============================ Bid NFT modal =====================================
    const [bidShow, setBidShow] = useState(false);
    const handleBidClose = () => setBidShow(false);
    const handleBidShow = () => setBidShow(true);

    //  ============================ Resell NFT modal =====================================
    const [resellShow, setresellShow] = useState(false);
    const handleResellClose = () => setresellShow(false);
    const handleResellShow = () => setresellShow(true);

    //  ============================ Resell Bid NFT modal =====================================
    const [resellBidShow, setresellBidShow] = useState(false);
    const handleResellBidClose = () => setresellBidShow(false);
    const handleResellBidShow = () => setresellBidShow(true);

    //  ============================ Buy NFT =====================================

    //  ============================ Multiple NFT modal =====================================
    const [multilpleShow, setMultilpleShow] = useState(false);
    const handleMultilpleClose = () => setMultilpleShow(false);
    const handleMultilpleShow = () => setMultilpleShow(true);

    //  ============================ Multiple Resell NFT modal =====================================
    const [multipleResellShow, setMultilpleResellShow] = useState(false);
    const handleMultipeResellClose = () => setMultilpleResellShow(false);
    const handleMultipeResellShow = () => setMultilpleResellShow(true);

    //  ============================ Multiple Buy NFT modal =====================================
    const [multilpleBuyShow, setMultilpleBuyShow] = useState(false);
    const handleMultilpleBuyClose = () => setMultilpleBuyShow(false);
    // const handleMultilpleBuyShow = () => setMultilpleBuyShow(true);

    const [listingBuyData, setListingBuyData] = useState("");
    const [checkingListingList, setcheckingListingList] = useState("");


    const [buyResellId, setBuyResellId] = useState("");
    const [multipleNFTDetail, setMultipleNFTDetail] = useState("");
    const [multipleNFTUserListDetail, setmultipleNFTUserListDetail] = useState([]);


    const [sellerData, setSellerData] = useState("");
    const [bidStatus, setbidStatus] = useState(true);
    const [maxBidUser, setMaxBidUserData] = useState("");
    const [usersList, setUsersListData] = useState([]);
    const [auctionDuation, setAuctionDuation] = useState("");
    const [bharatTokenBalance, setBharatTokenBalance] = useState("");
    // const [getOwnerDetailData, setGetOwnerDetailData] = useState("");
    // const [priyanka, setPriyanka] = useState("");

    

    const [usersListLowerArray, setUsersListLowerDataArray] = useState([]);


    // ==========================  Function for  MultipleBuy ============
    const handleMultilpleBuyShow = async (id, owner_id) => {
        let checkAddress = sessionStorage.getItem('loginUserAdd')

        var listingBuyData = await axios.get(BASE_URL + '/getBuyData?id=' + id)
        console.log("listingBuyD", listingBuyData.data.result)
        setListingBuyData(listingBuyData.data.result)
        setBuyResellId(id);
        setMultilpleBuyShow(true);
    }


    useEffect(() => {
        const number_pattern = /^[0-9\b]+$/;
        
        // var n = (0.000600000000000000).toFixed(5).replace(/(\.0+|0+)$/, '')
        var n = (0.0012000000000000001).toFixed(5).replace(/(\.0+|0+)$/, '')
        var noTrailingZeroes = n.toString()
        console.log("noTrailingZeroes",noTrailingZeroes)

        if (number_pattern.test(noBuyImage)) {
            let total_price = noBuyImage * nftInfo.nft_price
            let t_p = total_price.toString();

            console.log("noTrailingZeroes3",t_p)
            setTotalPrice(total_price)
            // setTotalPrice((total_price).toFixed(5).replace(/(\.0+|0+)$/, ''))
        }


        if (number_pattern.test(multipleNoBuyImage)) {
            var total_multiple_price = multipleNoBuyImage * listingBuyData.price
            setMultipleTotalPrice(total_multiple_price)
        }

        $("#pricecheck").hide();
        $("#nameheck").hide();
        $("#desccheck").hide();
        $("#bidPricecheck").hide();
        $("#starBidPriceCheck").hide();
        $("#startHighestPriceCheck").hide();

        $("#newPricecheck").hide();
        $('#newBidNameheck').hide();
        $('#noBuyImage').hide();
        $('#noBuyImageCheck').hide();

        $('#noResellBuyImage').hide();
        $('#noResellBuyImageCheck').hide();
        $('#newResellPricecheck').hide();

        $('#noMultipleBuyImage').hide();
        $('#noMultipleBuyImageCheck').hide();

        $("#newPriceValidation").hide();
        $("#newBidPriceValidation").hide();
        $("#noBuyImageCheckValidation").hide();
        $("#newResellPricecheckValidation").hide();
        $("#noResellBuyImageCheckValidation").hide();

        $("#auctionDateAndTime").hide();
        $("#timeAuctionValidation").hide();
        $("#noResellBuyInt").hide();
        $("#noBuyImgInt").hide();
        $("#noMultipleBuyImageInt").hide();
        $("#noMultipleBuyImageCheckValidation").hide();

        $('#bidPriceValid').hide();
        $("#noBuyImageValid").hide();
        $("#noMultipleBuyImageValid").hide();
        $("#noResellBuyImageValid").hide();
        $("#noResellPriceBuyImageValid").hide();
        $("#checkTokenBalance").hide();
        

        let checkAddress = sessionStorage.getItem('loginUserAdd')
        if (checkAddress !== null && checkAddress !== "null") {
            ERC20ABiWthiCONTRACT.methods.balanceOf(checkAddress)
            .call()
            .then(async function (tokenBalance) {
                console.log('RahulllllllllltokenBalance ', tokenBalance);
                var tokenBalance_bn = Web3.utils.fromWei(tokenBalance, 'ether')
                console.log('RahulllllllllltokenBalance_bn ', tokenBalance_bn);
                setBharatTokenBalance(tokenBalance_bn)
            }).catch((err) => {
                console.log('eror ', err);
            })
        }

    })


    $(".validate").focus(function () {
        $("#pricecheck").hide();
        $("#nameheck").hide();
        $("#desccheck").hide();
        $("#bidPricecheck").hide();
        $("#starBidPriceCheck").hide();
        $("#startHighestPriceCheck").hide();

        $("#newPricecheck").hide();
        $('#newBidNameheck').hide();
        $('#noBuyImage').hide();
        $('#noBuyImageCheck').hide();

        $('#noResellBuyImage').hide();
        $('#noResellBuyImageCheck').hide();
        $('#newResellPricecheck').hide();

        $('#noMultipleBuyImage').hide();
        $('#noMultipleBuyImageCheck').hide();

        $("#newPriceValidation").hide();
        $("#newBidPriceValidation").hide();
        $("#noBuyImageCheckValidation").hide();
        $("#newResellPricecheckValidation").hide();
        $("#noResellBuyImageCheckValidation").hide();

        $("#auctionDateAndTime").hide();
        $("#timeAuctionValidation").hide();
        $("#noResellBuyInt").hide();
        $("#noBuyImgInt").hide();
        $("#noMultipleBuyImageInt").hide();
        $("#noMultipleBuyImageCheckValidation").hide();

        $('#bidPriceValid').hide();
        $("#noBuyImageValid").hide();
        $("#noMultipleBuyImageValid").hide();
        $("#noResellBuyImageValid").hide();
        $("#noResellPriceBuyImageValid").hide();
        $("#checkTokenBalance").hide();
    })

    function toTimestamp(strDate) {
        var datum = Date.parse(strDate);
        return datum / 1000;
    }

    const currentDate_time = new Date()

    const [currentDateTime, setCurrentDateTime] = useState(currentDate_time)

    const new_auction_date = Moment(currentDateTime).format('DD MMMM YYYY h:mm A')
    const new_aucation_timestamp = toTimestamp(new_auction_date)

    function toTimestamp(strDate) {
        var datum = Date.parse(strDate);
        return datum / 1000;
    }

    const auction_date = Moment(nftInfo.aucation_date).format('MMMM DD YYYY h:mm A')

    const startDate = new Date()
    const [currentDate, setCurrentDate] = useState("")
    const currentDateCheck = Moment(currentDate).format('DD MMMM YYYY h:mm A')

    const [todayDate, setTodayDate] = useState(startDate)
    const current_date = Moment(todayDate).format('DD MMMM YYYY h:mm A')
    const current_date_timestamp = toTimestamp(current_date)


    const handleChange = (date) => {
        setCurrentDate(date)
    }

    async function resellBidNFT() {
        // alert("reseell bid nft")
        const newAuction_date = Moment(currentDateCheck).format('DD MMMM YYYY h:mm A')
        const newAucation_timestamp = toTimestamp(newAuction_date)

        if (!walletAddress) {
            toast.error('Please connect with metamask!', {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }


        if (!newName) {
            $("#newBidNameheck").show();
            return;
        }

        if (!newPrice) {
            $("#newPricecheck").show();
        } else if (newPrice <= 0) {
            $("#newBidPriceValidation").show();
            return;
        }

        if (currentDate === "") {
            $("#auctionDateAndTime").show();
            return;
        }

        const startDateERC721 = new Date()
        const todayDate = Moment(startDateERC721).format('DD MMMM YYYY h:mm A')
        const todayTimestamp = toTimestamp(todayDate)

        const getAuctionDate = Moment(currentDate).format('DD MMMM YYYY h:mm A')
        const getAuctionTimestamp = toTimestamp(getAuctionDate)
        if (parseFloat(getAuctionTimestamp) < parseFloat(todayTimestamp)) {
            $("#timeAuctionValidation").show();
            return
            // currentTimeValid = false;
        }

        if (newPrice && currentDate && newName) {
            var tokenPrice = newPrice;
            var tokens = Web3.utils.toWei(tokenPrice.toString(), 'ether')
            var bntokens = Web3.utils.toBN(tokens)

            setresellBidShow(false)
            document.getElementById("overlay").style.display = "block";

            ERC721ABiWthiCONTRACT.methods.approve(MARKETPLACE_CONTACT_ADDRESS, nftInfo.token_id)
                .send({
                    from: walletAddress,
                    // gas: 3000000
                }).on('error', function (error) {
                    console.log("error", error)
                }).then(async function (info) {
                    MarketplaceABiWthiCONTRACT.methods.createTokenAuction721(ERC721_CONTACT_ADDRESS,nftInfo.token_id, bntokens, newAucation_timestamp)
                        .send({
                            from: walletAddress,
                            // gas: 3000000,
                            // value: platformFees_bn

                        }).on('error', function (error) {
                            console.log("error", error)
                        }).then(async function (info) {
                            console.log('success ', info);
                            var transaction_id = info.transactionHash

                            const formData = new FormData();
                            formData.append("wallet_address", walletAddress);
                            formData.append("token_id", nftInfo.token_id);
                            formData.append("new_price", newPrice);
                            formData.append("nft_name", newName);
                            // formData.append("auction_date", new_auction_date);
                            // formData.append("auction_date", current_date);
                            formData.append("auction_date", currentDateCheck);
                            formData.append("transaction_id", transaction_id);

                            var resellBidNft = await axios.post(`${BASE_URL}/resellBidNft`, formData)
                            console.log("resellBidNft", resellBidNft)

                            if (resellBidNft.status == 200) {
                                var ethBalance = await (await web3_Stake.eth.getBalance(walletAddress))
                                ethBalance = Web3.utils.fromWei(ethBalance, 'ether').slice(0, 6);
                                sessionStorage.setItem("ethBalance", ethBalance);

                                ERC20ABiWthiCONTRACT.methods.balanceOf(walletAddress)
                                .call()
                                .then(async function (tokenBalance) {
                                    var tokenBalance_bn = Web3.utils.fromWei(tokenBalance, 'ether')
                                    console.log('RahulllllllllltokenBalance_bn ', tokenBalance_bn);
                                    sessionStorage.setItem("tokenBalance", tokenBalance_bn);
                                }).catch((err) => {
                                    console.log('eror ', err);
                                })

                                console.log("Yes");
                                toast.success('Resell bid nft successfully !', {
                                    position: "top-center",
                                    theme: "colored",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                document.getElementById("overlay").style.display = "none";
                                setTimeout(() => {
                                    setresellBidShow(false)
                                    window.location.reload()
                                    // navigate(`/prod_detail?id=${nftInfo.id}`)
                                }, 2000)
                            }
                        }).catch((err) => {
                            console.log("err", err)
                        })
                }).catch((err) => {
                    console.log("err", err)
                    toast.error('Resell bid nft failed !', {
                        position: "top-center",
                        theme: "colored",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("overlay").style.display = "none";
                    setTimeout(() => {
                        // navigate(`/prod_detail?id=${nftInfo.id}`)
                        window.location.reload()
                    }, 2000)
                })
        }
    }

    async function resellNoBidNFT() {
        // alert("no bid",auctionOwner)

        console.log("no bid")
        const newAuction_date = Moment(currentDateCheck).format('DD MMMM YYYY h:mm A')
        const newAucation_timestamp = toTimestamp(newAuction_date)

        if (!walletAddress) {
            toast.error('Please connect with metamask!', {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (!newName) {
            $("#newBidNameheck").show();
            return;
        }

        if (!newPrice) {
            $("#newPricecheck").show();
        } else if (newPrice <= 0) {
            $("#newBidPriceValidation").show();
            return;
        }

        if (currentDate === "") {
            $("#auctionDateAndTime").show();
            return;
        }


        const startDateERC721 = new Date()
        const todayDate = Moment(startDateERC721).format('DD MMMM YYYY h:mm A')
        const todayTimestamp = toTimestamp(todayDate)

        const getAuctionDate = Moment(currentDate).format('DD MMMM YYYY h:mm A')
        const getAuctionTimestamp = toTimestamp(getAuctionDate)

        console.log("todayDatetodayDate", todayDate)
        console.log("todayDatetodayDate 1", getAuctionTimestamp)
        console.log("todayDatetodayDate 2", todayTimestamp)
        if (parseFloat(getAuctionTimestamp) < parseFloat(todayTimestamp)) {
            console.log("todayDatetodayDate 3")
            $("#timeAuctionValidation").show();
            return
            // currentTimeValid = false;
        }

        console.log("todayDatetodayDate 4")
        if (newPrice && currentDate && newName) {
            var tokenPrice = newPrice;
            var tokens = Web3.utils.toWei(tokenPrice.toString(), 'ether')
            var bntokens = Web3.utils.toBN(tokens)

            setresellBidShow(false)
            document.getElementById("overlay").style.display = "block";

            ERC721ABiWthiCONTRACT.methods.ownerOf(nftInfo.token_id)
            .call()
            .then(async (fixedAddress) => {
                console.log("getOwnerDetailgetOwnerDetail", fixedAddress)

                if(fixedAddress == MARKETPLACE_CONTACT_ADDRESS){
                    console.log("owner marketplace")
                    MarketplaceABiWthiCONTRACT.methods.executeSale721(nftInfo.token_id)
                        .send({
                            from: walletAddress,
                            // from : "0x9B28D3d3F545952578E314395f9Adc504e1b1136",
                            // gas: 3000000,
                            // value: bntokens
                        }).on('error', function (error) {
                            console.log("error", error)
                        }).then(async function (info) {
                            console.log("infoinfo", info)

                            ERC721ABiWthiCONTRACT.methods.approve(MARKETPLACE_CONTACT_ADDRESS, nftInfo.token_id)
                                .send({
                                    from: walletAddress,
                                    // gas: 3000000
                                }).on('error', function (error) {
                                    console.log("error", error)

                                }).then(async function (info) {
                                    MarketplaceABiWthiCONTRACT.methods.createTokenAuction721(ERC721_CONTACT_ADDRESS,nftInfo.token_id, bntokens, newAucation_timestamp)
                                        .send({
                                            from: walletAddress,
                                            // gas: 3000000,
                                            // value: platformFees_bn

                                        }).on('error', function (error) {
                                            console.log("error", error)

                                        }).then(async function (info) {
                                            console.log('success ', info);
                                            var transaction_id = info.transactionHash

                                            const formData = new FormData();
                                            formData.append("wallet_address", walletAddress);
                                            formData.append("token_id", nftInfo.token_id);
                                            formData.append("new_price", newPrice);
                                            formData.append("nft_name", newName);
                                            // formData.append("auction_date", new_auction_date);
                                            // formData.append("auction_date", current_date);
                                            formData.append("auction_date", currentDateCheck);

                                            formData.append("transaction_id", transaction_id);

                                            var resellBidNft = await axios.post(`${BASE_URL}/resellBidNft`, formData)
                                            console.log("resellBidNft", resellBidNft)

                                            if (resellBidNft.status == 200) {
                                                var ethBalance = await (await web3_Stake.eth.getBalance(walletAddress))
                                                ethBalance = Web3.utils.fromWei(ethBalance, 'ether').slice(0, 6);
                                                sessionStorage.setItem("ethBalance", ethBalance);

                                                ERC20ABiWthiCONTRACT.methods.balanceOf(walletAddress)
                                                .call()
                                                .then(async function (tokenBalance) {
                                                    var tokenBalance_bn = Web3.utils.fromWei(tokenBalance, 'ether')
                                                    console.log('RahulllllllllltokenBalance_bn ', tokenBalance_bn);
                                                    sessionStorage.setItem("tokenBalance", tokenBalance_bn);
                                                }).catch((err) => {
                                                    console.log('eror ', err);
                                                })

                                                console.log("Yes");
                                                toast.success('Resell bid nft successfully !', {
                                                    position: "top-center",
                                                    theme: "colored",
                                                    autoClose: 2000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                });
                                                document.getElementById("overlay").style.display = "none";
                                                setTimeout(() => {
                                                    setresellBidShow(false)
                                                    window.location.reload()
                                                    // navigate(`/prod_detail?id=${nftInfo.id}`)
                                                }, 2000)
                                            }
                                        }).catch((err) => {
                                            console.log("err", err)
                                        })
                                }).catch((err) => {
                                    console.log("err", err)
                                })

                        }).catch((err) => {
                            console.log("err", err)
                        })
                }else{
                    console.log("owner nft")
                    ERC721ABiWthiCONTRACT.methods.approve(MARKETPLACE_CONTACT_ADDRESS, nftInfo.token_id)
                    .send({
                        from: walletAddress,
                        // gas: 3000000
                    }).on('error', function (error) {
                        console.log("error", error)
        
                    }).then(async function (info) {
                        MarketplaceABiWthiCONTRACT.methods.createTokenAuction721(ERC721_CONTACT_ADDRESS,nftInfo.token_id, bntokens, newAucation_timestamp)
                            .send({
                                from: walletAddress,
                                // gas: 3000000,
                                // value: platformFees_bn
        
                            }).on('error', function (error) {
                                console.log("error", error)
        
                            }).then(async function (info) {
                                console.log('success ', info);
                                var transaction_id = info.transactionHash
        
                                const formData = new FormData();
                                formData.append("wallet_address", walletAddress);
                                formData.append("token_id", nftInfo.token_id);
                                formData.append("new_price", newPrice);
                                formData.append("nft_name", newName);
                                // formData.append("auction_date", new_auction_date);
                                // formData.append("auction_date", current_date);
                                formData.append("auction_date", currentDateCheck);
        
                                formData.append("transaction_id", transaction_id);
        
                                var resellBidNft = await axios.post(`${BASE_URL}/resellBidNft`, formData)
                                console.log("resellBidNft", resellBidNft)
        
                                if (resellBidNft.status == 200) {
                                    var ethBalance = await (await web3_Stake.eth.getBalance(walletAddress))
                                    ethBalance = Web3.utils.fromWei(ethBalance, 'ether').slice(0, 6);
                                    sessionStorage.setItem("ethBalance", ethBalance);
        
                                    console.log("Yes");
                                    toast.success('Resell bid nft successfully !', {
                                        position: "top-center",
                                        theme: "colored",
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                    document.getElementById("overlay").style.display = "none";
                                    setTimeout(() => {
                                        setresellBidShow(false)
                                        window.location.reload()
                                        // navigate(`/prod_detail?id=${nftInfo.id}`)
                                    }, 2000)
                                }
                            }).catch((err) => {
                                console.log("err", err)
                            })
                    }).catch((err) => {
                        console.log("err", err)
                    })
                }
                
            }).catch((err) => {
                console.log('eror ', err);
                toast.error('Resell bid nft failed !', {
                    position: "top-center",
                    theme: "colored",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                document.getElementById("overlay").style.display = "none";
                setTimeout(() => {
                    // navigate(`/prod_detail?id=${nftInfo.id}`)
                    window.location.reload()
                }, 2000)
            })

        }
    }

    const sendNFT = async (sender_id, bid_auction_id,biderAddress,bidPrice) => {
        console.log("Rahullllllllll Bid PRice",bidPrice)

        var bidPrice = Web3.utils.toWei(bidPrice.toString(), 'ether')
        console.log("Rahullllllllll Bid PRice1",bidPrice)

        // alert(bidPrice)
        if (!walletAddress) {
            toast.error('Please connect with metamask!', {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        
        ERC20ABiWthiCONTRACT.methods.balanceOf(biderAddress)
        .call()
        .then(async function (biderTokenBalance) {
            console.log('RahulllllllllltokenBalance ', biderTokenBalance);
            var biderTokenBalance_bn = Web3.utils.fromWei(biderTokenBalance, 'ether')
            console.log('RahulllllllllltokenBalance_bn ', biderTokenBalance_bn);
            if(biderTokenBalance_bn == 0 || (parseInt(biderTokenBalance_bn) < parseInt(biderTokenBalance_bn))){
                toast.error("User donn't have insufficient token in wallet !", {
                    position: "top-center",
                    theme: "colored",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                document.getElementById("overlay").style.display = "none";
                setTimeout(() => {
                    // navigate(`/prod_detail?id=${nftInfo.id}`)
                    window.location.reload()
                }, 3000)
    
                return;
            }else{
                document.getElementById("overlay").style.display = "block";

                // ERC20ABiWthiCONTRACT.methods.approve(MARKETPLACE_CONTACT_ADDRESS,bidPrice)
                // .send({
                //     from: walletAddress,
                //     // gas: 3000000,
                //     // value: bidPrice
                // }).on('error', function (error) {
                //     console.log("error", error)
                //     // setLoading(false)
                //     toast.error('Bid placed failed !', {
                //         position: "top-center",
                //         theme: "colored",
                //         autoClose: 2000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //     });
                //     document.getElementById("overlay").style.display = "none";
                //     setTimeout(() => {
                //         // navigate(`/prod_detail?id=${nftInfo.id}`)
                //         window.location.reload()
                //     }, 2000)
                
                // }).then(async function (info) {
                    MarketplaceABiWthiCONTRACT.methods.executeSale721(ERC721_CONTACT_ADDRESS,nftInfo.token_id)
                    .send({
                        from: walletAddress,
                        // gas: 3000000,
                        // value: bntokens
                    }).on('error', function (error) {
                        console.log("error", error)
                        toast.error('You send nft failed !', {
                            position: "top-center",
                            theme: "colored",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        document.getElementById("overlay").style.display = "none";
                        setTimeout(() => {
                            // navigate(`/prod_detail?id=${nftInfo.id}`)
                            window.location.reload()
                        }, 2000)
        
                    }).then(async function (info) {
                        var transaction_id = info.transactionHash

                        const formData = new FormData();
                        formData.append("owner_address", walletAddress);
                        formData.append("token_id", nftInfo.token_id);
                        formData.append("bid_auction_id", bid_auction_id);
                        formData.append("transaction_id", transaction_id);
                        formData.append("sender_id", sender_id);
        
        
                        var resellBidNft = await axios.post(`${BASE_URL}/sendBuyBidNft`, formData)
                        console.log("resellBidNft", resellBidNft)
        
                        if (resellBidNft.status == 200) {
                            var ethBalance = await (await web3_Stake.eth.getBalance(walletAddress))
                            ethBalance = Web3.utils.fromWei(ethBalance, 'ether').slice(0, 6);
                            sessionStorage.setItem("ethBalance", ethBalance);

                            ERC20ABiWthiCONTRACT.methods.balanceOf(walletAddress)
                            .call()
                            .then(async function (tokenBalance) {
                                var tokenBalance_bn = Web3.utils.fromWei(tokenBalance, 'ether')
                                console.log('RahulllllllllltokenBalance_bn ', tokenBalance_bn);
                                sessionStorage.setItem("tokenBalance", tokenBalance_bn);
                            }).catch((err) => {
                                console.log('eror ', err);
                            })

                            toast.success('Send nft successfully !', {
                                position: "top-center",
                                theme: "colored",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            document.getElementById("overlay").style.display = "none";
                            setTimeout(() => {
                                setresellBidShow(false)
                                window.location.reload()
                                // navigate(`/prod_detail?id=${nftInfo.id}`)
                            }, 2000)
                        } else {
                            toast.error('You send nft failed !', {
                                position: "top-center",
                                theme: "colored",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            document.getElementById("overlay").style.display = "none";
                            setTimeout(() => {
                                // navigate(`/prod_detail?id=${nftInfo.id}`)
                                window.location.reload()
                            }, 2000)
                        }
                    })
                // })
            }
        }).catch((err) => {
            console.log('eror ', err);
        })
    }

    async function buyBidNFT() {

        const highestBidPriceWei = Web3.utils.fromWei(highestBidPrice, 'ether');
        const hightBidEth = Web3.utils.fromWei(highestBidPriceWei, 'ether');
        

        if (!walletAddress) {
            toast.error('Please connect with metamask!', {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        if(bharatTokenBalance == 0 || (parseInt(bharatTokenBalance) < parseInt(hightBidEth))){
            toast.error("You donn't have insufficient token !", {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            document.getElementById("overlay").style.display = "none";
            setTimeout(() => {
                // navigate(`/prod_detail?id=${nftInfo.id}`)
                window.location.reload()
            }, 3000)

            return;
        }

        // alert(1);
        var tokenOwner = nftInfo.wallet_address;
        var tokenPrice = nftInfo.price;
        console.log("nftInfo.token_id", nftInfo.token_id)
        console.log("nftInfo.price", tokenPrice)
        console.log("nftInfo.price.toString()", tokenPrice.toString())
        console.log("walletAddress", walletAddress)
        console.log("highestBidPrice", highestBidPrice)

        var tokens = Web3.utils.toWei(tokenPrice.toString(), 'ether')
        var bntokens = Web3.utils.toBN(tokens)

        document.getElementById("overlay").style.display = "block";
        // var transaction_id = "fdsfsdfsdfsdfsd"

        // ERC20ABiWthiCONTRACT.methods.approve(MARKETPLACE_CONTACT_ADDRESS,highestBidPriceWei)
        // .send({
        //     from: walletAddress,
        //     // gas: 3000000,
        //     // value: bidPrice
        // }).on('error', function (error) {
        //     console.log("error", error)
        //     // setLoading(false)
        //     toast.error('Bid placed failed !', {
        //         position: "top-center",
        //         theme: "colored",
        //         autoClose: 2000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        //     document.getElementById("overlay").style.display = "none";
        //     setTimeout(() => {
        //         // navigate(`/prod_detail?id=${nftInfo.id}`)
        //         window.location.reload()
        //     }, 2000)
        
        // }).then(async function (info) {
            MarketplaceABiWthiCONTRACT.methods.executeSale721(ERC721_CONTACT_ADDRESS,nftInfo.token_id)
            .send({
                from: walletAddress,
                // gas: 3000000,
                // value: bntokens
            }).on('error', function (error) {
                console.log("error", error)
                toast.error('Buy bid nft failed !', {
                    position: "top-center",
                    theme: "colored",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                document.getElementById("overlay").style.display = "none";
                setTimeout(() => {
                    // navigate(`/prod_detail?id=${nftInfo.id}`)
                    window.location.reload()
                }, 2000)

            }).then(async function (info) {

                var transaction_id = info.transactionHash

                const formData = new FormData();
                formData.append("wallet_address", walletAddress);
                formData.append("token_id", nftInfo.token_id);
                formData.append("price", hightBidEth);
                formData.append("bid_auction_id", nftInfo.bid_auction_id);
                formData.append("transaction_id", transaction_id);

                var buyBidNft = await axios.post(`${BASE_URL}/buyBidNft`, formData)
                console.log("buyBidNft", buyBidNft.status)

                if (buyBidNft.status == 200) {
                    var ethBalance = await (await web3_Stake.eth.getBalance(walletAddress))
                    ethBalance = Web3.utils.fromWei(ethBalance, 'ether').slice(0, 6);
                    sessionStorage.setItem("ethBalance", ethBalance);

                    ERC20ABiWthiCONTRACT.methods.balanceOf(walletAddress)
                    .call()
                    .then(async function (tokenBalance) {
                        var tokenBalance_bn = Web3.utils.fromWei(tokenBalance, 'ether')
                        console.log('RahulllllllllltokenBalance_bn ', tokenBalance_bn);
                        sessionStorage.setItem("tokenBalance", tokenBalance_bn);
                    }).catch((err) => {
                        console.log('eror ', err);
                    })

                    toast.success('Buy bid nft successfully !', {
                        position: "top-center",
                        theme: "colored",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("overlay").style.display = "none";
                    setTimeout(() => {
                        // navigate(`/prod_detail?id=${nftInfo.id}`)
                        window.location.reload()
                    }, 2000)
                } else {
                    toast.error('Buy bid nft failed !', {
                        position: "top-center",
                        theme: "colored",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("overlay").style.display = "none";
                    setTimeout(() => {
                        // navigate(`/prod_detail?id=${nftInfo.id}`)
                        window.location.reload()
                    }, 2000)
                }
            })
        // })
    }

    async function bidNFT() {
        // alert(bharatTokenBalance);
        if (!walletAddress) {
            toast.error('Please connect with metamask!', {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }
        // setLoading(true)

        const price_pattern = /^[+-]?\d*(?:[.,]\d*)?$/;

        if (!newBidPrice) {
            $("#bidPriceValid").hide();
            $("#bidPricecheck").show();
            return;
        }if (!price_pattern.test(newBidPrice)) {
            $("#bidPricecheck").hide();
            $("#bidPriceValid").show();
            return;
        }

        if(bharatTokenBalance == 0){
            $("#checkTokenBalance").show();
            return;
        }
        
        if(parseInt(bharatTokenBalance) < parseInt(newBidPrice)){
            $("#checkTokenBalance").show();
            return;
        }

        var startNFTPrice = nftInfo.price
        var highest_bid = nftInfo.highest_bid;

        var startNFTPrice_wei = Web3.utils.toWei(startNFTPrice.toString(), 'ether')
        var newBidPrice_wei = Web3.utils.toWei(newBidPrice.toString(), 'ether')
        var highest_bid_wei = Web3.utils.toWei(highest_bid.toString(), 'ether')


        // if (parseInt(startNFTPrice) >= parseInt(newBidPrice)) {
        if (parseInt(startNFTPrice_wei) >= parseInt(newBidPrice_wei)) {
            $("#starBidPriceCheck").show();
            return;
        }

        if (parseInt(nftInfo.highest_bid) != 0) {
            // if (parseInt(nftInfo.highest_bid) >= parseInt(newBidPrice)) {
            if (parseInt(highest_bid_wei) >= parseInt(newBidPrice_wei)) {
                $("#startHighestPriceCheck").show();
                return;
            }
        }

        document.getElementById("overlay").style.display = "block";

        var tokenPrice = newBidPrice;
        var tokens = Web3.utils.toWei(tokenPrice.toString(), 'ether')
        var bidPrice = Web3.utils.toBN(tokens)

        setBidShow(false)
        
        ERC20ABiWthiCONTRACT.methods.approve(MARKETPLACE_CONTACT_ADDRESS,bidPrice)
        .send({
            from: walletAddress,
            // gas: 3000000,
            // value: bidPrice
        }).on('error', function (error) {
            console.log("error", error)
            // setLoading(false)
            toast.error('Bid placed failed !', {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            document.getElementById("overlay").style.display = "none";
            setTimeout(() => {
                // navigate(`/prod_detail?id=${nftInfo.id}`)
                window.location.reload()
            }, 2000)
        
        }).then(async function (info) {
            MarketplaceABiWthiCONTRACT.methods.bid721(ERC721_CONTACT_ADDRESS,nftInfo.token_id,bidPrice)
            .send({
                from: walletAddress,
                // gas: 3000000,
                // value: bidPrice
            }).on('error', function (error) {
                console.log("error", error)
                // setLoading(false)
                toast.error('Bid placed failed !', {
                    position: "top-center",
                    theme: "colored",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                document.getElementById("overlay").style.display = "none";
                setTimeout(() => {
                    // navigate(`/prod_detail?id=${nftInfo.id}`)
                    window.location.reload()
                }, 2000)

            }).then(async function (info) {
                var transaction_id = info.transactionHash

                const formData = new FormData();
                formData.append("wallet_address", walletAddress);
                formData.append("token_id", nftInfo.token_id);
                formData.append("auction_price", newBidPrice);
                formData.append("transaction_id", transaction_id);

                var bidAuctionNft = await axios.post(`${BASE_URL}/bidAuctionNft`, formData)
                console.log("bidAuctionNft", bidAuctionNft.status)


                if (bidAuctionNft.status == 200) {
                    var ethBalance = await (await web3_Stake.eth.getBalance(walletAddress))
                    ethBalance = Web3.utils.fromWei(ethBalance, 'ether').slice(0, 6);
                    sessionStorage.setItem("ethBalance", ethBalance);

                    ERC20ABiWthiCONTRACT.methods.balanceOf(walletAddress)
                    .call()
                    .then(async function (tokenBalance) {
                        var tokenBalance_bn = Web3.utils.fromWei(tokenBalance, 'ether')
                        console.log('RahulllllllllltokenBalance_bn ', tokenBalance_bn);
                        sessionStorage.setItem("tokenBalance", tokenBalance_bn);
                    }).catch((err) => {
                        console.log('eror ', err);
                    })

                    console.log("Yes");
                    toast.success('Bid placed successfully !', {
                        position: "top-center",
                        theme: "colored",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("overlay").style.display = "none";
                    setTimeout(() => {
                        setBidShow(false)
                        window.location.reload()
                        // navigate(`/prod_detail?id=${nftInfo.id}`)
                    }, 2000)
                }
            })
        })

    }//done


    async function multipleResellNft(id) {
        // alert(id)
        var pattern = new RegExp(/^[0-9\b]+$/);

        if (!NoResellBuyImage) {
            $("#noResellBuyImage").show();
            // return;
        } else if (NoResellBuyImage <= 0) {
            $("#noResellBuyImageCheckValidation").show();
            return;
        } else if (!pattern.test(NoResellBuyImage)) {
            // $("#noResellBuyInt").show();
            $("#noResellPriceBuyImageValid").show();
            return;
        }

        if (!noResellPrice) {
            $("#newResellPricecheck").show();
            // return;
        } else if (noResellPrice <= 0) {
            $("#newResellPricecheckValidation").show();
            return;
        }

        if (parseFloat(multipleNFTDetail.resell_remainingLoginSupply) < parseFloat(NoResellBuyImage)) {
            $("#noResellBuyImageCheck").show();
            return;
        }

        if (NoResellBuyImage && noResellPrice) {

            var tokens = Web3.utils.toWei(noResellPrice.toString(), 'ether')
            var bntokens = Web3.utils.toBN(tokens)

            // var platformFees_bn = Web3.utils.toBN(platformFees)

            document.getElementById("overlay").style.display = "block";
            setMultilpleResellShow(false)
            ERC1155ABiWthiCONTRACT.methods.setApprovalForAll(MARKETPLACE_CONTACT_ADDRESS, true)
                .send({
                    from: walletAddress,
                    // gas: 3000000
                }).on('error', function (error) {
                    console.log("error", error)
                }).then(async function (info) {
                    console.log('setApprovalForAllsetApprovalForAll', info);

                    ERC1155ABiWthiCONTRACT.methods._tokenIds()
                        .call()
                        .then(async function (fetch_data) {
                            console.log('fetchMarketItemsERC1155', fetch_data);
                            var token_id = fetch_data - 1;
                            console.log('fetchMarketItemsERC1155token_id', token_id);
                            // MarketplaceABiWthiCONTRACT.methods.listToken1155(token_id, NoResellBuyImage, bntokens, [])
                            MarketplaceABiWthiCONTRACT.methods.listToken1155(ERC1155_CONTACT_ADDRESS,nftInfo.token_id, NoResellBuyImage, bntokens, [])
                                .send({
                                    from: walletAddress,
                                    // gas: 3000000,
                                    // value: platformFees_bn

                                }).on('error', function (error) {
                                    console.log("error", error)

                                }).then(async function (info) {
                                    var transaction_id = info.transactionHash
                                    console.log("transaction_idsssssss", transaction_id)

                                    var item_id = info.events.TokenListed1155.returnValues[6]

                                    console.log("BharatNFTDATA", info)
                                    console.log("BharatNFTDATA", info.events.TokenListed1155.returnValues)
                                    console.log("BharatNFTDATA", info.events.TokenListed1155.returnValues[6])
                                    // var item_id = listingIds1155;

                                    var transaction_id = info.transactionHash

                                    const formData = new FormData();
                                    formData.append("wallet_address", walletAddress);
                                    formData.append("number_of_supply", NoResellBuyImage);
                                    formData.append("per_price", noResellPrice);
                                    // formData.append("token_id", checkingListingList.token_id);
                                    formData.append("token_id", nftInfo.token_id);
                                    formData.append("mint_id", id);
                                    formData.append("transaction_id", transaction_id);
                                    formData.append("item_id", item_id);

                                    console.log("multipleResell", formData);

                                    var multipleResell = await axios.post(`${BASE_URL}/resell_multiple_nft`, formData)

                                    console.log("multipleResell", multipleResell);

                                    if (multipleResell.status == 200) {
                                        toast.success('Resell NFT Successfully !', {
                                            position: "top-center",
                                            theme: "colored",
                                            autoClose: 1000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                                        document.getElementById("overlay").style.display = "none";
                                        setTimeout(() => {
                                            // navigate(`/prod_detail?id=${nftInfo.id}`)
                                            window.location.reload()
                                        }, 2000)
                                    } else {
                                        toast.error('Your resell nft failed !', {
                                            position: "top-center",
                                            theme: "colored",
                                            autoClose: 1000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                                        setTimeout(() => {
                                            // navigate(`/prod_detail?id=${nftInfo.id}`)
                                            window.location.reload()
                                        }, 2000)
                                    }
                                }).catch((err) => {
                                    console.log('eror ', err);
                                })
                        }).catch((err) => {
                            console.log('eror ', err);
                        })
                }).catch((err) => {
                    console.log("err", err)
                    toast.error('Your resell nft failed !', {
                        position: "top-center",
                        theme: "colored",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("overlay").style.display = "none";
                    setTimeout(() => {
                        // navigate(`/prod_detail?id=${nftInfo.id}`)
                        window.location.reload()
                    }, 2000)
                })
        }

    }


    // async function multipleBuyNft() {
    async function multipleBuyNft(id) {
        var pattern = /^[0-9\b]+$/;
        const number_pattern = /^[+-]?\d*(?:[.,]\d*)?$/;

        // alert(id);
        if (!walletAddress) {
            toast.error('Please connect with metamask!', {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        if (!noBuyImage) {
            $("#noBuyImage").show();
            $("#noBuyImageValid").hide();
            return;
        } else if (noBuyImage <= 0) {
            $("#noBuyImageCheckValidation").show();
            return;
        } 
        else if (!pattern.test(noBuyImage)) {
            // $("#noBuyImgInt").show();
            $("#noBuyImageValid").show();
            return;
        } 

        var remaining_img_supply = nftInfo.remaining_img_supply

        if (parseFloat(remaining_img_supply) < parseFloat(noBuyImage)) {
            $("#noBuyImageCheck").show();
            return;
        }

        var item_id = nftInfo.item_id;
        var tokenPrice = nftInfo.nft_price;

        // alert(item_id);
        // alert(tokenPrice);
        // alert(noBuyImage);

        var tokens = Web3.utils.toWei(tokenPrice.toString(), 'ether')
        var bntokens = Web3.utils.toBN(tokens)

        document.getElementById("overlay").style.display = "block";
        setMultilpleShow(false)


        MarketplaceABiWthiCONTRACT.methods.purchaseToken1155(item_id, noBuyImage)
            .send({
                from: walletAddress,
                // gas: 3000000,
                value: bntokens * noBuyImage
            }).on('error', function (error) {
                // console.log("error", error)
                toast.error('Buy nft failed !', {
                    position: "top-center",
                    theme: "colored",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                document.getElementById("overlay").style.display = "none";
                setTimeout(() => {
                    // navigate(`/prod_detail?id=${nftInfo.id}`)
                    window.location.reload()
                }, 2000)

            }).then(async function (info) {
                var transaction_id = info.transactionHash

                const formData = new FormData();
                formData.append("wallet_address", walletAddress);
                formData.append("token_id", nftInfo.token_id);
                formData.append("price", totalPrice);
                formData.append("no_of_buy_image", noBuyImage);
                formData.append("mint_id", id);
                formData.append("transaction_id", transaction_id);

                var buyBidNft = await axios.post(`${BASE_URL}/buy_multiple_nft`, formData)

                console.log("buyBidNft", buyBidNft);

                if (buyBidNft.status == 200) {
                    var ethBalance = await (await web3_Stake.eth.getBalance(walletAddress))
                    ethBalance = Web3.utils.fromWei(ethBalance, 'ether').slice(0, 6);
                    sessionStorage.setItem("ethBalance", ethBalance);

                    toast.success('Buy multiple nft successfully !', {
                        position: "top-center",
                        theme: "colored",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("overlay").style.display = "none";
                    setTimeout(() => {
                        // navigate(`/prod_detail?id=${nftInfo.id}`)
                        window.location.reload()
                    }, 2000)
                } else {
                    toast.error('Buy multiple nft failed !', {
                        position: "top-center",
                        theme: "colored",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("overlay").style.display = "none";
                    setTimeout(() => {
                        // navigate(`/prod_detail?id=${nftInfo.id}`)
                        window.location.reload()
                    }, 2000)
                }
            })
    }

    // async function multipleListBuyNft() {
    async function multipleListBuyNft(id) {
        // alert(id);
        var pattern = new RegExp(/^[0-9\b]+$/);

        if (!multipleNoBuyImage) {
            $("#noMultipleBuyImage").show();
            $("#noMultipleBuyImageValid").hide();
            return;
        } else if (multipleNoBuyImage <= 0) {
            $("#noMultipleBuyImageCheckValidation").show();
            return;
        } else if (!pattern.test(multipleNoBuyImage)) {
            // $("#noMultipleBuyImageInt").show();
            $("#noMultipleBuyImageValid").show();
            return;
        }

        var remaining_img_supply = listingBuyData.quantity

        if (parseFloat(remaining_img_supply) < parseFloat(multipleNoBuyImage)) {
            $("#noMultipleBuyImageCheck").show();
            return;
        }

        var item_id = listingBuyData.item_id;
        var tokenPrice = listingBuyData.price;



        var tokens = Web3.utils.toWei(tokenPrice.toString(), 'ether')
        var bntokens = Web3.utils.toBN(tokens)

        document.getElementById("overlay").style.display = "block";
        setMultilpleBuyShow(false);


        MarketplaceABiWthiCONTRACT.methods.purchaseToken1155(item_id, multipleNoBuyImage)
            .send({
                from: walletAddress,
                // gas: 3000000,
                value: bntokens * multipleNoBuyImage
            }).on('error', function (error) {
                // console.log("error", error)
                toast.error('Your Buy nft failed !', {
                    position: "top-center",
                    theme: "colored",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                document.getElementById("overlay").style.display = "none";
                setTimeout(() => {
                    setresellShow(false)
                    window.location.reload()
                    // navigate(`/prod_detail?id=${nftInfo.id}`)
                }, 2000)
            }).then(async function (info) {
                var transaction_id = info.transactionHash

                const formData = new FormData();
                formData.append("wallet_address", walletAddress);
                formData.append("token_id", listingBuyData.token_id);
                formData.append("price", MultipleTotalPrice);
                formData.append("no_of_buy_image", multipleNoBuyImage);
                formData.append("mint_id", id);
                formData.append("transaction_id", transaction_id);

                var multipleListBuyNft = await axios.post(`${BASE_URL}/buy_multiple_nft`, formData)

                console.log("buyBidNft", multipleListBuyNft);

                if (multipleListBuyNft.status == 200) {
                    toast.success('Buy nft successfully !', {
                        position: "top-center",
                        theme: "colored",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("overlay").style.display = "none";
                    setTimeout(() => {
                        // navigate(`/prod_detail?id=${nftInfo.id}`)
                        window.location.reload()
                    }, 2000)
                } else {
                    toast.error('Your Buy nft failed !', {
                        position: "top-center",
                        theme: "colored",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("overlay").style.display = "none";
                    setTimeout(() => {
                        setresellShow(false)
                        window.location.reload()
                        // navigate(`/prod_detail?id=${nftInfo.id}`)
                    }, 2000)
                }
            })
    }

    async function resellNFT() {
        // alert(1);
        if (!walletAddress) {
            toast.error('Please connect with metamask!', {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }
        if (!newName) {
            $("#nameheck").show();
        }

        if (!newDescription) {
            $("#desccheck").show();
        }

        if (!newPrice) {
            $("#pricecheck").show();
        } else if (newPrice <= 0) {
            $("#newPriceValidation").show();
        }
        else {
            var tokenPrice = newPrice;
            var tokens = Web3.utils.toWei(tokenPrice.toString(), 'ether')
            var bntokens = Web3.utils.toBN(tokens)
            var token_id = nftInfo.token_id;
            var loginUserAdd = walletAddress;

            setresellShow(false)

            document.getElementById("overlay").style.display = "block";

            ERC721ABiWthiCONTRACT.methods.approve(MARKETPLACE_CONTACT_ADDRESS, token_id)
                .send({
                    from: loginUserAdd,
                    // gas: 3000000
                }).on('error', function (error) {
                    console.log("error", error)
                }).then(async function (info) {
                    console.log('success ', info);

                    MarketplaceABiWthiCONTRACT.methods.createMarketItem721(ERC721_CONTACT_ADDRESS,token_id, bntokens)
                        .send({
                            from: loginUserAdd,
                            // gas: 3000000,
                            // value: platformFees_bn

                        }).on('error', function (error) {
                            console.log("error", error)
                        }).then(async function (info) {
                            
                            console.log('success ', info);
                            // console.log('success ', info.events.TokenListed721);
                            // console.log('success ', info.events.TokenListed721.returnValues);
                            // console.log('success_returnValues ', info.events.TokenListed721.returnValues[0]);

                            var transaction_id = info.transactionHash
                            // var item_id = info.events.TokenListed721.returnValues[0]
                            var item_id = 0

                            const formData = new FormData();
                            formData.append("wallet_address", walletAddress);
                            formData.append("token_id", nftInfo.token_id);
                            // formData.append("new_price", nftInfo.nft_price);
                            formData.append("new_price", newPrice);
                            formData.append("nft_name", newName);
                            formData.append("nft_description", newDescription);
                            formData.append("transaction_id", transaction_id);
                            formData.append("item_id", item_id);

                            var resellNFT = await axios.post(`${BASE_URL}/resellNFT`, formData)
                            console.log("resellNFT", resellNFT.status)
                            if (resellNFT.status == 200) {
                                var ethBalance = await (await web3_Stake.eth.getBalance(walletAddress))
                                ethBalance = Web3.utils.fromWei(ethBalance, 'ether').slice(0, 6);
                                sessionStorage.setItem("ethBalance", ethBalance);

                                console.log("Yes");
                                toast.success('Resell nft successfully !', {
                                    position: "top-center",
                                    theme: "colored",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                document.getElementById("overlay").style.display = "none";
                                setTimeout(() => {
                                    setresellShow(false)
                                    window.location.reload()
                                    // navigate(`/prod_detail?id=${nftInfo.id}`)
                                }, 2000)
                            }

                            // MarketplaceABiWthiCONTRACT.methods.fetchMarketItems721(token_id)
                            //     .call()
                            //     .then(async function (fetch_data) {
                            //         console.log('fetchMarketItemsERC721 ', fetch_data[0]);
                            //         console.log('fetchMarketItemsERC721ItemId ', fetch_data[0][0]);

                            //         var item_id = fetch_data[0][0];

                            //         var transaction_id = info.transactionHash

                            //     }).catch((err) => {
                            //         console.log('eror ', err);
                            //     })
                        }).catch((err) => {
                            console.log("err", err)
                        })
                }).catch((err) => {
                    console.log("err", err)
                    toast.error('You resell nft failed !', {
                        position: "top-center",
                        theme: "colored",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("overlay").style.display = "none";
                    setTimeout(() => {
                        setresellShow(false)
                        window.location.reload()
                        // navigate(`/prod_detail?id=${nftInfo.id}`)
                    }, 1000)
                })
        }

    }//done

    async function buyNFT() {
        if (!walletAddress) {
            toast.error('Please connect with metamask!', {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        // alert(1)
        // var item_id = nftInfo.item_id;
        var item_id = nftInfo.token_id;
        var tokenPrice = nftInfo.nft_price;

        var tokens = Web3.utils.toWei(tokenPrice.toString(), 'ether')
        var bntokens = Web3.utils.toBN(tokens)

        document.getElementById("overlay").style.display = "block";

        MarketplaceABiWthiCONTRACT.methods.createMarketSale721(ERC721_CONTACT_ADDRESS,item_id)
            .send({
                from: walletAddress,
                // gas: 2000000,
                value: bntokens
            }).on('error', function (error) {
                console.log("error", error)
                toast.error('Buy nft failed !', {
                    position: "top-center",
                    theme: "colored",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                document.getElementById("overlay").style.display = "none";
                setTimeout(() => {
                    // navigate(`/prod_detail?id=${nftInfo.id}`)
                    window.location.reload()
                }, 2000)

            }).then(async function (info) {
                var transaction_id = info.transactionHash

                const formData = new FormData();
                formData.append("wallet_address", walletAddress);
                formData.append("token_id", nftInfo.token_id);
                formData.append("price", nftInfo.nft_price);
                formData.append("transaction_id", transaction_id);

                var buyMint = await axios.post(`${BASE_URL}/buyNFT`, formData)
                console.log("buyMint", buyMint.status)
                if (buyMint.status == 200) {
                    var ethBalance = await (await web3_Stake.eth.getBalance(walletAddress))
                    ethBalance = Web3.utils.fromWei(ethBalance, 'ether').slice(0, 6);
                    sessionStorage.setItem("ethBalance", ethBalance);

                    toast.success('Buy nft successfully !', {
                        position: "top-center",
                        theme: "colored",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("overlay").style.display = "none";
                    setTimeout(() => {
                        // navigate(`/prod_detail?id=${nftInfo.id}`)
                        window.location.reload()
                    }, 2000)
                }
            })
    }//done


    async function detail(walletAddress) {

        let checkAddress = sessionStorage.getItem('loginUserAdd')
        // let checkAddress = 0x94025d7a70ba94ee97c58ddf9b7349da9419c64e
        if (checkAddress !== null && checkAddress !== "null") {
            var check_Address = checkAddress;
        } else {

            var check_Address = "";
        }
        var nft_info_data = await axios.get(BASE_URL + '/getMintNFTDeatils?id=' + id + '&address=' + check_Address)

        setmetaData(nft_info_data.data)
        setNftInfo(nft_info_data.data.result)
        setNewName(nft_info_data.data.result.name)
        setNewDescription(nft_info_data.data.result.description)

        var orderCreator = nft_info_data.data.result.wallet_address
        var mintType = nft_info_data.data.result.mint_type
        var buyResellStatus = nft_info_data.data.result.buyResellStatus
        setNftOwner(orderCreator);

        var bidPriceList = nft_info_data.data.result.bidPriceList;
        setbidPriceList(bidPriceList);

        var listingsList = nft_info_data.data.result.listings_array_list;
        setlistingsList(listingsList);

        var listingsBuyList = nft_info_data.data.result.listings_array_buy;
        setlistingsBuyList(listingsBuyList);

        var mulitpleNFT = nft_info_data.data.result.multiple_nft
        setMultipleNFTDetail(mulitpleNFT);
        setmultipleNFTUserListDetail(mulitpleNFT.userSupplyList_array)

        console.log("Nitesthhhhhhh", nft_info_data.data.result)

        if (nft_info_data.data.result.checkListings_array != "") {
            var checkingListingList = nft_info_data.data.result.checkListings_array;

            setcheckingListingList(checkingListingList);
        } else {
            var checkingListingList = nft_info_data.data.result;
            setcheckingListingList(checkingListingList);
        }

        const auction_date = Moment(nftInfo.aucation_date).format('MMMM DD YYYY h:mm A')
        const aucation_timestamp = nftInfo.aucation_timestamp;

        var fileName = nft_info_data.data.result.image;
        var fileExtension = fileName.split(/[#?]/)[0].split('.').pop().trim();


        setFileExtension(fileExtension);

        if (nft_info_data.data.result.wallet_address != checkAddress) {
            if (parseInt(nft_info_data.data.result.create_mint_type) === 721 || parseInt(nft_info_data.data.result.mint_status) === 1) {
                setShowBuyButton(false)
                setShowResellButton(false)
            }
        }

        var ERC1155_token_id = nft_info_data.data.result.token_id;
        var ERC1155item_id = nft_info_data.data.result.item_id;
        // MarketplaceABiWthiCONTRACT.methods.viewListingByTokenId1155(nft_info_data.data.result.token_id)
        // MarketplaceABiWthiCONTRACT.methods.viewListing1155(ERC1155item_id, ERC1155_token_id)
        MarketplaceABiWthiCONTRACT.methods.viewListing1155(ERC1155item_id)
            .call()
            .then(async function (fetch_data_listing) {

                console.log("seller data1", fetch_data_listing)


                // var listing_completed_status = fetch_data_listing[8];
                var listing_completed_status = fetch_data_listing["completed"];
                console.log("seller data3", listing_completed_status)
                console.log("seller dataa3", fetch_data_listing["completed"])

                var listing_buyer = fetch_data_listing[1];

                var listing_seller = fetch_data_listing[0];
                var tokenAvaiable = fetch_data_listing[5];

                if (checkAddress.toLowerCase() == listing_seller.toLowerCase()) {
                    setShowMultipleBuyButton(false);
                    // setShow1155MultipleResellButton(false);
                    console.log("seller data", tokenAvaiable)

                    ERC1155ABiWthiCONTRACT.methods.balanceOf(checkAddress, nft_info_data.data.result.token_id)
                        .call()
                        .then(async function (checkAddress_balance) {
                            console.log("login user_balance data", checkAddress_balance)

                            if (checkAddress_balance == tokenAvaiable) {
                                setShow1155MultipleResellButton(false);
                            } else {
                                // setShow1155MultipleResellButton(true);
                                if (mulitpleNFT.resell_remainingLoginSupply == 0) {
                                    setShow1155MultipleResellButton(false);
                                } else {
                                    setShow1155MultipleResellButton(true);
                                }
                            }
                        }).catch((err) => {
                            console.log('eror ', err);
                        })
                } else {
                    if (listing_completed_status == false || listing_completed_status == "false") {
                        console.log("seller data2", listing_buyer)

                        if (listing_buyer == 0) {
                            console.log("seller data4")
                            setShowMultipleBuyButton(true);
                            setShow1155MultipleResellButton(false);
                        } else {
                            console.log("seller data5")
                            for (var i = 0; i <= listing_buyer.length - 1; i++) {
                                var buyer_address = listing_buyer[i].toLowerCase();
                                if (checkAddress.toLowerCase() === buyer_address) {

                                    if (mulitpleNFT.resell_remainingLoginSupply == 0) {
                                        setShow1155MultipleResellButton(false);
                                    } else {
                                        setShow1155MultipleResellButton(true);
                                    }
                                    setShowMultipleBuyButton(true);
                                } else {

                                    setShowMultipleBuyButton(true);
                                    // setShow1155MultipleResellButton(false);
                                    if (mulitpleNFT.resell_remainingLoginSupply == 0) {
                                        setShow1155MultipleResellButton(false);
                                    } else {
                                        setShow1155MultipleResellButton(true);
                                    }
                                }
                            }
                        }
                    } else {
                        console.log("compleeeeeeeeeeeeeeeeeee shivami")
                        if (listing_buyer != 0) {
                            for (var i = 0; i <= listing_buyer.length - 1; i++) {
                                var buyer_address = listing_buyer[i].toLowerCase();
                                if (checkAddress.toLowerCase() === buyer_address) {
                                    // setShow1155MultipleResellButton(true);
                                    if (mulitpleNFT.resell_remainingLoginSupply == 0) {
                                        setShow1155MultipleResellButton(false);
                                    } else {
                                        setShow1155MultipleResellButton(true);
                                    }
                                    setShowMultipleBuyButton(false);
                                } else {

                                    setShowMultipleBuyButton(false);
                                    // setShow1155MultipleResellButton(false);
                                    if (mulitpleNFT.resell_remainingLoginSupply == 0) {
                                        setShow1155MultipleResellButton(false);
                                    } else {
                                        setShow1155MultipleResellButton(true);
                                    }
                                }
                            }
                        }


                    }
                }

            }).catch((err) => {
                console.log('eror ', err);
            })


        if (mintType == 1) {
            if (userDetail) {
                if (userDetail.address == '' || userDetail.address === "null" || userDetail.address == null) {

                    setShowBuyButton(true)
                    setShowResellButton(false)
                } else {
                    if (buyResellStatus == 0) {
                        console.log("13dgd",ownerAddress)
                        console.log("13dg4d",userDetail.address)

                        if (ownerAddress === userDetail.address) {
                            setShowBuyButton(false)
                            setShowResellButton(false)
                        } else {
                            setShowBuyButton(true)
                            setShowResellButton(false)
                        }
                    } else if (buyResellStatus == 1) {
                        // console.log("pri=ayaaaaaaaaaa",nft_info_data.data.result.create_mint_type)
                        if(nft_info_data.data.result.create_mint_type == 721){
                            ERC721ABiWthiCONTRACT.methods.ownerOf(nft_info_data.data.result.token_id)
                                .call()
                                .then(async (fixedAddress) => {
                                    console.log("getOwnerDetailgetOwnerDetail", fixedAddress)
                                    if (checkAddress == fixedAddress.toLowerCase()) {
                                        console.log("listeddddddd")
                                        setShowResellButton(true)
                                    } else {
                                        console.log("not listeddddddd")
                                        setShowBuyButton(false)
                                        setShowResellButton(false)
                                    }
                                }).catch((err) => {
                                    console.log('eror ', err);
                                })
                        }
                    } else if (buyResellStatus == 2) {
                        if (userDetail.address === nftOwnerAddress) {
                            setShowBuyButton(false)
                            setShowResellButton(false)
                        }
                    }
                }
            } else {
                setShowBuyButton(true)
                setShowResellButton(false)
            }

        } else {

            const highest_bid = nftInfo.highest_bid;
            // console.log("highest_bid",highest_bid)
            setSetHighestBidPrice(highest_bid)

            setShowBuyButton(false)
            setShowResellButton(false)

            MarketplaceABiWthiCONTRACT.methods.getTokenAuctionDetails721(ERC721_CONTACT_ADDRESS,nft_info_data.data.result.token_id)
                .call()
                .then(async function (auctionDetail) {
                    console.log("shivani auctionDetail", auctionDetail)
                    console.log("shivani auctionDetail 5", auctionDetail[5])
                    console.log("shivani auctionDetail 3", auctionDetail[3])

                    var highestBid = Web3.utils.toWei(auctionDetail[3], 'ether')
                    var highest_Bid = Web3.utils.toBN(highestBid)
                    console.log("shivani auctionDetail 34", highest_Bid)
                    setSetHighestBidPrice(highest_Bid)

                    var sellerData = auctionDetail[0].toLowerCase()
                    var bidStatus = auctionDetail[5]
                    var maxBidUser = auctionDetail[4].toLowerCase()
                    var usersList = auctionDetail[7]
                    var auctionDuation = auctionDetail[2]

                    setSellerData(sellerData)
                    setbidStatus(bidStatus)
                    setMaxBidUserData(maxBidUser)
                    setUsersListData(usersList)
                    setAuctionDuation(auctionDuation)

                    if (checkAddress == sellerData) {
                        console.log("shivani auctionDetail seller")
                        if (current_date_timestamp > auctionDuation) {
                            if (usersList.length == 0) {
                                console.log("shivani auctionDetail no bid")
                                setShowBidResellutton(true);
                                setShowBidButton(false);
                            } else {
                                console.log("shivani auctionDetail bid")
                                setShowBidResellutton(false);
                                setShowBidButton(false);
                            }
                        } else {
                            setShowBidResellutton(false);
                            setShowBidButton(false);
                        }
                    } else {
                        console.log("shivani auctionDetail not seller login")

                        if (bidStatus === true) {
                            if (current_date_timestamp > auctionDuation) {
                                console.log("shivani auctionDetail overtime")

                                if (maxBidUser == checkAddress) {
                                    console.log("shivani auctionDetail bid user1 avaiable")
                                    setShowBidBuyButton(true);
                                }
                            } else {
                                console.log("shivani auctionDetail reamining")
                                if (usersList.length != 0) {
                                    console.log("shivani auctionDetail bid usersList", usersList)

                                    var userPushLowerArray = []

                                    for (var i = 0; i <= usersList.length - 1; i++) {
                                        var userAddres = usersList[i].toLowerCase()

                                        userPushLowerArray.push(userAddres)


                                        if (userAddres == checkAddress) {
                                            console.log("shivani auctionDetail bid user avaiable")

                                            setShowBidButton(false)
                                        } else {
                                            setShowBidButton(true)
                                        }
                                    }
                                    console.log("shivani auctionDetail rahuuuuuuuuu", userPushLowerArray)
                                    setUsersListLowerDataArray(userPushLowerArray)
                                } else {
                                    console.log("shivani auctionDetail bid user avaible")
                                    setShowBidButton(true);
                                }
                            }

                        }
                        else {
                            console.log("shivani auctionDetail bid/buy complete")
                            setShowBidResellutton(true);
                        }
                    }

                }).catch((err) => {
                    console.log('eror ', err);
                })
        }

    }

    const [pageUpdate, setPageUpdate] = useState(false)
    useEffect(() => {
        var loginUserAdd = sessionStorage.getItem("loginUserAdd");

        if (loginUserAdd) {
            setwalletAddress(loginUserAdd)
            detail(loginUserAdd)
        }
        detail()

    }, [pageUpdate])


    const checkBidValidation = () => {
        const number_pattern = /^[+-]?\d*(?:[.,]\d*)?$/;

        if (!number_pattern.test(newBidPrice)) {
            $("#bidPricecheck").hide();
            $("#bidPriceValid").show();
            return;
        } else {
            $("#bidPriceValid").hide();
        }
    }

    const checkNumberofBuy = () => {
        const number_pattern = /^[0-9\b]+$/;
        if (!number_pattern.test(noBuyImage)) {
            $("#noBuyImage").hide();
            $("#noBuyImageCheck").hide();
            $("#totalPriceDiv").hide();
            $("#noBuyImageValid").show();
            
            return;
        } else {
            $("#noBuyImageValid").hide();
        }
    }

    const checkMulipleNoBuyValidation = () => {
        const number_pattern = /^[0-9\b]+$/;
    
        if (!number_pattern.test(multipleNoBuyImage)) {
            $("#noMultipleBuyImage").hide();
            $("#totalMultiplePriceDiv").hide();
            $("#noMultipleBuyImageValid").show();
            return;
        } else {
            $("#noMultipleBuyImageValid").hide();
            var remaining_img_supply = listingBuyData.quantity

            if (parseFloat(remaining_img_supply) < parseFloat(multipleNoBuyImage)) {
                $("#noMultipleBuyImageCheck").show();
                $("#totalMultiplePriceDiv").hide();
                return;
            }else{
                $("#totalMultiplePriceDiv").show();
            }
        }
    }

    const checkMulipleNoResellValidation = () => {
        const number_pattern = /^[0-9\b]+$/;
    
        if (!number_pattern.test(NoResellBuyImage)) {
            $("#noResellBuyImage").hide();
            $("#noResellBuyInt").hide();
            $("#noResellBuyImageValid").show();

            return;
        } else {
            $("#noResellBuyImageValid").hide();
            if (parseFloat(multipleNFTDetail.resell_remainingLoginSupply) < parseFloat(NoResellBuyImage)) {
                $("#noResellBuyImageCheck").show();
                return;
            }
        }
    }

    const checkMulipleNoPriceResellValidation = () => {
        const number_pattern = /^[+-]?\d*(?:[.,]\d*)?$/;
    
        if (!number_pattern.test(noResellPrice)) {
            $("#newResellPricecheck").hide();
            // $("#noResellBuyInt").hide();
            $("#noResellPriceBuyImageValid").show();

            return;
        }else {
            $("#noResellPriceBuyImageValid").hide();
        }
    }

    // setTimeout(() => {
    //     detail(walletAddress)
    // }, 60000)

    // =============Read more read less state start===============
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
        
    };
    // =============Read more read less state end===============

     // =============Bid List pagination start===============
     const [currentItemsBid, setCurrentItemsBid] = useState(null);
     const [pageCountBid, setPageCountBid] = useState(0);
 
     const [itemOffsetBid, setItemOffsetBid] = useState(0);
     const itemsPerPageBid = 6
     useEffect(() => {
 
         const endOffsetBid = itemOffsetBid + itemsPerPageBid;
 
         setCurrentItemsBid(bidPriceList.slice(itemOffsetBid, endOffsetBid));
         setPageCountBid(Math.ceil(bidPriceList.length / itemsPerPageBid));
     }, [itemOffsetBid, itemsPerPageBid, bidPriceList]);
 
     const handlePageClickBid = (event) => {
         const newOffsetBid = (event.selected * itemsPerPageBid) % bidPriceList.length;
         setItemOffsetBid(newOffsetBid);
     };
   // =============Buy List pagination start===============
    const [currentBuyList, setCurrentBuyList] = useState([]);
    const [pageCountBuyList, setPageCountBuyList] = useState(0);

    const [itemOffsetBuyList, setItemOffsetBuyList] = useState(0);
    const itemsPerPageBuyList = 6
    useEffect(() => {

        const endOffsetBuyList = itemOffsetBuyList + itemsPerPageBuyList;

        setCurrentBuyList(listingsBuyList.slice(itemOffsetBuyList, endOffsetBuyList));
        setPageCountBuyList(Math.ceil(listingsBuyList.length / itemsPerPageBuyList));
    }, [itemOffsetBuyList, itemsPerPageBuyList, listingsBuyList]);

    const handlePageClickBuyList = (event) => {
        const newOffsetBuyList = (event.selected * itemsPerPageBuyList) % listingsBuyList.length;
        setItemOffsetBuyList(newOffsetBuyList);
    };
    // =============Buy List pagination end===============
console.log("currentBuyList", currentBuyList)
    return (

        <>
            <div id="overlay">

                <div className='loader_spiner'>
                    <ImSpinner7 />
                    <div className="loader_text">
                        <strong>Please wait while complete your processing...</strong>
                    </div>
                </div>
            </div>


            <div>
                <Header />
                <section style={{ minHeight: "100vh" }}>
                    <Container className='mt-2'>

                        <Row>
                            <Col lg={12}>
                                {/* <Link to='/' className='GoBack_btn fw-bold'><FaArrowLeft className='back-icon' />  Go Back</Link> */}
                                <button className='GoBack_btn fw-bold' onClick={() => navigate(-1)} style={{ backgroundColor: "transparent" }}><FaArrowLeft className='back-icon' />  Go Back</button>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col lg={4} md={12} className="p-lr-0">

                                <div className={(fileExtension == 'mp3') ? 'prod-img_audioH' : 'prod-img'}  >
                                    {(() => {
                                        if (fileExtension == 'mp3') {
                                            return (
                                                <div>
                                                    <img src={nftInfo.preview_image} alt="featured" className='img-fluid' />
                                                    <audio controls controlsList="nodownload" style={{ width: "100%", marginTop: "5px" }}>
                                                        <source src={nftInfo.image} type="audio/ogg"></source>
                                                        <source src={nftInfo.image} type="audio/mpeg"></source>
                                                        Your browser does not support the audio element.
                                                    </audio>

                                                </div>
                                            )
                                        } else if (fileExtension == 'mp4') {
                                            return (
                                                <video autoPlay={true} loop={true} controls className='img-fluid' controlsList="nodownload" oncontextmenu="return false;">
                                                    <source src={nftInfo.image} type="video/mp4"></source>
                                                    Your browser does not support the audio element.
                                                </video>
                                            )
                                        } else {
                                            return fileExtension == "png" ||
                                                fileExtension == "jpg" ||
                                                fileExtension == "gif" ||
                                                fileExtension == "jpeg" ||
                                                fileExtension == "svg" ? 
                                                (
                                                    <img src={nftInfo.image} alt="featured" className='img-fluid' />
                                            ) : (
                                                <div className='threeD_custom'>
                                                    <model-viewer src={nftInfo.image} alt="VR Headset" auto-rotate camera-controls ar ios-src={nftInfo.image}>
                                                    </model-viewer>
                                                </div>
                                            );
                                        }
                                      
                                    })()}
                                </div>


                                <div className="prod_detail_div">

                                    <div className="prod_user_price">
                                        <div className='owner-img'>
                                            <img src={nftInfo.userProfile} alt="profile" className='img-fluid' />

                                            <div className='' style={{ display: "flex", gap: "6px" }}>
                                                {
                                                    (() => {
                                                        if (nftInfo.create_mint_type === "721") {
                                                            return (
                                                                <h6>Owned By</h6>
                                                            )
                                                        } else {
                                                            return (
                                                                <h6>Created By</h6>
                                                            )
                                                        }
                                                    })()
                                                }

                                                <Link to={`/User_profile?id=${nftInfo.user_id}`}>{nftOwnerAddress.slice(0, 4)}.....{nftOwnerAddress.slice(-4, nftOwnerAddress.length)}</Link>
                                                <CopyToClipboard text={nftOwnerAddress}
                                                    onCopy={() => copyText()}>
                                                    <span><FaRegCopy className='copy-icon ms-1' /></span>
                                                </CopyToClipboard>
                                            </div>
                                            <span style={{ color: '#0d6efd', display: "none" }} className="copy" id="copyAddress" >Copied.</span>
                                        </div>
                                        <div className='prod_nft_price_div'>
                                            <div className='prod_nft_price_text'>Price</div>
                                            <div className='prod_nft_price'>
                                                {
                                                    (() => {
                                                        if(nftInfo.create_mint_type == 721 && nftInfo.mint_type == 2){
                                                            return(
                                                                <img src={bharatToken} alt="img" className='img-fluid me-1' />
                                                            )
                                                        }else{
                                                            if (nftInfo.chain === "BNB") {

                                                                return (
                                                                    <img src={bnb} alt="img" className='img-fluid me-1' />
                                                                )
                                                            } else {
                                                                return (
                                                                    <img src={ethereum} alt="img" className='img-fluid me-1' />
                                                                )
                                                            }
                                                        }
                                                    })()
                                                }

                                                {/* {(nftInfo.checkBidUser !== 2) ? nftInfo.highest_bid: nftInfo.price } */}
                                                {nftInfo.bidPriceList === "" ? nftInfo.price : nftInfo.highest_bid}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div> */}
                                    {
                                        (nftInfo.mint_type == 2)
                                            ?
                                            <div>
                                                <div className="countdown_bid " style={{ borderTop: (current_date_timestamp > nftInfo.aucation_timestamp) ? '0px' : '1px solid #e0e0e0', padding: (current_date_timestamp > nftInfo.aucation_timestamp) ? '2px' : '15px' }}>
                                                    {
                                                        (() => {
                                                            if (current_date_timestamp > nftInfo.aucation_timestamp) {
                                                                return (<div className="countdown_text" style={{ display: "none" }}>Sale ends {nftInfo.aucationDate} at {nftInfo.aucationTime}</div>)
                                                            } else {
                                                                return (<div className="countdown_text">Sale ends {nftInfo.aucationDate} at {nftInfo.aucationTime}</div>)

                                                            }
                                                        })()
                                                    }
                                                    <div className="count_down">
                                                        {
                                                            (() => {
                                                                if (current_date_timestamp > nftInfo.aucation_timestamp) {
                                                                    return (<div className="countdown" style={{ display: "none" }}>
                                                                        <Timer currentTime={auction_date} />
                                                                    </div>)
                                                                } else {
                                                                    return (<div className="countdown">
                                                                        <Timer currentTime={auction_date} />
                                                                    </div>)

                                                                }
                                                            })()
                                                        }

                                                        {/* <div className="bid_div"> */}
                                                        {
                                                            (() => {
                                                                if (walletAddress) {
                                                                    if (userDetail) {

                                                                        if (walletAddress == sellerData) {
                                                                            console.log("shivani auctionDetail1 seller")
                                                                            if (current_date_timestamp > auctionDuation) {
                                                                                if (usersList.length == 0) {
                                                                                    console.log("shivani auctionDetail1 no bid")
                                                                                    return (
                                                                                        <div className="bid_div"><button className='bid_resell_btn' onClick={handleResellBidShow}>Resell</button></div>
                                                                                    )
                                                                                } else {
                                                                                    console.log("shivani auctionDetail1 bid")
                                                                                    return (null)
                                                                                }
                                                                            } else {
                                                                                console.log("shivani auctionDetail1 biddfgfdgfdgfdg")
                                                                                return (null)
                                                                            }
                                                                        } else {
                                                                            console.log("shivani auctionDetail1 not seller login")

                                                                            if (bidStatus === true) {
                                                                                if (current_date_timestamp > auctionDuation) {
                                                                                    console.log("shivani auctionDetail1 overtime")

                                                                                    if (maxBidUser == walletAddress) {
                                                                                        console.log("shivani auctionDetail1 bid user1 avaiable")
                                                                                        return (
                                                                                            <div className="bid_div"><button className='bid_buy_btn' onClick={buyBidNFT}>Buy</button></div>
                                                                                        )
                                                                                    }
                                                                                } else {
                                                                                    console.log("shivani auctionDetail1 reamining")
                                                                                    if (usersList.length != 0) {
                                                                                        console.log("shivani auctionDetail1 bid usersList", usersList)
                                                                                        console.log("shivani auctionDetail1 bid include", usersList.includes(walletAddress))
                                                                                        console.log("shivani auctionDetail1 bid getUserAddress", walletAddress)
                                                                                        console.log("shivani auctionDetail1 bid getUserAddressfdgdf", usersListLowerArray)
                                                                                        if (usersListLowerArray.includes(walletAddress) == true) {
                                                                                            console.log("shivani auctionDetail1 bid user avaiable2 no")
                                                                                            // return (null)
                                                                                            return (
                                                                                                <div className="bid_div"><button className='bid_btn' onClick={handleBidShow} disabled title='You bid placed already' style={{ background: "#e5cdd2" }}>Bid</button></div>
                                                                                            )
                                                                                        } else {
                                                                                            console.log("shivani auctionDetail1 bid userdrtrtet yes")
                                                                                            return (
                                                                                                <div className="bid_div"><button className='bid_btn' onClick={handleBidShow}>Bid</button></div>
                                                                                            )
                                                                                        }
                                                                                    } else {
                                                                                        console.log("shivani auctionDetail1 bid user avaible7")
                                                                                        return (
                                                                                            <div className="bid_div"><button className='bid_btn' onClick={handleBidShow}>Bid</button></div>
                                                                                        )
                                                                                    }
                                                                                }

                                                                            }
                                                                            else {
                                                                                //  console.log("shivani auctionDetail1 bid/buy complete")

                                                                                if (maxBidUser == walletAddress) {
                                                                                    console.log("shivani auctionDetail1 bid user1 avaiable")
                                                                                    return (
                                                                                        <div className="bid_div"><button className='bid_resell_btn' onClick={handleResellBidShow}>Resell</button></div>
                                                                                    )
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                }
                                                            })()
                                                        }
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                    {

                                        (() => {
                                            if (walletAddress) {
                                                if (userDetail) {
                                                    if (userDetail.address === nftOwnerAddress) {
                                                        if (nftInfo.create_mint_type == 721) {
                                                            if (nftInfo.buyResellStatus == 1) {
                                                                if (nftInfo.mint_type == 1) {
                                                                    return (resellButton ?
                                                                        <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                            <button className='resell_btn' onClick={handleResellShow}>Resell</button>
                                                                        </div></div>
                                                                        :
                                                                        <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                            <div>Owned By You</div>
                                                                        </div></div>
                                                                    )
                                                                }

                                                            } else if (nftInfo.buyResellStatus == 0) {
                                                                if (nftInfo.create_mint_type == 721 && nftInfo.mint_type == 1) {
                                                                    return (
                                                                        <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                            <div>Owned By You</div>
                                                                        </div></div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <div className="prod_nft_buy_div" style={{ display: "none" }}><div className='buy_div'>
                                                                            <div>Owned By You</div>
                                                                        </div></div>
                                                                    )
                                                                }

                                                            } else {
                                                                if (userDetail.address === nftOwnerAddress) {
                                                                    if (nftInfo.mint_type == 1) {
                                                                        return (
                                                                            <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                                <div>Owned By You</div>
                                                                            </div></div>
                                                                        )
                                                                    }

                                                                } else {
                                                                    return (buyButton ?
                                                                        <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                            <button className='buy_btn' onClick={buyNFT}>Buy</button>
                                                                        </div></div>
                                                                        :
                                                                        <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                            <div>Owned By You</div>
                                                                        </div></div>
                                                                    )
                                                                }

                                                            }
                                                        } else {
                                                            return (multiple1155ResellButton ?
                                                                <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                    <button className='resell_btn' onClick={handleMultipeResellShow}>Resell</button>
                                                                </div></div>
                                                                :
                                                                <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                    <div>Owned By You</div>
                                                                </div></div>
                                                            )
                                                        }


                                                    } else {
                                                        if (nftInfo.mint_type == 1) {
                                                            if (nftInfo.buyResellStatus == 0) {


                                                                if (nftInfo.create_mint_type == 721) {
                                                                    if (nftInfo.mint_status == 1) {
                                                                        return (null)
                                                                    } else {
                                                                        return (buyButton ?
                                                                            <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                                <button className='buy_btn' onClick={buyNFT}>{buyButton} Buy</button>
                                                                            </div></div>
                                                                            :
                                                                            <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                                <div>Owned By You</div>
                                                                            </div></div>
                                                                        )
                                                                    }

                                                                } else {
                                                                    if (nftInfo.mint_status == 0) {
                                                                        return (multipleBuyButton ?
                                                                            <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                                <button className='buy_btn' onClick={handleMultilpleShow}>Buy</button>
                                                                            </div></div>
                                                                            :
                                                                            null
                                                                        )
                                                                    }

                                                                }
                                                            }
                                                            else if (nftInfo.buyResellStatus == 1) {

                                                                if (nftInfo.create_mint_type == 721) {
                                                                    return (resellButton ?
                                                                        <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                            <button className='resell_btn' onClick={handleResellShow}>Resell</button>
                                                                        </div></div>
                                                                        :
                                                                        <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                            <div>Owned By You</div>
                                                                        </div></div>
                                                                    )
                                                                } else {

                                                                    if (nftInfo.create_mint_type == 1155) {
                                                                        return (
                                                                            <div className="prod_nft_buy_div">
                                                                                <div className='buy_div'>
                                                                                    {
                                                                                        multipleBuyButton ?
                                                                                            <div className='buy_div'>
                                                                                                <button className='buy_btn' onClick={handleMultilpleShow}>Buy</button>
                                                                                            </div>
                                                                                            :
                                                                                            null

                                                                                    }

                                                                                    {
                                                                                        multiple1155ResellButton ?
                                                                                            <div className='buy_div'>
                                                                                                <button className='resell_btn' onClick={handleMultipeResellShow}>{multiple1155ResellButton} Resell</button>
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }

                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    }


                                                                }
                                                            }
                                                            else if (nftInfo.buyResellStatus == 2) {
                                                                if (userDetail.address === nftOwnerAddress) {

                                                                    return (
                                                                        <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                            <div>Owned By You</div>
                                                                        </div></div>
                                                                    )
                                                                } else {
                                                                    if (nftInfo.create_mint_type == 1155) {

                                                                        return (
                                                                            <div className="prod_nft_buy_div">
                                                                                <div className='buy_div'>
                                                                                    {
                                                                                        multipleBuyButton ?
                                                                                            <div className='buy_div'>
                                                                                                <button className='buy_btn' onClick={handleMultilpleShow}>Buy</button>
                                                                                            </div>
                                                                                            :
                                                                                            null

                                                                                    }

                                                                                    {
                                                                                        multiple1155ResellButton ?
                                                                                            <div className='buy_div'>
                                                                                                <button className='resell_btn' onClick={handleMultipeResellShow}>{multiple1155ResellButton} Resell</button>
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }

                                                                                </div>

                                                                            </div>
                                                                        )

                                                                    } else {
                                                                        if (userDetail.address === nftOwnerAddress) {
                                                                            return (
                                                                                <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                                    <div>Owned By You</div>
                                                                                </div></div>
                                                                            )
                                                                        } else {
                                                                            if (nftInfo.mint_status != 1) {
                                                                                return (<div className="prod_nft_buy_div"><div className='buy_div'>
                                                                                    <button className='buy_btn' onClick={buyNFT}>Buy</button>
                                                                                </div></div>)
                                                                            }

                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                    }
                                                } else {
                                                    if (nftInfo.buyResellStatus == 1) {
                                                        return (resellButton ?
                                                            <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                <button className='resell_btn' onClick={handleResellShow}>Resell</button>
                                                            </div></div>
                                                            :
                                                            <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                <div>Owned By You</div>
                                                            </div></div>
                                                        )
                                                    } else if (nftInfo.buyResellStatus == 0) {

                                                        return (buyButton ?
                                                            <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                <button className='buy_btn' onClick={buyNFT}>Buy</button>
                                                            </div></div>
                                                            :
                                                            <div className="prod_nft_buy_div"><div className='buy_div'>
                                                                <div>Owned By You</div>
                                                            </div></div>
                                                        )
                                                    } else {
                                                        // return (
                                                        //     <div className="prod_nft_buy_div"><div className='buy_div'>
                                                        //         <div>Owned By You</div>
                                                        //     </div></div>
                                                        // )
                                                        if (nftInfo.create_mint_type == 1155) {
                                                            return (
                                                                <div className="prod_nft_buy_div">
                                                                    <div className='buy_div'>
                                                                        {
                                                                            multipleBuyButton ?
                                                                                <div className='buy_div'>
                                                                                    <button className='buy_btn' onClick={handleMultilpleShow}>Buy</button>
                                                                                </div>
                                                                                :
                                                                                null

                                                                        }

                                                                        {
                                                                            multiple1155ResellButton ?
                                                                                <div className='buy_div'>
                                                                                    <button className='resell_btn' onClick={handleMultipeResellShow}>{multiple1155ResellButton} Resell1</button>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }

                                                                    </div>

                                                                </div>
                                                            )
                                                        } else {
                                                            return (<div className="prod_nft_buy_div"><div className='buy_div'>
                                                                <div>Owned By You</div>
                                                            </div></div>)
                                                        }
                                                    }
                                                }
                                            }

                                        })()
                                    }



                                </div>



                            </Col>
                            <Col lg={8} md={12} className="mt-20">

                            <div className="total_owner_div">
                                    <ul>
                                        
                                        {
                                            
                                            (() => {
                                                if (nftInfo.collectionName != "") {
                                                    var collectionDetails = `/collection/${nftInfo.new_url_name}`
                                                    return (
                                                    // <div className="list_1155" style={{display:"flex", gap:"13px"}}>
                                                        <li> 
                                                        <Link to={collectionDetails}><span className='name'>{nftInfo.collectionName}</span></Link>
                                                        </li>
                                                    // </div> 
                                                    )
                                                }
                                            })()
                                        }

                                        {(nftInfo.create_mint_type == "1155") ? 
                                                <div className="list_1155" style={{display:"flex", gap:"13px"}}>
                                                    <li className='owners_li' onClick={handleShowOwner}><span className='icons'><HiUsers /></span> <span className='name'>{multipleNFTDetail.totalUserSupply} Owners</span></li>
                                                    <li><span className='icons'><BsGrid3X2GapFill /> </span><span className='name'>{nftInfo.number_of_image} Total Supply</span></li>
                                                    { walletAddress ? <li><span className='icons'><HiUser /></span> <span className='name'>You Own {multipleNFTDetail.totalLoginSupply}</span></li> : ""}
                                                </div> 
                     
                                            :""
                                        }
                                    </ul>
                                </div>
                                <Tabs defaultActiveKey="nft_details" id="uncontrolled-tab-example" className="mb-3 cus-tabs">
                                    <Tab eventKey="nft_details" title="NFT Details">
                                        <ListGroup variant="flush" style={{ border: "1px solid #e0e0e0", borderRadius: "10px" }}>
                                            <ListGroup.Item className='d-flex justify-content-start'>
                                                <div className='w-50'><h6>Title</h6></div>
                                                <div className='w-50 address_link'>{nftInfo.name}</div>
                                            </ListGroup.Item>

                                            <ListGroup.Item className='d-flex justify-content-start'>
                                                <div className='w-50'><h6>Description</h6></div>
                                                <div className={isReadMore ? 'w-50 address_link addressStyle1' : 'w-50 address_link addressStyle'}>
                                                    {isReadMore ? (nftInfo.description ? nftInfo.description.slice(0, 150) : '-') : (nftInfo.description ? nftInfo.description : '-')}
                                                    {(nftInfo.description && nftInfo.description.length >= 150) ?
                                                        <span onClick={toggleReadMore} className="read-or-hide">
                                                            {isReadMore ? "...read more" : " show less"}
                                                        </span>
                                                        :
                                                        <span></span>
                                                    }
                                                </div>
                                            </ListGroup.Item>

                                            <ListGroup.Item className='d-flex justify-content-start'>
                                                <div className='w-50'><h6>Category</h6></div>
                                                <div className='w-50' style={{ textTransform: "capitalize" }}>{nftInfo.category}</div>
                                            </ListGroup.Item>

                                            <ListGroup.Item className='d-flex justify-content-start'>
                                                <div className='w-50'><h6>Royalty</h6></div>
                                                <div className='w-50'>{nftInfo.royalty}%</div>
                                            </ListGroup.Item>

                                            {
                                                (() => {
                                                    if (nftInfo.create_mint_type == "1155") {
                                                        return (<ListGroup.Item className='d-flex justify-content-start'>
                                                            <div className='w-50'><h6>Total Supply</h6></div>
                                                            <div className='w-50'>{nftInfo.number_of_image}</div>
                                                        </ListGroup.Item>)
                                                    }
                                                })()
                                            }

                                        </ListGroup>
                                    </Tab>
                                    <Tab eventKey="details" title="Details">
                                        <div className="price_table">
                                            <ListGroup variant="flush" style={{ border: "1px solid #e0e0e0", borderRadius: "10px" }}>
                                                <ListGroup.Item className='d-flex justify-content-start'>
                                                    <div className='w-50'><h6>Contract Address</h6></div>
                                                    <div className='address_link w-50'>
                                                        <a href={"https://etherscan.io/address/" + MARKETPLACE_CONTACT_ADDRESS} target="_blank">
                                                            {MARKETPLACE_CONTACT_ADDRESS.slice(0, 4)}.....{MARKETPLACE_CONTACT_ADDRESS.slice(-4, MARKETPLACE_CONTACT_ADDRESS.length)}
                                                        </a>
                                                    </div>
                                                </ListGroup.Item>
                                                <ListGroup.Item className='d-flex justify-content-start'>
                                                    <div className='w-50'><h6>Token ID</h6></div>
                                                    <div className='w-50 address_link'>{nftInfo.token_id}</div>
                                                </ListGroup.Item>
                                                <ListGroup.Item className='d-flex justify-content-start'>
                                                    <div className='w-50'><h6>Token Standard</h6></div>
                                                    <div className='w-50 address_link'>ERC - {nftInfo.create_mint_type}</div>
                                                </ListGroup.Item>
                                                <ListGroup.Item className='d-flex justify-content-start'>
                                                    <div className='w-50'><h6>Blockchain</h6></div>
                                                    <div className='w-50 address_link'>{nftInfo.chain_name}</div>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </div>
                                    </Tab>

                                    {
                                        (() => {
                                            if (nftInfo.mint_type == 2 && nftInfo.create_mint_type == 721) {
                                                return (
                                                    <Tab eventKey="price_details" title="Price Details">
                                                        <div className="price_table">
                                                            <Table bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                        <th>S No</th>
                                                                        <th>Wallet Address</th>
                                                                        <th>Bid Amount</th>
                                                                        {
                                                                            (() => {
                                                                                if (nftInfo.wallet_address === walletAddress) {

                                                                                    return (
                                                                                        <th>Status</th>
                                                                                    )
                                                                                }
                                                                            })()
                                                                        }
                                                                        {/* <th>Status</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        bidPriceList.length == 0 ?
                                                                            <tr >
                                                                                <td colspan={4}><h5 className='text-center py-5' style={{ fontSize: "28px", color: "#9d91ff" }}>No Bid's price Available</h5></td>
                                                                            </tr>
                                                                            : currentItemsBid && currentItemsBid.map((e, index) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{index + itemOffsetBid + 1}</td>
                                                                                        <td>
                                                                                            <Link to={`/User_profile?id=${e.user_id}`}>{e.bidwalletAddress.slice(0, 4)}.....{e.bidwalletAddress.slice(-4, e.bidwalletAddress.length)}</Link>
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                (() => {
                                                                                                    if(nftInfo.create_mint_type == 721 && nftInfo.mint_type == 2){
                                                                                                        return(
                                                                                                            <img src={bharatToken} alt="img" className='img-fluid me-1' style={{width:"20px"}}/>
                                                                                                        )
                                                                                                    }else{
                                                                                                        if (nftInfo.chain === "BNB") {
                                            
                                                                                                            return (
                                                                                                                <img src={bnb} alt="img" className='img-fluid me-1' />
                                                                                                            )
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <img src={ethereum} alt="img" className='img-fluid me-1' />
                                                                                                            )
                                                                                                        }
                                                                                                    }
                                                                                                })()
                                                                                            }
                                                                                            {e.bidPrice}
                                                                                        </td>
                                                                                        {

                                                                                            (() => {
                                                                                                if (nftInfo.wallet_address === walletAddress) {
                                                                                                    if (e.status == 0) {
                                                                                                        return (<td><strong>Pending</strong></td>)
                                                                                                    } else if (e.status == 1) {
                                                                                                        return (<td><strong>Buy NFT</strong></td>)
                                                                                                    } else if (e.status == 2) {
                                                                                                        return (<td><strong>Rejected Bid</strong> </td>)
                                                                                                    } else if (e.status == 3) {
                                                                                                        if (current_date_timestamp > nftInfo.aucation_timestamp) {
                                                                                                            return (
                                                                                                                <td><button className='send_bid_buy_btn' onClick={() => sendNFT(e.user_id, e.id,e.bidwalletAddress,e.bidPrice)}>Send NFT</button></td>
                                                                                                            )
                                                                                                            return (<td>reterterNFT</td>)
                                                                                                        } else {
                                                                                                            return (<td>Waiting for user buy NFT</td>)
                                                                                                        }

                                                                                                    } else {
                                                                                                        if (current_date_timestamp > nftInfo.aucation_timestamp) {
                                                                                                            return (
                                                                                                                <td><button className='send_bid_buy_btn' onClick={() => sendNFT(e.user_id, e.id,e.bidwalletAddress,e.bidPrice)}>Send NFT</button></td>
                                                                                                            )
                                                                                                        } else {
                                                                                                            return (<td>Auction Running</td>)
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            })()
                                                                                        }

                                                                                    </tr>
                                                                                )
                                                                            })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                        <div className="listing_paginate">
                                                            <Row>
                                                                <Col lg={12}>
                                                                    {
                                                                        (() => {

                                                                            if (bidPriceList.length > 6) {
                                                                                return (
                                                                                    <div className="paginate mt-4">
                                                                                        <ReactPaginate
                                                                                            breakLabel="..."
                                                                                            nextLabel=" >>"
                                                                                            onPageChange={handlePageClickBid}
                                                                                            pageRangeDisplayed={3}
                                                                                            marginPagesDisplayed={2}
                                                                                            pageCount={pageCountBid}
                                                                                            previousLabel="<< "
                                                                                            containerClassName='pagination justify-content-end'
                                                                                            pageClassName='page-item'
                                                                                            pageLinkClassName='page-link'
                                                                                            previousClassName='page-item'
                                                                                            previousLinkClassName='page-link'
                                                                                            nextClassName='page-item'
                                                                                            nextLinkClassName='page-link'
                                                                                            breakClassName='page-item'
                                                                                            breakLinkClassName='page-link'
                                                                                            activeClassName='active'

                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()
                                                                    }

                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Tab>
                                                )
                                            } if (nftInfo.mint_type == 1 && nftInfo.create_mint_type == 1155) {
                                                return (
                                                    <Tab eventKey="price_details" title="Listings">
                                                        <div className="price_table" >
                                                            <Table bordered hover responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th>S No</th>
                                                                        <th>Unit Price</th>
                                                                        <th>Quantity</th>
                                                                        <th>From</th>


                                                                        {
                                                                            (() => {

                                                                                // if(parseInt(nftInfo.number_of_image) > (parseInt(nftInfo.used_img_supply) + parseInt(nftInfo.remaining_img_supply)))
                                                                                if (walletAddress) {
                                                                                    // if (parseInt(nftInfo.number_of_image) != parseInt(nftInfo.used_img_supply)) {
                                                                                    return (<th>Action</th>)
                                                                                    // }
                                                                                }
                                                                            })()
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        listingsBuyList.length == 0 ?
                                                                            <tr >
                                                                                <td colspan={5} className="text-center"><p className='notification_error_text'>No Listing User Available</p></td>

                                                                            </tr>
                                                                            : currentBuyList &&  currentBuyList.map((e, index) => {

                                                                                return (
                                                                                    <tr>
                                                                                        <td>{index + itemOffsetBuyList + 1 }</td>
                                                                                        <td>
                                                                                            {
                                                                                                (() => {
                                                                                                    if (nftInfo.chain === "BNB") {

                                                                                                        return (
                                                                                                            <img src={bnb} alt="img" className='img-fluid me-1' style={{ width: "20px", height: "20px" }} />
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <img src={ethereum} alt="img" className='img-fluid me-1' style={{ width: "20px", height: "20px" }} />
                                                                                                        )
                                                                                                    }
                                                                                                })()
                                                                                            } {e.price}
                                                                                        </td>
                                                                                        <td>{e.quantity}</td>
                                                                                        <td>

                                                                                            <Link to={`/User_profile?id=${e.user_id}`}>{e.listwalletAddress.slice(0, 4)}.....{e.listwalletAddress.slice(-4, e.listwalletAddress.length)}</Link>
                                                                                        </td>
                                                                                        <td>

                                                                                            {
                                                                                                (() => {

                                                                                                    // if(parseInt(nftInfo.number_of_image) > (parseInt(nftInfo.used_img_supply) + parseInt(nftInfo.remaining_img_supply)))
                                                                                                    if (walletAddress) {
                                                                                                        // if (parseInt(nftInfo.number_of_image) != parseInt(nftInfo.used_img_supply)) {
                                                                                                        if (walletAddress != e.listwalletAddress) {
                                                                                                            // return(<button className='send_bid_buy_btn' onClick={handleMultilpleShow}>Buy</button>)
                                                                                                            return (<button className='send_bid_buy_btn' onClick={() => handleMultilpleBuyShow(e.id, e.owner_id)}>Buy</button>)
                                                                                                        }
                                                                                                        // }
                                                                                                    }
                                                                                                })()
                                                                                            }
                                                                                        </td>

                                                                                    </tr>
                                                                                )
                                                                            })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                        <div className="listing_paginate">
                                                            <Row>
                                                                <Col lg={12}>
                                                                    {
                                                                        (() => {

                                                                            if (listingsBuyList.length > 6) {
                                                                                return (
                                                                                    <div className="paginate mt-4">
                                                                                        <ReactPaginate
                                                                                            breakLabel="..."
                                                                                            nextLabel=" >>"
                                                                                            onPageChange={handlePageClickBuyList}
                                                                                            pageRangeDisplayed={3}
                                                                                            marginPagesDisplayed={2}
                                                                                            pageCount={pageCountBuyList}
                                                                                            previousLabel="<< "
                                                                                            containerClassName='pagination justify-content-end'
                                                                                            pageClassName='page-item'
                                                                                            pageLinkClassName='page-link'
                                                                                            previousClassName='page-item'
                                                                                            previousLinkClassName='page-link'
                                                                                            nextClassName='page-item'
                                                                                            nextLinkClassName='page-link'
                                                                                            breakClassName='page-item'
                                                                                            breakLinkClassName='page-link'
                                                                                            activeClassName='active'

                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()
                                                                    }

                                                                </Col>
                                                            </Row>
                                                        </div>
                                                      
                                                    </Tab>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        })()
                                    }

                                </Tabs>

                            </Col>
                        </Row>

                        {/* all products collections start */}
                        <ProductCollection id={id} pageUpdate = {pageUpdate} setPageUpdate ={setPageUpdate} />
                        {/* all products collections end */}


                    </Container>

                    <ToastContainer
                        position="bottom-right"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />

                </section>
                <Footer />
            </div>


            {/*successfull modal start */}
            <Modal show={modalShow} onHide={handleClose} centered>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className='success_modal_div'>
                                    <div className='success_icon'><FaRegCheckCircle /></div>
                                    <div className='head'>NFT Buy Successfully !</div>
                                    <div className='success_btn'>
                                        <button className='btn btn-secondary' onClick={handleClose}>
                                            Cancel
                                        </button></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            {/*successfull modal end */}


            {/*=============resell nft modal start================== */}
            <Modal show={resellShow} onHide={handleResellClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Resell NFT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-section resell_modal'>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" value={newName} onChange={(e) => setNewName(e.target.value)} />
                            <p className='required-text' id="nameheck">Please Enter New Name </p>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Description"
                                style={{ height: '100px' }} value={newDescription} onChange={(e) => setNewDescription(e.target.value)} />
                            <p className='required-text' id="desccheck">Please Enter New Description </p>
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Control type="text" placeholder="Photography" readOnly={true} style={{ textTransform: "capitalize" }} value={nftInfo.category} />
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>New Price</Form.Label>
                            <Form.Control type="number" min="0" placeholder="Price" onChange={(e) => setNewPrice(e.target.value)} />
                            <p className='required-text' id="pricecheck">Please Enter Price </p>
                            <p className='required-text' id="newPriceValidation">Price should be greater than zero</p>
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 d-none">
                            <Form.Label>Token ID</Form.Label>
                            <Form.Control type="text" placeholder="Token Id" readOnly={true} value={nftInfo.token_id} />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='resell_btn' onClick={resellNFT}>
                        Resell
                    </button>
                </Modal.Footer>
            </Modal>
            {/* ===============resell nft modal end=================== */}

            {/*=============Bid nft modal start================== */}
            <Modal show={bidShow} onHide={handleBidClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Place Bid</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-section resell_modal'>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" value={newName} readOnly />
                            <p className='required-text' id="nameheck">Please Enter New Name </p>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Description"
                                style={{ height: '100px' }} value={newDescription} readOnly />
                            <p className='required-text' id="desccheck">Please Enter New Description </p>
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Control type="text" placeholder="Category" value={nftInfo.category} readOnly={true} style={{ textTransform: "capitalize" }} />
                        </Form.Group>
                        
                        <Form.Group className="mt-4 mb-3">
                            <Form.Label>Bharat Token</Form.Label>
                            <Form.Control type="text" placeholder="Starting Bid Price" readOnly={true} value={bharatTokenBalance} />
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3">
                            <Form.Label>Initial Bid Price</Form.Label>
                            <Form.Control type="text" placeholder="Starting Bid Price" readOnly={true} value={nftInfo.nft_price} />
                        </Form.Group>


                        {
                            (() => {
                                if (nftInfo.highest_bid != 0) {
                                    return (
                                        <Form.Group className="mt-4 mb-3">
                                            <Form.Label>Current Bid Price</Form.Label>
                                            <Form.Control type="text" placeholder="Highest Bid Price" readOnly={true} value={nftInfo.highest_bid} />
                                        </Form.Group>
                                    )
                                }
                            })()
                        }

                        <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>Place Bid Price</Form.Label>
                            <Form.Control type="text" min="0" placeholder="Price" onChange={(e) => setNewBidPrice(e.target.value)} onKeyUp={checkBidValidation} />
                            <p className='required-text' id="bidPricecheck">Please Enter Bid Price </p>
                            <p className='required-text' id="bidPriceValid">Please Enter Valid Bid Price </p>
                            <p className='required-text' id="starBidPriceCheck">You can not bid below the starting bid price </p>
                            <p className='required-text' id="startHighestPriceCheck">You can not bid below the highest bid price </p>
                            <p className='required-text' id="checkTokenBalance">You don't have insufficient token balance to bid</p>
                            {/* <p className='required-text' id="checkAvailableTokenBalance">You don't have insufficient token balance </p> */}

                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 d-none">
                            <Form.Label>Token ID</Form.Label>
                            <Form.Control type="text" placeholder="Token Id" readOnly={true} value={nftInfo.token_id} />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="countdown_bid ">
                        <button className='bid_btn' onClick={bidNFT}>
                            Bid
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* ===============Bid nft modal end=================== */}

            {/*=============resell bid nft modal start================== */}
            <Modal show={resellBidShow} onHide={handleResellBidClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Resell NFT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-section resell_modal'>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" value={newName} onChange={(e) => setNewName(e.target.value)} />
                            <p className='required-text' id="newBidNameheck">Please Enter New Name </p>
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Control type="text" placeholder="Photography" readOnly={true} style={{ textTransform: "capitalize" }} value={nftInfo.category} />
                        </Form.Group>

                        <Form.Group className="mb-3 price_field">
                            <Form.Label>Choose New Till Date and Time</Form.Label>
                            <DatePicker
                                selected={currentDate ? currentDate : ""}
                                onChange={handleChange}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={20}
                                timeCaption="time"
                                dateFormat="dd MMMM, yyyy h:mm aa"
                                locale="en"
                                minDate={new Date()}
                                placeholderText={'01 Jan 1970 10:00 AM'}
                            />
                            {/* <p className='required-text' id="desccheck">Please Choose Till Date and Time </p> */}
                            <p className='required-text' id="auctionDateAndTime">Please Choose Duration </p>
                            <p className='required-text' id="timeAuctionValidation">You can not choose time less than the current time </p>
                        </Form.Group>


                        <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>New Price</Form.Label>
                            <Form.Control type="number" min="0" placeholder="Price" onChange={(e) => setNewPrice(e.target.value)} />
                            <p className='required-text' id="newPricecheck">Please Enter Price </p>
                            <p className='required-text' id="newBidPriceValidation">Price should be greater than zero</p>
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 d-none">
                            <Form.Label>Token ID</Form.Label>
                            <Form.Control type="text" placeholder="Token Id" readOnly={true} value={nftInfo.token_id} />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    {
                        (() => {
                            if (usersList.length == 0) {
                                return (
                                    <button className='resell_btn' onClick={resellNoBidNFT}>
                                        Resell
                                    </button>
                                )
                            } else {
                                return (
                                    <button className='resell_btn' onClick={resellBidNFT}>
                                        Resell
                                    </button>
                                )
                            }
                        })()
                    }

                    {/* <button className='resell_btn' onClick={resellBidNFT}>
                        Resell 
                    </button> */}
                </Modal.Footer>
            </Modal>
            {/* ===============resell bid nft modal end=================== */}

            {/*=============multiple nft modal start================== */}
            <Modal show={multilpleShow} onHide={handleMultilpleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Buy NFT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-section resell_modal'>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" value={newName} onChange={(e) => setNewName(e.target.value)} readOnly={true} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Description"
                                style={{ height: '100px' }} value={newDescription} onChange={(e) => setNewDescription(e.target.value)} readOnly={true} />
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Control type="text" placeholder="Category" readOnly={true} style={{ textTransform: "capitalize" }} value={nftInfo.category} />
                        </Form.Group>

                        {/* <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>Total Supply</Form.Label>
                            <Form.Control type="number" min="0" placeholder="Price" value={nftInfo.remaining_img_supply} readOnly={true} />
                        </Form.Group> */}

                        <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>Remaining Supply</Form.Label>
                            <Form.Control type="number" min="0" placeholder="Price" value={nftInfo.remaining_img_supply} readOnly={true} />
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>Per Price</Form.Label>
                            <Form.Control type="number" min="0" placeholder="Price" value={nftInfo.price} readOnly={true} />
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>Number of NFT to buy</Form.Label>
                            <Form.Control type="text" min="0" placeholder="Number of NFT to buy" onChange={(e) => setNoBuyImage(e.target.value)} onKeyUp={checkNumberofBuy}/>
                            <p className='required-text' id="noBuyImage">Please Enter Number of NFT to buy</p>
                            <p className='required-text' id="noBuyImageValid">Please Enter Valid Number of NFT to buy </p>
                            <p className='required-text' id="noBuyImageCheck">You can not buy above the supply </p>
                            <p className='required-text' id="noBuyImageCheckValidation">Number should be greater than zero</p>
                            <p className='required-text' id="noBuyImgInt">Only number allow</p>
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 new_price_field" style={{ display: 'none' }} id="totalPriceDiv">
                            <Form.Label>Total Price</Form.Label>
                            <Form.Control type="text" placeholder="Total Price" readOnly={true} value={totalPrice} />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <button className='resell_btn' onClick={multipleBuyNft}> */}
                    {/* <button className='resell_btn' onClick={() => multipleBuyNft(nftInfo.id)}> */}
                    <button className='resell_btn' onClick={() => multipleBuyNft(nftInfo.buy_resell_id)}>
                        Buy
                    </button>

                </Modal.Footer>
            </Modal>
            {/* ===============multiple nft modal end=================== */}

            {/*=============multiple resell nft modal start================== */}
            <Modal show={multipleResellShow} onHide={handleMultipeResellClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Resell NFT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-section resell_modal'>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" value={newName} onChange={(e) => setNewName(e.target.value)} readOnly={true} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Description"
                                style={{ height: '100px' }} value={newDescription} onChange={(e) => setNewDescription(e.target.value)} readOnly={true} />
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Control type="text" placeholder="Photography" readOnly={true} style={{ textTransform: "capitalize" }} value={nftInfo.category} />
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>You Own</Form.Label>
                            <Form.Control type="number" min="0" placeholder="Price" value={multipleNFTDetail.resell_remainingLoginSupply} readOnly={true} />
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>Number of NFT to sell</Form.Label>
                            <Form.Control type="text" min="0" placeholder="Number of NFT to sell" onChange={(e) => setNoResellBuyImage(e.target.value)} onKeyUp={checkMulipleNoResellValidation}/>
                            <p className='required-text' id="noResellBuyImage">Please Enter Number of NFT to sell</p>
                            <p className='required-text' id="noResellBuyImageValid">Please Enter Valid Number of NFT to sell</p>
                            <p className='required-text' id="noResellBuyImageCheck">You can not sell above the supply</p>
                            <p className='required-text' id="noResellBuyImageCheckValidation">Number of NFT should be greater than zero</p>
                            <p className='required-text' id="noResellBuyInt">Only number allows</p>
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="text" min="0" placeholder="Price" onChange={(e) => setNoResellPrice(e.target.value)} onKeyUp={checkMulipleNoPriceResellValidation}/>
                            <p className='required-text' id="newResellPricecheck">Please Enter Price </p>
                            <p className='required-text' id="noResellPriceBuyImageValid">Please Enter Valid Price</p>
                            <p className='required-text' id="newResellPricecheckValidation">Price should be greater than zero</p>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <button className='resell_btn' onClick={multipleResellNft}> */}
                    <button className='resell_btn' onClick={() => multipleResellNft(checkingListingList.id)}>
                        Resell
                    </button>
                </Modal.Footer>
            </Modal>
            {/* ===============multiple resell nft modal end=================== */}

            {/*=============multiple Buy nft modal start================== */}
            <Modal show={multilpleBuyShow} onHide={handleMultilpleBuyClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Buy NFT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-section resell_modal'>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" value={listingBuyData.name} onChange={(e) => setNewName(e.target.value)} readOnly={true} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Description"
                                style={{ height: '100px' }} value={listingBuyData.description} onChange={(e) => setNewDescription(e.target.value)} readOnly={true} />
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Control type="text" placeholder="Photography" readOnly={true} style={{ textTransform: "capitalize" }} value={listingBuyData.category} />
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>Total Supply</Form.Label>
                            <Form.Control type="number" min="0" placeholder="Price" value={listingBuyData.quantity} readOnly={true} />
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>Per Price</Form.Label>
                            <Form.Control type="number" min="0" placeholder="Price" value={listingBuyData.price} readOnly={true} />
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 new_price_field">
                            <Form.Label>Number of NFT to buy</Form.Label>
                            <Form.Control type="text" min="0" placeholder="Number of NFT to buy" onChange={(e) => setMultipleNoBuyImage(e.target.value)} onKeyUp={checkMulipleNoBuyValidation}/>
                            <p className='required-text' id="noMultipleBuyImage">Please Enter Number of NFT to buy</p>
                            <p className='required-text' id="noMultipleBuyImageCheck">You can not buy above the supply </p>
                            <p className='required-text' id="noMultipleBuyImageValid">Please Enter Valid Number of NFT to buy </p>
                            <p className='required-text' id="noMultipleBuyImageInt">Only number allow</p>
                            <p className='required-text' id="noMultipleBuyImageCheckValidation">Number of NFT should be greater than zero</p>
                        </Form.Group>

                        <Form.Group className="mt-4 mb-3 new_price_field" style={{ display: 'none' }} id="totalMultiplePriceDiv">
                            <Form.Label>Total Price</Form.Label>
                            <Form.Control type="text" placeholder="Total Price" readOnly={true} value={MultipleTotalPrice} />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <button className='resell_btn' onClick={multipleListBuyNft}> */}
                    <button className='resell_btn' onClick={() => multipleListBuyNft(buyResellId)}>
                        {/* <button className='resell_btn' onClick={() => multipleListBuyNft(listingBuyData.id)}> */}
                        Buy
                    </button>

                </Modal.Footer>
            </Modal>
            {/* ===============multiple nft modal end=================== */}

            {/* // ===========Total owner modal start========== */}
            <Modal show={showOwner} onHide={handleCloseOwner} className="follower_profile_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Owned By</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="followers_modal">
                        {
                            multipleNFTUserListDetail ?

                                multipleNFTUserListDetail.map((e) => {
                                    var userProfile = `/User_profile?id=${e.id}`
                                    return (
                                        <Link to={userProfile}>
                                            <div className="followers_list">
                                                <div className="follower_users">
                                                    <div className="follower_img">
                                                        <img src={e.profileImage} alt="follower_img" className='img-fluid' />
                                                    </div>
                                                    <div className="follower_details">
                                                        <div className="follower_profile">
                                                            <div className="follower_name">{e.name ? e.name : "Unname"}</div>
                                                            <div className="follower_address">{e.address.slice(0, 4)}....{e.address.slice(-4, e.address.length)}</div>
                                                        </div>
                                                        <div className="follower_items">{e.totalItem} items</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                                :
                                " "
                        }
                    </div>

                </Modal.Body>

            </Modal>
            {/* // ===========Total owner modal end========== */}
        </>
    )
}

export default Prod_detail
