import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  FaArrowLeft,
  FaImages,
  FaGlobe,
  FaGithubAlt,
  FaInstagram,
  FaTwitter,
  FaTelegramPlane,
  FaFacebook,
  FaYoutube
} from "react-icons/fa";
import { ImSpinner7 } from 'react-icons/im'
import { MdDeleteForever } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import axios from "axios";
import crossSign from '../../assets/img/cross_sign.jpg'
import rightSign from '../../assets/img/right_sign.jpg'


var BASE_URL = process.env.REACT_APP_BASE_URL;
var COLLECTION_URL = process.env.REACT_APP_COLLECTION_URL;

console.log("COLLECTION_URL",COLLECTION_URL);
console.log("COLLECTION_URL",process.env.REACT_APP_COLLECTION_URL);
console.log("COLLECTION_URL",process.env.REACT_APP_BASE_URL);
console.log("BASE_URL",BASE_URL);
function CreateCollections() {
  // ========add/remove address fields function start=======
  const [addField, setAddField] = useState([]);
  const [formValues, setFormValues] = useState([{ wallet_address: "", percentage : ""}])
  const [collectionName, setCollectionName] = useState("");
  const [collectionUrl, setCollectionUrl] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCollectionCategory] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [discordLink, setDiscordLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  // const [telegramLink, setTelegramLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [logoImage, setLogoImage] = useState([]);
  const [bannerImaage, setBannerImage] = useState([]);
  const [logoPreview, setLogoImagePreview] = useState("");
  const [bannerImagePreview, setBannerImagePreview] = useState("");
  var loginUserAdd = sessionStorage.getItem("loginUserAdd");
  let navigate = useNavigate();
 
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
     setFormValues(newFormValues);
     
  }
  

  let addAddressFields = () => {
    setFormValues([...formValues, { wallet_address: "", percentage: "" }])
  }
console.log("formValuesssssssssssssssssss",formValues)
console.log("collectionUrlAAAAAAAAAAAAAA",collectionUrl);
  const removeAddressFields = (i) => {
    const removeField = [...formValues];
    removeField.splice(i, 1);
    setFormValues(removeField);
  };

  useEffect(() => {
     $("#logoImage").hide();
     $("#collectionName").hide();
     $("#categoryCollection").hide();
     $("#nameALreadyValidation").hide();
     $("#nameAvaiable").hide();
     $("#urlALreadyValidation").hide();
     $("#urlAvaiable").hide();
     $("#urlName_Valid").hide();
     $("#urlPattern_Valid").hide();
  });

  $(".validate").focus(function() {
    $("#logoImage").hide();
    $("#collectionName").hide();
    $("#categoryCollection").hide();
    $("#nameALreadyValidation").hide();
    $("#nameAvaiable").hide();
    $("#urlALreadyValidation").hide();
    $("#urlAvaiable").hide();
    $("#urlName_Valid").hide();
    $("#urlPattern_Valid").hide();
  });
  const createcollection = async () => {
// alert(collectionUrl)
    console.log("logoImage", logoImage);
    console.log("collectionUrlcollectionUrl", collectionUrl);
    if (!logoImage.name) {
      $("#logoImage").show();
      $('#uploadLogoPreviewImageType').hide();
    }

    if (!collectionName) {
      $("#collectionName").show();
    } else if (/^\s+/g.test(collectionName)) {
      $("#collectionName").show();
      return;
    }
    if (!category) {
      $("#categoryCollection").show();
    }
   
    if(collectionUrl){
      console.log("collectionUrlcollectionUrlcollectionUrl",collectionUrl)
      const urlPattern = /^[0-9a-z\-]+$/;
        if (!urlPattern.test(collectionUrl)) {
          console.log("collectionUrlcollectionUrlcollectionUrl yes")
            // $("#urlName_Valid").show();
            $("#nameALreadyValidation").hide();
            $("#nameAvaiable").hide();
            $("#urlPattern_Valid").show();
            return;
        }else{
          console.log("collectionUrlcollectionUrlcollectionUrl no")
          $("#urlPattern_Valid").hide();
        }
    }

    if (logoImage && collectionName && category) {

      document.getElementById("overlay").style.display = "block";

      const formData = new FormData();
      formData.append("user_id", loginUserAdd);
      formData.append("logo", logoImage, logoImage.name);
      formData.append("bannerImage", bannerImaage);
      formData.append("name", collectionName);
      formData.append("description", description);
      formData.append("category", category);
      formData.append("website_link", websiteLink);
      formData.append("url", collectionUrl);
      formData.append("discord", discordLink);
      formData.append("facebook", facebookLink);
      formData.append("twitter", twitterLink);
      formData.append("instagram", instagramLink);
      // formData.append("telegram", telegramLink);
      formData.append("youtube", youtubeLink);
      if(formValues){
        formData.append("creator_earnings", JSON.stringify(formValues));
      }

      var creat_mint = await axios.post(`${BASE_URL}/createCollection`,
        formData
      );
      
      console.log("creat_mint",creat_mint)
      if (creat_mint.status == 200) {
        toast.success("Collection created successfully!", {
          position: "top-center",
          theme: "colored",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        document.getElementById("overlay").style.display = "none";

        setTimeout(() => {
          navigate(`/collections`);
        }, 2000);
      } else {

        document.getElementById("overlay").style.display = "none";

        toast.error("Create Collection failed !", {
          position: "top-center",
          theme: "colored",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  async function checkNameValidation(){
    let newNameData = {"name":collectionName}
    var checkNameData = await axios.post(`${BASE_URL}/checkName`, newNameData)
    console.log("getCollectionDatagetCollectionData",checkNameData)
    $("#collectionName").hide();
    if (checkNameData.data.status === 200) {
      $("#nameALreadyValidation").show();
      $("#nameAvaiable").hide();
    }else{
      $("#nameAvaiable").show();
      $("#nameALreadyValidation").hide();
    }
  }

  async function checkUrlValidation(){
    // alert(1);
    console.log("fgdfgfddf",collectionUrl)
    // const pattern = /^\S*$/;
    const urlPattern = /^[a-z0-9-]+$/;
        if (!urlPattern.test(collectionUrl)) {
            // $("#urlName_Valid").show();
            $("#nameALreadyValidation").hide();
            $("#nameAvaiable").hide();
            $("#urlName_Valid").show();
            $("#urlPattern_Valid").show();
            return;
        }  
        else {
            // $("#urlName_Valid").hide();
            $("#urlPattern_Valid").hide();

            let newUrlData = {"name":collectionUrl}
            // formData.append("name", collectionName);
            var checkNameData = await axios.post(`${BASE_URL}/checkURL`, newUrlData)
            console.log("getCollectionDatagetCollectionData",checkNameData)
            $("#collectionName").hide();
            if (checkNameData.data.status === 200) {
              $("#urlALreadyValidation").show();
              $("#urlAvaiable").hide();
            }else{
              $("#urlAvaiable").show();
              $("#urlALreadyValidation").hide();
            }

        }  
  }

  const closeBannerImg = () => {
    setBannerImagePreview("")
    setBannerImage("")
  }
  const closeLogoImg = () => {
     setLogoImagePreview("")
     setLogoImage("")
   }
  // ========add/remove address fields function end=======
  const logoImageValidate = (e) => {
    setLogoImage(e.target.files[0]);
    setLogoImagePreview(URL.createObjectURL(e.target.files[0]));
   console.log("AAAAAAAAAAAAbefore if",e.target.files[0]);
    if (e.target.files[0].name) {
      if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|gif|JPEG|PNG|JPG|GIF|SVG|svg)$/)) {
         $("#uploadLogoPreviewImageType").show();
        console.log("not preview match1")
        console.log("Us1", e.target.files[0].name)
        e.target.value = null;
        setLogoImage("")
        setLogoImagePreview("")
       $("#logoImage").hide();
        }else{
        $("#uploadLogoPreviewImageType").hide();
      }
    }
  };

  const BannerImageValidate = (e) => {
    setBannerImage(e.target.files[0]);
    setBannerImagePreview(URL.createObjectURL(e.target.files[0]));
   if (e.target.files[0].name) {
      if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|gif|JPEG|PNG|JPG|GIF|SVG|svg)$/)) 
      {
        $("#uploadBannerPreviewImageType").show();
        e.target.value = null;
        setBannerImage("")
        setBannerImagePreview("")
        }else{
          $("#uploadBannerPreviewImageType").hide();
        }
    }
  };
  return (
    <div>
      <div id="overlay">
        <div className='loader_spiner'>
            <ImSpinner7 />
            <div className="loader_text">
                <strong>Please wait while complete your processing...</strong>
            </div>
        </div>
      </div>
      <Header />
      <section className="create_collection_div">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10} className="create_item_div">
              <Row>
                <Col lg={12} className="Heading-Txt">
                  <Link to="/collections" className="GoBack_btn fw-bold">
                    {/* <FaArrowLeft className="back-icon" /> Go Back */}
                    <button className='GoBack_btn fw-bold' onClick={() => navigate(-1)} style={{ backgroundColor: "transparent" }}><FaArrowLeft className='back-icon' />  Go Back</button>
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={6} md={6}>
                  <h2 className="fw-bold mb-3 mt-3">Create a Collection</h2>
                  <Form.Label>
                    Logo image <span className="required-text">*</span>
                  </Form.Label>
                  <div
                    className="mt-1"
                    style={{ wordBreak: "break-all", fontSize: "14px" }}
                  >
                    File type supported:JPG, JPEG, PNG, SVG, GIF. Max size:
                    100MB
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col lg={2} md={4}>
                  {logoPreview ? (
                    <div
                      className="card p-4 mt-3 text-center"
                      style={{
                        border: "2px dashed gray",
                        borderRadius: "50%",
                        height: "160px",
                        width: "160px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="card-body customCardBody mb-1"
                        style={{
                          height: "100px",
                          width: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          src={logoPreview}
                          alt="profile_pic"
                          className="logo_img"
                        />
                         <FaImages className="fs-1 mb-1" style={{opacity:"0"}} />
                        <Form.Control
                          type="file"
                          className="uploadPostImage validate"
                          onChange={logoImageValidate}
                          style={{opacity:"0"}}
                        />
                        <div className="img_close_collection">
                          <button className='img_close_btn_collection' onClick={closeLogoImg}>X</button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="card p-4 mt-3 text-center"
                      style={{
                        border: "2px dashed gray",
                        borderRadius: "50%",
                        height: "160px",
                        width: "160px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="card-body customCardBody mb-1"
                        style={{
                          height: "100px",
                          width: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                        }}
                      >
                        <FaImages className="fs-1 mb-1" />
                        <Form.Control
                          type="file"
                          className="uploadPostImage validate"
                          onChange={logoImageValidate}
                        />
                      </div>
                    </div>
                  )}
                </Col>
                <p style={{textAlign:"center",color:"red"}} id="logoImage">Please Upload Logo</p>
                <p  id="uploadLogoPreviewImageType" style={{textAlign:"center",color:"red",display:"none"}}>Invalid File Type </p>
              </Row>
              <Row className="justify-content-center">
                <Col lg={6} md={6}>
                  <Form.Label>Banner image</Form.Label>
                  <div
                    className="mt-1"
                    style={{ wordBreak: "break-all", fontSize: "14px" }}
                  >
                    File type supported:JPG, JPEG, PNG, SVG, GIF. Max size:
                    100MB
                  </div>
                  {bannerImagePreview ? (
                    <div
                      className="card p-4 mt-3 text-center"
                      style={{ border: "2px dashed gray" }}
                    >
                      <div
                        className="card-body banner customCardBody mb-1"
                        style={{
                          height: "200px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={bannerImagePreview}
                          alt="profile_pic"
                          className="banner_img"
                        />
                        <FaImages className="fs-1 mb-1" style={{opacity:"0"}} />
                        <Form.Control
                          type="file"
                          className="uploadPostImage validate"
                          onChange={BannerImageValidate}
                          style={{opacity:"0"}}
                        />
                         <div className="img_close">
                        <button className='img_close_btn' onClick={closeBannerImg}>X</button>
                    </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="card p-4 mt-3 text-center"
                      style={{ border: "2px dashed gray" }}
                    >
                      <div
                        className="card-body customCardBody mb-1"
                        style={{
                          height: "200px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FaImages className="fs-1 mb-1" />
                        <Form.Control
                          type="file"
                          className="uploadPostImage validate"
                          onChange={BannerImageValidate}
                        />
                      </div>
                    </div>
                  )}
                </Col>
                <p style={{textAlign:"center",color:"red",display:"none"}} id="uploadBannerPreviewImageType">Invalid File Type </p>
              </Row>
              <Row className="justify-content-center">
                <Col lg={6}>
                  <div className="form-section">
                    <Form.Group
                      className="mt-4 mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Name <span className="required-text">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Collection Name"
                        className="validate"
                        onChange={(e) => setCollectionName(e.target.value)}
                        onKeyUp={checkNameValidation}
                      />
                      <p className="required-text" id="collectionName"> Name field is required.</p>
                      <p  id="nameAvaiable"><img src={rightSign} with="25" height="25"/> This name is available.</p>
                      <p className="required-text" id="nameALreadyValidation"> The name is already taken.</p>
                    </Form.Group>

                    <Form.Group
                      className="mt-4 mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>URL </Form.Label>
                      <div className="url_text">
                        Customize your URL on Bharat NFT. Must only contain
                        lowercase letters, numbers, and hyphens.
                      </div>
                      <div className="url_collection_div">
                        <div className="url_name">{COLLECTION_URL}</div>
                      <Form.Control
                        type="text"
                        className="mt-2"
                        placeholder="collection-name-url"
                        onChange={(e) => setCollectionUrl(e.target.value)}
                        onKeyUp={checkUrlValidation}
                   
                      />
                      </div>
                     <p  id="urlAvaiable"><img src={rightSign} with="25" height="25"/> The URL name is available.</p>
                      <p className="required-text" id="urlALreadyValidation"> The URL name is already taken.</p>
                      <p className='required-text' id="urlName_Valid"><img src={crossSign} with="25" height="25"/> Wrong format.</p>
                      <p className='required-text' id="urlPattern_Valid">Entered value must only contain lowercase letters, numbers, and hyphens</p>
                    </Form.Group>

                    <Form.Group
                      className="mt-4 mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Description </Form.Label>
                      <Form.Control
                        rows={4}
                        className=""
                        placeholder="Description"
                        as="textarea"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Category <span className="required-text">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        className="validate"
                        onChange={(e) => setCollectionCategory(e.target.value)}
                      >
                        <option value="" selected>
                          Select Category
                        </option>
                        <option value="artwork">Artwork</option>
                        <option value="photography">Photography</option>
                        <option value="audio">Audio</option>
                        <option value="video">Video</option>
                        <option value="collectibles">Collectibles</option>
                      </Form.Select>
                      <p className="required-text" id="categoryCollection">
                        Please select category.
                      </p>
                    </Form.Group>
                    <div className="link text-start mt-4">
                      <Form.Label>Links</Form.Label>

                      <ul className="list-unstyled mt-3">
                        <li className="d-flex editing_icon">
                          {" "}
                          <FaGlobe />
                          <InputGroup>
                            <FormControl
                              placeholder="Website Link"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={(e) => setWebsiteLink(e.target.value)}
                            />
                          </InputGroup>
                        </li>
                        <li className="d-flex editing_icon">
                          {" "}
                          <FaTwitter />
                          <InputGroup>
                            <FormControl
                              placeholder="Twitter Link"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={(e) => setTwitterLink(e.target.value)}
                            />
                          </InputGroup>
                        </li>
                        <li className="d-flex editing_icon">
                          {" "}
                          <FaInstagram />
                          <InputGroup>
                            <FormControl
                              placeholder="Instagram Link"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={(e) => setInstagramLink(e.target.value)}
                            />
                          </InputGroup>
                        </li>
                        <li className="d-flex editing_icon">
                          {" "}
                          <FaGithubAlt />
                          <InputGroup>
                            <FormControl
                              placeholder="Discord Link"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={(e) => setDiscordLink(e.target.value)}
                            />
                          </InputGroup>
                        </li>

                        <li className="d-flex editing_icon">
                          {" "}
                          <FaFacebook />
                          <InputGroup>
                            <FormControl
                              placeholder="Facebook Link"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={(e) => setFacebookLink(e.target.value)}
                            />
                          </InputGroup>
                        </li>

                        <li className="d-flex editing_icon">
                          {" "}
                          <FaYoutube />
                          <InputGroup>
                            <FormControl
                              placeholder="Youtube Link"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={(e) => setYoutubeLink(e.target.value)}
                            />
                          </InputGroup>
                        </li>
                      </ul>
                    </div>
                    {/* <Form.Group
                      className="mt-4 mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Creator earnings</Form.Label>
                      {formValues.map((e, i) => {
                        return (
                          <div className="creator_input_div">
                            <Form.Control
                              type="text"
                              placeholder="Please Enter an address"
                              name="wallet_address"
                              onChange={e => handleChange(i, e)}
                            />
                            <div className="percentage_div">
                              <Form.Control type="text" name="percentage" onChange={e => handleChange(i, e)}/>
                              <span>%</span>
                            </div>
                            <div
                              className="creator_delete_btn"
                              onClick={() => removeAddressFields(i)}
                            >
                              <MdDeleteForever />
                            </div>
                          </div>
                        );
                      })}

                      <div className="add_address_btn" onClick={addAddressFields} >Add address</div>
                    </Form.Group> */}
                    <div className="creator_create_btn">
                      <button
                        className="create_btn_add"
                        onClick={createcollection}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
      </section>
      <Footer />
    </div>
  );
}

export default CreateCollections;
