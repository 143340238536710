import React, { useEffect, useState, useRef } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Container, Row, Col, Tabs, Tab, Card, InputGroup, FormControl, Modal } from 'react-bootstrap'
import ProfileImg from '../assets/img/Profile.png'
import { FaRegCopy } from 'react-icons/fa'
import { FaGlobe } from 'react-icons/fa'
import { FaGithubAlt } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaTiktok } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa'
import ProductCard from './ProductCard'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'
import { useNavigate, Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import $ from 'jquery'
import ethereum from '../assets/img/ether.svg'
import bnb from '../assets/img/bnb.png'
import bharatToken from '../assets/img/bharatToken(1).png'
import { BsFillPlayFill } from 'react-icons/bs';
import { AiOutlinePause } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import Timer1 from './Timer1';
import Moment from 'moment';
import { ImSpinner7 } from 'react-icons/im'
const BASE_URL = process.env.REACT_APP_BASE_URL

const loginUserData = JSON.parse(sessionStorage.getItem('user'))

function UserProfile() {

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [userData, setuserData] = useState("")
    const [creatorAddress, setcreatorAddress] = useState("")
    const [topCreaterId, setTopCreaterId] = useState("")
    // const searchParams = useSearchParams();
    const [onSale, setOnSaleData] = useState([])
    const [activity, setActivity] = useState([])
    const [owned, setOwned] = useState([])
    const [load, setLoading] = useState(true)
  console.log("activityyyyyyyyyyyyyyyyyyy",activity);
    const copyText = () => {
        $("#copyAddress").show()
        setTimeout(() => {
            $("#copyAddress").hide()
        }, 1000)
    }

    async function getUserProfile() {
        const id = searchParams.get("id")
        const login_id = loginUserData ? loginUserData.id : ""
        setTopCreaterId(id)
        console.log("iddddd", id);
        var creators = await axios.get(`${BASE_URL}/getTopCreatorDetail?user_id=${id}&login_id=${login_id}`)

        if (creators.data.result) {
            setuserData(creators.data.result)
            setOnSaleData(creators.data.result.on_sale)
            setActivity(creators.data.result.activity)
            setOwned(creators.data.result.owned)
            setcreatorAddress(creators.data.result.address)
            setLoading(false)
        }

    }
    useEffect(() => {
        getUserProfile()
        setTimeout(()=>{
            setLoading(false) 
        },1000)
    }, [])

    // =============Activity pagination start===============
    const [currentItemsActivity, setCurrentItemsActivity] = useState([]);
    const [pageCountActivity, setPageCountActivity] = useState(0);

    const [itemOffsetActivity, setItemOffsetActivity] = useState(0);
    const itemsPerPageActivity = 6
    useEffect(() => {


        const endOffsetActivity = itemOffsetActivity + itemsPerPageActivity;

        setCurrentItemsActivity(activity.slice(itemOffsetActivity, endOffsetActivity));
        setPageCountActivity(Math.ceil(activity.length / itemsPerPageActivity));
    }, [itemOffsetActivity, itemsPerPageActivity, activity]);

    const handlePageClickActivity = (event) => {
        const newOffsetActivity = (event.selected * itemsPerPageActivity) % activity.length;
        setItemOffsetActivity(newOffsetActivity);
    };
    // =============Activity pagination end===============

    // =============On Sell pagination start===============
    const [currentItemsOnSale, setCurrentItemsOnSale] = useState([]);
    const [pageCountOnSale, setPageCountOnSale] = useState(0);

    const [itemOffsetOnSale, setItemOffsetOnSale] = useState(0);
    const itemsPerPageOnSale = 6
    useEffect(() => {

        const endOffsetOnSale = itemOffsetOnSale + itemsPerPageOnSale;

        setCurrentItemsOnSale(onSale.slice(itemOffsetOnSale, endOffsetOnSale));
        setPageCountOnSale(Math.ceil(onSale.length / itemsPerPageOnSale));
    }, [itemOffsetOnSale, itemsPerPageOnSale, onSale]);

    const handlePageClickOnSale = (event) => {
        const newOffsetOnSale = (event.selected * itemsPerPageOnSale) % onSale.length;
        setItemOffsetOnSale(newOffsetOnSale);
    };
    // =============On Sell pagination end===============

    // =============Owned pagination start===============
    const [currentItemsOwned, setCurrentItemsOwned] = useState([]);
    const [pageCountOwned, setPageCountOwned] = useState(0);

    const [itemOffsetOwned, setItemOffsetOwned] = useState(0);
    let checkAddress = sessionStorage.getItem('loginUserAdd')
    const itemsPerPageOwned = 6
    useEffect(() => {

        const endOffsetOwned = itemOffsetOwned + itemsPerPageOwned;

        setCurrentItemsOwned(owned.slice(itemOffsetOwned, endOffsetOwned));
        setPageCountOwned(Math.ceil(owned.length / itemsPerPageOwned));
    }, [itemOffsetOwned, itemsPerPageOwned, owned]);

    const handlePageClickOwned = (event) => {
        const newOffsetOwned = (event.selected * itemsPerPageOwned) % owned.length;
        setItemOffsetOwned(newOffsetOwned);
    };
    // =============Owned pagination end===============

    // =============Read more read less state===============
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };


    // =========audio state section=========
    const [audioStatus, changeAudioStatus] = useState(false);
    const myRef = useRef();
    const startAudio = () => {
        myRef.current.play();

        changeAudioStatus(true);
    };

    const pauseAudio = () => {
        myRef.current.pause();
        changeAudioStatus(false);
    };

    async function unfollow() {
        var user_id = loginUserData ? loginUserData.id : ""

        const formData = new FormData();
        formData.append("from_id", user_id);
        formData.append("to_id", topCreaterId);

        var unfollow = await axios.post(`${BASE_URL}/unfollow`, formData)

        if (unfollow.data.status == 200) {
            // toast.success(unfollow.data.message, {
            //     position: "top-center",
            //     theme: "colored",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });

            setTimeout(() => {
                window.location.reload()
            }, 2000)
        } else {
            console.log("errro")
            // toast.error(unfollow.data.message, {
            //     position: "top-center",
            //     theme: "colored",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
        }
    }

    async function follow() {

        var user_id = loginUserData ? loginUserData.id : ""

        const formData = new FormData();
        formData.append("from_id", user_id);
        formData.append("to_id", topCreaterId);

        var follow = await axios.post(`${BASE_URL}/follow`, formData)

        console.log("Raaaaaaaaaaaa", follow.data.message)
        if (follow.data.status == 200) {
            // toast.success(follow.data.message, {
            //     position: "top-center",
            //     theme: "colored",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });

            setTimeout(() => {
                window.location.reload()
            }, 2000)
        } else {
            console.log("errro")
            // toast.error(follow.data.message, {
            //     position: "top-center",
            //     theme: "colored",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
        }
    }

    // ===========follower modal start==========
    const [showFollowers, setShowFollowers] = useState(false);

    const handleCloseFollowers = () => setShowFollowers(false);
    const handleShowFollowers = () => setShowFollowers(true);
    // ===========follower modal end==========

    // ===========following modal start==========
    const [showFollowing, setShowFollowing] = useState(false);

    const handleCloseFollowing = () => setShowFollowing(false);
    const handleShowFollowing = () => setShowFollowing(true);
    // ===========following modal end==========

    // =======full profile modal start ======== 
    const [showProfile, setShowProfile] = useState(false);
    const handleCloseProfile = () => setShowProfile(false);
    const handleShowProfile = () => setShowProfile(true);
    // =======full profile modal end ======== 

    const handleClick = (id) => {
        navigate(`/User_profile?id=${id}`)
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        window.location.reload(false);

    }
    return (
        <div>
            <Header />
            <section className='profile'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            {/* <Link to='/' className='GoBack_btn fw-bold'><FaArrowLeft className='back-icon' />  Go Back</Link> */}
                            <button className='GoBack_btn fw-bold' onClick={() => navigate(-1)} style={{ backgroundColor: "transparent" }}><FaArrowLeft className='back-icon' />  Go Back</button>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col lg={4} md={12}>
                            <div className='profile-detail text-center'>
                                <div className="profileCover_image">
                                    <div className="cover_img_div">
                                        <img src={userData.coverImage} alt="cover_img" className='img-fluid' />
                                    </div>
                                    <img src={userData.profileImage} alt="profile" className='img-fluid' onClick={handleShowProfile} style={{ cursor: "pointer" }} />
                                </div>

                                <div className='border mt-3 rounded-pill py-1'>
                                    {/* {userData.address} */}
                                    {creatorAddress.slice(0, 4)}.......{creatorAddress.slice(-4, creatorAddress.length)}
                                    <CopyToClipboard text={creatorAddress}
                                        onCopy={() => copyText()}>
                                        <span><FaRegCopy className='copy-icon ms-1' /></span>
                                    </CopyToClipboard>
                                </div>
                                <span style={{ color: '#0d6efd', display: "none" }} className="copy" id="copyAddress" >Copied.</span>
                                <div className='mt-4 heading-name'>
                                    <h4>{userData.name}</h4>
                                    <p>{userData.username}</p>
                                </div>

                                <div className='edit-profile mt-4'>
                                    {checkAddress ?
                                        <ul className='list-unstyled d-flex justify-content-around align-items-center'>
                                            {userData.followingCount == 0 ?
                                                <li style={{ cursor: "pointer" }}><strong>{userData.followingCount}</strong> <span className='d-block'>Following</span></li>
                                                :
                                                <li onClick={handleShowFollowing} style={{ cursor: "pointer" }}><strong>{userData.followingCount}</strong> <span className='d-block'>Following</span></li>
                                            }
                                            {
                                                userData.followerCount == 0 ?
                                                    <li style={{ cursor: "pointer" }}><strong>{userData.followerCount}</strong> <span className='d-block'>Followers</span></li>
                                                    :
                                                    <li onClick={handleShowFollowers} style={{ cursor: "pointer" }}><strong>{userData.followerCount}</strong> <span className='d-block'>Followers</span></li>

                                            }

                                            {
                                                (() => {
                                                    if (loginUserData.id != userData.id) {
                                                        if (userData.follow_status === 1) {
                                                            return (<li><a href="javascript:void(0)" onClick={unfollow}>Unfollow</a></li>)
                                                        } else {
                                                            return (<li><a href="javascript:void(0)" onClick={follow}>Follow</a></li>)
                                                        }
                                                    }
                                                })()
                                            }

                                        </ul> : <div></div>

                                    }



                                </div>

                                <div className='Bio mt-4 text-start'>
                                    <h6><strong>Bio</strong></h6>
                                    <hr style={{ marginTop: "4px" }}></hr>
                                    <div className={userData.bio ? "bio_textarea" : " "}>
                                        {isReadMore ? (userData.bio ? userData.bio.slice(0, 160) : '-') : (userData.bio ? userData.bio : '-')}
                                        {(userData.bio && userData.bio.length >= 160) ?
                                            <span onClick={toggleReadMore} className="read-or-hide">
                                                {isReadMore ? "...read more" : " show less"}
                                            </span>
                                            :
                                            <span></span>
                                        }
                                    </div>
                                </div>

                                <div className='link text-start mt-4'>
                                    <h6><strong>Links</strong></h6>

                                    <ul className='list-unstyled mt-3'>
                                        <li className='d-flex editing_icon'> <FaGlobe />
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Website Link"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    value={userData.links}
                                                    readOnly
                                                />
                                            </InputGroup>
                                        </li>

                                        <li className='d-flex editing_icon'> <FaTwitter />
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Twitter Link"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    value={userData.twitter_link}
                                                    readOnly
                                                />
                                            </InputGroup>
                                        </li>

                                        <li className='d-flex editing_icon'> <FaInstagram />
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Instagram Link"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    value={userData.instagram_link}
                                                    readOnly
                                                />
                                            </InputGroup>
                                        </li> 

                                        <li className='d-flex editing_icon'> <FaGithubAlt />
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Discord Link"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    value={userData.discord_link}
                                                    readOnly
                                                />
                                            </InputGroup>
                                        </li>

                                        <li className='d-flex editing_icon'> <FaFacebook />
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Facebook Link"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    value={userData.facebook_link}
                                                    readOnly
                                                />
                                            </InputGroup>
                                        </li>

                                        
                                        <li className='d-flex editing_icon'> <FaYoutube />
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Youtube Link"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    value={userData.youtube_link}
                                                    readOnly
                                                />
                                            </InputGroup>
                                        </li>

                                    </ul>
                                </div>


                                <div>
                                    <ul className='list-unstyled d-flex justify-content-between border-top border-bottom py-2'>
                                        <li><strong>Joined</strong></li>
                                        <li><strong>{(userData.joined_date)}</strong></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8} md={12} className="mt-20">
                            <Tabs defaultActiveKey="sale" id="uncontrolled-tab-example" className="mb-3 cus-tabs">
                                <Tab eventKey="sale" title="Fixed Sale">
                                    <Row className='nft_row'>
                                        {
                                            load ?
                                                <div className='loader_spiner_div  '>
                                                    {/* <ImSpinner7 className='spin_icon' /> */}
                                                </div>
                                                :
                                                (currentItemsOnSale.length === 0) ?

                                                    <div className='filter_data_card text-center py-5'>
                                                        <p>No NFT's Available</p>
                                                    </div>
                                                    :
                                                    currentItemsOnSale.map((e) => {
                                                        var link = `/prod_detail?id=${e.id}`
                                                        var e_fileName = e.image;
                                                        var e_fileExtension = e_fileName.split(/[#?]/)[0].split('.').pop().trim();

                                                        return (
                                                            <Col lg={4} md={6}>

                                                                <Card className='mt-4'>
                                                                    <Link to={link}>
                                                                        <div className='product_audio_btn_wrap'>
                                                                            {audioStatus ? (
                                                                                <button onClick={pauseAudio} className={e_fileExtension == 'mp3' ? "product_audio_btn_show1" : "product_audio_btn_hide"} ><AiOutlinePause /></button>
                                                                            ) : (
                                                                                <button onClick={startAudio} className={e_fileExtension == 'mp3' ? "product_audio_btn_show1" : "product_audio_btn_hide"}><BsFillPlayFill /></button>
                                                                            )}

                                                                        </div>
                                                                        <div className="nft_img_div">
                                                                            {(() => {
                                                                                if (e_fileExtension == 'mp3') {
                                                                                    return (
                                                                                        <div className='product_audio_wrap'>
                                                                                            <img src={e.preview_image} alt="featured" style={{ height: "235px", width: "100%", borderRadius: "7px", objectFit: "fill" }} />
                                                                                            <audio controls style={{ width: "280px" }}>
                                                                                                <source src={e.image} type="audio/ogg"></source>
                                                                                                <source src={e.image} type="audio/mpeg"></source>
                                                                                                Your browser does not support the audio element.
                                                                                            </audio>
                                                                                        </div>
                                                                                    )
                                                                                } else if (e_fileExtension == 'mp4') {
                                                                                    return (
                                                                                        <video controls className='img-fluid' style={{ height: "100%", width: "100%", backgroundColor: "#000", borderRadius: "7px" }} controlsList="nodownload" onContextMenu="return false;">
                                                                                            <source src={e.image} type="video/mp4"></source>
                                                                                            Your browser does not support the audio element.
                                                                                        </video>
                                                                                    )
                                                                                } else {
                                                                                    return e_fileExtension == "png" ||
                                                                                        e_fileExtension == "jpg" ||
                                                                                        e_fileExtension == "gif" ||
                                                                                        e_fileExtension == "jpeg" ||
                                                                                        e_fileExtension == "svg" ?
                                                                                        (
                                                                                            <img src={e.image} alt="featured" className='img-fluid' />
                                                                                        ) : (
                                                                                            <div className='threeD_custom_product'>
                                                                                                <model-viewer src={e.image} alt="VR Headset" auto-rotate camera-controls ar ios-src={e.image}>
                                                                                                </model-viewer>
                                                                                            </div>
                                                                                        );
                                                                                }
                                                                            })()}
                                                                        </div>

                                                                        <div>
                                                                            <Card.Body>
                                                                                <div className='user-section d-flex justify-content-between' style={{ height: "85px" }}>
                                                                                    {/* <div className='category_name'>{e.name}</div> */}
                                                                                    <div>
                                                                                        <td rowSpan="2" colSpan="2">
                                                                                            <tr className='category_name'>{e.category}</tr>
                                                                                            <tr className='nft_name_user_profile'><h6>{e.name}</h6></tr>
                                                                                            <tr><div className='erc_div mt-1'>{e.create_mint_type}</div></tr>
                                                                                        </td>
                                                                                    </div>
                                                                                    <div>
                                                                                        {
                                                                                            (() => {
                                                                                                if (e.mint_type === "2") {
                                                                                                    return (
                                                                                                        <div className='nft_price'>
                                                                                                            <div class="price_text ms-1">Price </div>
                                                                                                            <img src={bharatToken} alt="img" className='img-fluid me-1' />
                                                                                                            <strong>{e.nft_price}</strong>
                                                                                                        </div>

                                                                                                    )
                                                                                                }else{
                                                                                                    if (e.chain === "BNB") {
                                                
                                                                                                        return (
                                                                                                            <div className='nft_price'>
                                                                                                                <div class="price_text ms-1">Price </div>
                                                                                                                <img src={bnb} alt="img" className='img-fluid me-1' />
                                                                                                                <strong>{e.nft_price}</strong>
                                                                                                            </div>
    
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <div className='nft_price'>
                                                                                                                <div class="price_text ms-1">Price </div>
                                                                                                                <img src={ethereum} alt="img" className='img-fluid me-1' />
                                                                                                                <strong>{e.nft_price}</strong>
    
                                                                                                            </div>
    
                                                                                                        )
                                                                                                    }
                                                                                                }
                                                                                            })()
                                                                                        }

                                                                                    </div>

                                                                                </div>
                                                                            </Card.Body>
                                                                        </div>
                                                                    </Link>
                                                                </Card>
                                                            </Col>
                                                        )
                                                    })




                                        }


                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            {
                                                (() => {


                                                    if (onSale.length > 6) {
                                                        return (
                                                            <div className="paginate mt-4">
                                                                <ReactPaginate
                                                                    breakLabel="..."
                                                                    nextLabel=" >>"
                                                                    onPageChange={handlePageClickOnSale}
                                                                    pageRangeDisplayed={3}
                                                                    marginPagesDisplayed={2}
                                                                    pageCount={pageCountOnSale}
                                                                    previousLabel="<< "
                                                                    containerClassName='pagination justify-content-end'
                                                                    pageClassName='page-item'
                                                                    pageLinkClassName='page-link'
                                                                    previousClassName='page-item'
                                                                    previousLinkClassName='page-link'
                                                                    nextClassName='page-item'
                                                                    nextLinkClassName='page-link'
                                                                    breakClassName='page-item'
                                                                    breakLinkClassName='page-link'
                                                                    activeClassName='active'

                                                                />
                                                            </div>
                                                        )
                                                    }

                                                })()
                                            }
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="activity" title="Auction">
                                    <Row className='nft_row'>
                                        {
                                            load ?
                                                <div className='loader_spiner_div  '>
                                                    {/* <ImSpinner7 className='spin_icon' /> */}
                                                </div>
                                                :
                                                (currentItemsActivity.length === 0) ?

                                                    <div className='filter_data_card text-center py-5'>
                                                        <p>No NFT's Available</p>
                                                    </div>
                                                    :
                                                    currentItemsActivity.map((e) => {
                                                        function toTimestamp(strDate) {
                                                            var datum = Date.parse(strDate);
                                                            return datum / 1000;
                                                        }
                                                        var link = `/prod_detail?id=${e.id}`
                                                        var e_fileName = e.image;
                                                        var e_fileExtension = e_fileName.split(/[#?]/)[0].split('.').pop().trim();
                                                        const auction_date = Moment(e.aucation_date).format('MMMM DD YYYY h:mm A')
                                                        const currentDate_time = new Date()

                                                        const new_auction_date = Moment(currentDate_time).format('DD MMMM YYYY h:mm A')
                                                        const new_aucation_timestamp = toTimestamp(new_auction_date)
                                                        return (
                                                            <Col lg={4} md={6}>

                                                                <Card className='mt-4'>
                                                                    <Link to={link}>
                                                                        <div className='product_audio_btn_wrap'>
                                                                            {audioStatus ? (
                                                                                <button onClick={pauseAudio} className={e_fileExtension == 'mp3' ? "product_audio_btn_show1" : "product_audio_btn_hide"}><AiOutlinePause /></button>
                                                                            ) : (
                                                                                <button onClick={startAudio} className={e_fileExtension == 'mp3' ? "product_audio_btn_show1" : "product_audio_btn_hide"}><BsFillPlayFill /></button>
                                                                            )}

                                                                        </div>
                                                                        <div className="nft_img_div">
                                                                            {(() => {
                                                                                if (e_fileExtension == 'mp3') {
                                                                                    return (
                                                                                        <div className='product_audio_wrap'>
                                                                                            <img src={e.preview_image} alt="featured" style={{ height: "236px", width: "100%", borderRadius: "7px" }} />
                                                                                            <audio controls style={{ width: "280px" }}>
                                                                                                <source src={e.image} type="audio/ogg"></source>
                                                                                                <source src={e.image} type="audio/mpeg"></source>
                                                                                                Your browser does not support the audio element.
                                                                                            </audio>
                                                                                        </div>
                                                                                    )
                                                                                } else if (e_fileExtension == 'mp4') {
                                                                                    return (
                                                                                        <video controls className='img-fluid' style={{ height: "100%", width: "100%", backgroundColor: "#000", borderRadius: "7px" }} controlsList="nodownload" oncontextmenu="return false;">
                                                                                            <source src={e.image} type="video/mp4"></source>
                                                                                            Your browser does not support the audio element.
                                                                                        </video>
                                                                                    )
                                                                                } else {
                                                                                    return e_fileExtension == "png" ||
                                                                                        e_fileExtension == "jpg" ||
                                                                                        e_fileExtension == "gif" ||
                                                                                        e_fileExtension == "jpeg" ||
                                                                                        e_fileExtension == "svg" ?
                                                                                        (
                                                                                            <img src={e.image} alt="featured" className='img-fluid' />
                                                                                        ) : (
                                                                                            <div className='threeD_custom_product'>
                                                                                                <model-viewer src={e.image} alt="VR Headset" auto-rotate camera-controls ar ios-src={e.image}>
                                                                                                </model-viewer>
                                                                                            </div>
                                                                                        );
                                                                                }
                                                                            })()}
                                                                        </div>

                                                                        <div>
                                                                            <Card.Body>
                                                                                <div className='user-section d-flex justify-content-between' style={{ height: "85px" }}>
                                                                                    <div>
                                                                                        <td rowSpan="2" colSpan="2">
                                                                                            <tr className='category_name'>{e.category}</tr>
                                                                                            <tr className='nft_name_user_profile'>{e.name}</tr>
                                                                                            <tr><div className='erc_div mt-1'>{e.create_mint_type}</div></tr>
                                                                                        </td>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h6>
                                                                                            {
                                                                                                (() => {
                                                                                                    if (e.mint_type === "2") {
                                                                                                        return (
                                                                                                            <div className='nft_price'>
                                                                                                                <div class="price_text ms-1">Price </div>
                                                                                                                <img src={bharatToken} alt="img" className='img-fluid me-1' />
                                                                                                                <strong>{e.highest_bid_price}</strong>
                                                                                                            </div>
    
                                                                                                        )
                                                                                                    }else{
                                                                                                        if (e.chain === "BNB") {
                                                    
                                                                                                            return (
                                                                                                                <div className='nft_price'>
                                                                                                                    <div class="price_text ms-1">Price </div>
                                                                                                                    <img src={bnb} alt="img" className='img-fluid me-1' />
                                                                                                                    <strong>{e.nft_price}</strong>
                                                                                                                </div>
        
                                                                                                            )
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <div className='nft_price'>
                                                                                                                    <div class="price_text ms-1">Price </div>
                                                                                                                    <img src={ethereum} alt="img" className='img-fluid me-1' />
                                                                                                                    <strong>{e.nft_price}</strong>
        
                                                                                                                </div>
        
                                                                                                            )
                                                                                                        }
                                                                                                    }
                                                                                                })()
                                                                                            }

                                                                                        </h6>
                                                                                        <div className="nft_auction_time">
                                                                                            {(new_aucation_timestamp < e.aucation_timestamp) ?
                                                                                                (() => {
                                                                                                    if (e.mint_type === "2" && e.auction_left_date != "" && e.auction_left_date != " ") {
                                                                                                        return (
                                                                                                            <Timer1 currentTime={auction_date} />
                                                                                                        )
                                                                                                    } else {
                                                                                                        return null
                                                                                                    }
                                                                                                })()
                                                                                                :
                                                                                                <div></div>
                                                                                            }

                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                            </Card.Body>
                                                                        </div>
                                                                    </Link>
                                                                </Card>
                                                            </Col>
                                                        )
                                                    })




                                        }
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            {
                                                (() => {

                                                    if (activity.length > 6) {
                                                        return (
                                                            <div className="paginate mt-4">
                                                                <ReactPaginate
                                                                    breakLabel="..."
                                                                    nextLabel=" >>"
                                                                    onPageChange={handlePageClickActivity}
                                                                    pageRangeDisplayed={3}
                                                                    marginPagesDisplayed={2}
                                                                    pageCount={pageCountActivity}
                                                                    previousLabel="<< "
                                                                    containerClassName='pagination justify-content-end'
                                                                    pageClassName='page-item'
                                                                    pageLinkClassName='page-link'
                                                                    previousClassName='page-item'
                                                                    previousLinkClassName='page-link'
                                                                    nextClassName='page-item'
                                                                    nextLinkClassName='page-link'
                                                                    breakClassName='page-item'
                                                                    breakLinkClassName='page-link'
                                                                    activeClassName='active'

                                                                />
                                                            </div>
                                                        )
                                                    }
                                                })()
                                            }
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="owned" title="Owned">
                                    <Row className='nft_row'>
                                        {
                                            load ?
                                                <div className='loader_spiner_div  '>
                                                    {/* <ImSpinner7 className='spin_icon' /> */}
                                                </div>
                                                :
                                                (currentItemsOwned.length === 0) ?


                                                    <div className='filter_data_card text-center py-5'>
                                                        <p>No NFT's Available</p>
                                                    </div>
                                                    :
                                                    currentItemsOwned.map((e) => {
                                                        var link = `/prod_detail?id=${e.id}`
                                                        var e_fileName = e.image;
                                                        var e_fileExtension = e_fileName.split(/[#?]/)[0].split('.').pop().trim();

                                                        return (
                                                            <Col lg={4} md={6}>

                                                                <Card className='mt-4'>
                                                                    <Link to={link}>
                                                                        <div className='product_audio_btn_wrap'>
                                                                            {audioStatus ? (
                                                                                <button onClick={pauseAudio} className={e_fileExtension == 'mp3' ? "product_audio_btn_show1" : "product_audio_btn_hide"} ><AiOutlinePause /></button>
                                                                            ) : (
                                                                                <button onClick={startAudio} className={e_fileExtension == 'mp3' ? "product_audio_btn_show1" : "product_audio_btn_hide"}><BsFillPlayFill style={{ marginLeft: "4px", marginTop: "-5px" }} /></button>
                                                                            )}

                                                                        </div>
                                                                        <div className="nft_img_div">
                                                                            {(() => {
                                                                                if (e_fileExtension == 'mp3') {
                                                                                    return (
                                                                                        <div className='product_audio_wrap'>
                                                                                            <img src={e.preview_image} alt="featured" style={{ height: "236px", width: "100%", borderRadius: "7px" }} />
                                                                                            <audio controls style={{ width: "280px" }}>
                                                                                                <source src={e.image} type="audio/ogg"></source>
                                                                                                <source src={e.image} type="audio/mpeg"></source>
                                                                                                Your browser does not support the audio element.
                                                                                            </audio>
                                                                                        </div>
                                                                                    )
                                                                                } else if (e_fileExtension == 'mp4') {
                                                                                    return (
                                                                                        <video controls className='img-fluid' style={{ height: "100%", width: "100%", backgroundColor: "#000", borderRadius: "7px" }} controlsList="nodownload" oncontextmenu="return false;">
                                                                                            <source src={e.image} type="video/mp4"></source>
                                                                                            Your browser does not support the audio element.
                                                                                        </video>
                                                                                    )
                                                                                } else {
                                                                                    return e_fileExtension == "png" ||
                                                                                        e_fileExtension == "jpg" ||
                                                                                        e_fileExtension == "gif" ||
                                                                                        e_fileExtension == "jpeg" ||
                                                                                        e_fileExtension == "svg" ?
                                                                                        (
                                                                                            <img src={e.image} alt="featured" className='img-fluid' />
                                                                                        ) : (
                                                                                            <div className='threeD_custom_product'>
                                                                                                <model-viewer src={e.image} alt="VR Headset" auto-rotate camera-controls ar ios-src={e.image}>
                                                                                                </model-viewer>
                                                                                            </div>
                                                                                        );
                                                                                }
                                                                            })()}
                                                                        </div>

                                                                        <div>
                                                                            <Card.Body>
                                                                                <div className='user-section d-flex justify-content-between' style={{ height: "85px" }}>
                                                                                    <div>
                                                                                        <td rowSpan="2" colSpan="2">
                                                                                            <tr className='category_name'>{e.category}</tr>
                                                                                            <tr className='nft_name_user_profile'>{e.name}</tr>
                                                                                            <tr><div className='erc_div mt-1'>{e.create_mint_type}</div></tr>
                                                                                        </td>
                                                                                    </div>
                                                                                    <h6>
                                                                                        {
                                                                                            (() => {
                                                                                                if (e.mint_type === "2") {
                                                                                                    return (
                                                                                                        <div className='nft_price'>
                                                                                                            <div class="price_text ms-1">Price </div>
                                                                                                            <img src={bharatToken} alt="img" className='img-fluid me-1' />
                                                                                                            <strong>{e.nft_price}</strong>
                                                                                                        </div>

                                                                                                    )
                                                                                                }else{
                                                                                                    if (e.chain === "BNB") {
                                                
                                                                                                        return (
                                                                                                            <div className='nft_price'>
                                                                                                                <div class="price_text ms-1">Price </div>
                                                                                                                <img src={bnb} alt="img" className='img-fluid me-1' />
                                                                                                                <strong>{e.nft_price}</strong>
                                                                                                            </div>
    
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <div className='nft_price'>
                                                                                                                <div class="price_text ms-1">Price </div>
                                                                                                                <img src={ethereum} alt="img" className='img-fluid me-1' />
                                                                                                                <strong>{e.nft_price}</strong>
    
                                                                                                            </div>
    
                                                                                                        )
                                                                                                    }
                                                                                                }
                                                                                            })()
                                                                                        }

                                                                                    </h6>
                                                                                </div>
                                                                            </Card.Body>
                                                                        </div>
                                                                    </Link>
                                                                </Card>
                                                            </Col>
                                                        )
                                                    })





                                        }
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            {
                                                (() => {

                                                    if (owned.length > 6) {
                                                        return (
                                                            <div className="paginate mt-4">
                                                                <ReactPaginate
                                                                    breakLabel="..."
                                                                    nextLabel=" >>"
                                                                    onPageChange={handlePageClickOwned}
                                                                    pageRangeDisplayed={3}
                                                                    marginPagesDisplayed={2}
                                                                    pageCount={pageCountOwned}
                                                                    previousLabel="<< "
                                                                    containerClassName='pagination justify-content-end'
                                                                    pageClassName='page-item'
                                                                    pageLinkClassName='page-link'
                                                                    previousClassName='page-item'
                                                                    previousLinkClassName='page-link'
                                                                    nextClassName='page-item'
                                                                    nextLinkClassName='page-link'
                                                                    breakClassName='page-item'
                                                                    breakLinkClassName='page-link'
                                                                    activeClassName='active'

                                                                />
                                                            </div>
                                                        )
                                                    }
                                                })()
                                            }
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer
                    position="bottom-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </section>
            <Footer />

            {/* // ===========follower modal start========== */}
            <Modal show={showFollowers} onHide={handleCloseFollowers} className="follower_profile_modal">
                <Modal.Header closeButton>
                    <Modal.Title>All Followers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="followers_modal">
                        {
                            userData.follower_user_list ?

                                userData.follower_user_list.map((e) => {

                                    return (

                                        <div className="followers_list" onClick={() => { handleClick(e.id) }}>
                                            <div className="follower_users">
                                                <div className="follower_img">
                                                    <img src={e.profileImage} alt="follower_img" className='img-fluid' />
                                                </div>
                                                <div className="follower_details">
                                                    <div className="follower_profile">
                                                        <div className="follower_name">{e.name ? e.name : "Unname"}</div>
                                                        <div className="follower_address">{e.address.slice(0, 4)}....{e.address.slice(-4, e.address.length)}</div>
                                                    </div>
                                                    <div className="follower_items">{e.totalItem} items</div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })
                                :
                                <div className='no_followers'>
                                    <h5>No Followers</h5>
                                </div>
                        }



                    </div>

                </Modal.Body>

            </Modal>
            {/* // ===========follower modal end========== */}

            {/* // ===========following modal start========== */}
            <Modal show={showFollowing} onHide={handleCloseFollowing} className="follower_profile_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Following By</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="followers_modal">
                        {
                            userData.following_user_list ?

                                userData.following_user_list.map((e) => {

                                    return (

                                        <div className="followers_list" onClick={() => { handleClick(e.id) }}>
                                            <div className="follower_users">
                                                <div className="follower_img">
                                                    <img src={e.profileImage} alt="follower_img" className='img-fluid' />
                                                </div>
                                                <div className="follower_details">
                                                    <div className="follower_profile">
                                                        <div className="follower_name">{e.name ? e.name : "Unname"}</div>
                                                        <div className="follower_address">{e.address.slice(0, 4)}....{e.address.slice(-4, e.address.length)}</div>
                                                    </div>
                                                    <div className="follower_items">{e.totalItem} items</div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })
                                :
                                <div className='no_followers'>
                                    <h5>No Following</h5>
                                </div>
                        }



                    </div>

                </Modal.Body>

            </Modal>
            {/* // ===========following modal end========== */}

            {/* =========full profile modal start======== */}
            <Modal
                show={showProfile}
                onHide={handleCloseProfile}
                className="fullProfile_modal"
            >
                <Modal.Body>
                    <img src={userData.profileImage} className="img-fluid"></img>
                </Modal.Body>

            </Modal>
            {/* =========full profile modal end======== */}

        </div>

    )
}

export default UserProfile