import React, {useState, useEffect, useRef} from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import Timer1 from '../Timer1'
import Moment from 'moment';
import ethereum from '../../assets/img/ether.svg'
import bnb from '../../assets/img/bnb.png'
import bharatToken from '../../assets/img/bharatToken(1).png'
import { BsFillPlayFill } from 'react-icons/bs';
import { AiOutlinePause } from 'react-icons/ai';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
// import { ImSpinner7 } from 'react-icons/im';

const BASE_URL = process.env.REACT_APP_BASE_URL

const ProductCollection = ({id, setPageUpdate, pageUpdate}) => {
    const navigate = useNavigate()
    
    
    // ============all  nft collection function start==============
    const [NftExplore, setExplore] = useState([]);
    const [load, setLoading] = useState(true)

    async function explore() {
        await axios.get(`${BASE_URL}/getAllNFT`).then(async (data) => {
           
            if (data.data.result) {
                setExplore(data.data.result)
                setLoading(false)
            }

        }).catch(err => {
            console.log("err", err)
        })

    }
    useEffect(() => {
        explore()
        setTimeout(()=>{
            setLoading(false) 
        },1000)
    }, [])
    const handleAllNft = () => {
        navigate('/all_nft')
    }

    const handleClcik = (id) => {
       
        navigate(`/prod_detail?id=${id}`)
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setPageUpdate(!pageUpdate)
   }

    // audio state section
    const [audioStatus, changeAudioStatus] = useState(false);
    const myRef = useRef();
    const startAudio = () => {
        myRef.current.play();

        changeAudioStatus(true);
    };

    const pauseAudio = () => {
        console.log("here");
        myRef.current.pause();
        changeAudioStatus(false);
    };

   // ============all  nft collection function end==============
    return(
        <>
            <div className="productCollection_section">
                <Container>
                <Row className='mt-5'>
                            <Col sm={3}>
                                <h4 className='collection-title'>Collections</h4>
                            </Col>
                            <Col sm={9} className='d-flex justify-content-end align-items-center'>
                                <div>
                                    <Button size="sm" variant="outline-primary" className='collection-btn me-2 trending_btn' onClick={handleAllNft}>All NFT</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className='prod_nft_all '>
                            {
                                 load ?
                                 <div className='loader_spiner_div  '>
                                     {/* <ImSpinner7 className='spin_icon' /> */}
                                 </div>
                                 :
                                (NftExplore.length === 0) ?

                                    <div className='filter_data_card text-center py-5'>
                                        <p>No NFT's Available</p>
                                    </div>
                                    :
                                    NftExplore.map((e, index) => {
                                        var name = e.name
                                        var image = e.image
                                        var userProfile = e.userProfile
                                        var username = e.userFullName
                                        var price = e.price
                                        var category = e.category
                                        var auction_left_date = e.auction_left_date
                                        var mint_type = e.mint_type
                                        var chain = e.chain
                                        var preview_image = e.preview_image
                                        var create_mint_type = e.create_mint_type

                                        var e_fileName = e.image;
                                        var e_fileExtension = e_fileName.split(/[#?]/)[0].split('.').pop().trim();

                                        // =========timer section start========
                                        const currentDate_time1 = new Date()

                                        const new_auction_date1 = Moment(currentDate_time1).format('DD MMMM YYYY h:mm A')

                                        const auction_date = Moment(e.aucation_date).format('MMMM DD YYYY h:mm A')
                                         // =========timer section end========
                                       
                                        if(e.id != id){
                                            if (index <= 4)
                                                return (
                                                    <Col lg={3} md={6}>
                                                        <Card className='mt-4' onClick={() => { handleClcik(e.id) }} key={index}>
                                                            <div className='product_audio_btn_wrap'>
                                                                {audioStatus ? (
                                                                    <button onClick={pauseAudio} className={e_fileExtension == 'mp3' ? "product_audio_btn_show" : "product_audio_btn_hide"} ><AiOutlinePause /></button>
                                                                ) : (
                                                                    <button onClick={startAudio} className={e_fileExtension == 'mp3' ? "product_audio_btn_show" : "product_audio_btn_hide"}><BsFillPlayFill /></button>
                                                                )}

                                                            </div>
                                                            <div className="nft_img_div">
                                                                {(() => {
                                                                    if (e_fileExtension == 'mp3') {
                                                                        return (

                                                                            <div className='product_audio_wrap'>
                                                                                <img src={preview_image} alt="featured" style={{  height: "235px", width: "100%", borderRadius: "7px", objectFit:"fill"  }} />
                                                                                <audio ref={myRef} controls controlsList="nodownload noplaybackrate" style={{ width: "280px" }}>
                                                                                    <source src={image} type="audio/ogg"></source>
                                                                                    <source src={image} type="audio/mpeg"></source>
                                                                                    Your browser does not support the audio element.
                                                                                </audio>
                                                                            </div>
                                                                        )
                                                                    } else if (e_fileExtension == 'mp4') {
                                                                        return (
                                                                            <video   controls className='img-fluid' style={{ height: "100%", width: "100%", backgroundColor:"#000", borderRadius:"7px" }} controlsList="nodownload" oncontextmenu="return false;">
                                                                                <source src={image} type="video/mp4"></source>
                                                                                Your browser does not support the audio element.
                                                                            </video>
                                                                        )
                                                                    } else {
                                                                        return e_fileExtension == "png" ||
                                                                            e_fileExtension == "jpg" ||
                                                                            e_fileExtension == "gif" ||
                                                                            e_fileExtension == "jpeg" ||
                                                                            e_fileExtension == "svg" ? 
                                                                            (
                                                                                <img src={e.image} alt="featured" className='img-fluid' />
                                                                        ) : (
                                                                            <div className='threeD_custom_product'>
                                                                                <model-viewer src={e.image} alt="VR Headset" auto-rotate camera-controls ar ios-src={e.image}>
                                                                                </model-viewer>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })()}

                                                            </div>

                                                            <div>
                                                                <Card.Body>
                                                                    <div className="nft_main_div">
                                                                        <div className="nft_left_div">
                                                                            <div className="category_name">{category}</div>
                                                                            <div className="nft_name"><h6>{name}</h6></div>
                                                                            <div className='erc_div mt-1'>{create_mint_type}</div>
                                                                        </div>
                                                                        <div className="nft_right_div">
                                                                            <div className="price_text">Price</div>
                                                                            <div className="nft_price">
                                                                                {
                                                                                    (() => {
                                                                                        if(create_mint_type === "ERC-721" && mint_type === "2"){
                                                                                            return(
                                                                                                <img src={bharatToken} alt="img" className='img-fluid me-1' />
                                                                                            )
                                                                                        }else{
                                                                                            if (chain === "BNB") {

                                                                                                return (
                                                                                                    <img src={bnb} alt="img" className='img-fluid me-1' />
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <img src={ethereum} alt="img" className='img-fluid me-1' />
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                    })()
                                                                                }
                                                                                <strong>{price}</strong>

                                                                            </div>
                                                                            <div className="nft_auction_time">
                                                                                {
                                                                                    (() => {
                                                                                        if (mint_type === "2" && auction_left_date != "" && auction_left_date != " ") {
                                                                                            return (
                                                                                                <Timer1 currentTime={auction_date} />
                                                                                            )
                                                                                        } else {
                                                                                            return null
                                                                                        }
                                                                                    })()
                                                                                }
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                )
                                        }
                                    })

                            }
                        </Row>
                </Container>
            </div>
        </>
    )
}



export default ProductCollection;