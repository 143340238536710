import React, { useState, useEffect, useRef } from "react";
import Footer from "../Footer";
import Header from "../Header";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Card,
  Tabs,
  Tab,Modal
} from "react-bootstrap";
import { Link ,useNavigate} from "react-router-dom";
import {
  FaArrowLeft,
  FaGlobe,
  FaGithubAlt,
  FaInstagram,
  FaTwitter,
  FaTelegramPlane,
  FaFacebook,
  FaRegCopy,
  FaYoutube
} from "react-icons/fa";
import $ from "jquery";
import { HiUsers, HiUser } from "react-icons/hi";
import { BsGrid3X2GapFill } from "react-icons/bs";
import ethereum from "../../assets/img/ether.svg";
import bnb from "../../assets/img/bnb.png";
import bharatToken from "../../assets/img/bharatToken(1).png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParams, useLocation } from "react-router";
import { ImSpinner7 } from "react-icons/im";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BsFillPlayFill } from "react-icons/bs";
import { AiOutlinePause } from "react-icons/ai";

var BASE_URL = process.env.REACT_APP_BASE_URL;
function SingleCollection() {
  const [audioStatus, changeAudioStatus] = useState(false);
  const myRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  // var result = location.pathname.split("/");
  // var collection_name = result[result.length - 1];

  const [websiteLink, setWebsiteLink] = useState("");
  const [discordLink, setDiscordLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  // const [telegramLink, setTelegramLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [collectionDetail, setSingleCollectionData] = useState([]);
  const [allNFTOfCollection, setSingleCollectionAllNFT] = useState([]);
  const [load, setLoading] = useState(true);
  console.log("  const params = useParams();", params)
  // =============Read more read less state===============
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  var collection_url = params.singleCollection;
console.log("RRRRRRRRRRRRRRRRRRRRRRRR",params.singleCollection)
  async function getCollectionDetailsFunction() {
    let newApiData = { word: collection_url };
    var getCollectionData = await axios.post(`${BASE_URL}/collectionDetail`,
      newApiData
    );
    if (getCollectionData.data.status === 200) {
      setSingleCollectionData(getCollectionData.data.result);
      setSingleCollectionAllNFT(
        getCollectionData.data.result.allNFT_collection
      );
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }

  console.log("rewrrrrrrrrrrr",allNFTOfCollection)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      getCollectionDetailsFunction();
    }, 1000);
   
  },[]);

  const startAudio = () => {
    myRef.current.play();

    changeAudioStatus(true);
  };

  const pauseAudio = () => {
    myRef.current.pause();
    changeAudioStatus(false);
  };
  console.log("collectionDetailLLLLLLLLLLLLLLLLLLLLL", collectionDetail);

  //  =======copy test======
  const copyText = () => {
    $("#copyAddress").show();
    setTimeout(() => {
      $("#copyAddress").hide();
    }, 1000);
  };

  const [showProfile, setShowProfile] = useState(false);
  const handleCloseProfile = () => setShowProfile(false);
  const handleShowProfile = () => setShowProfile(true);
// ===========Owner modal start==========
const [showOwner, setShowOwner] = useState(false);

const handleCloseOwner = () => setShowOwner(false);
const handleShowOwner = () => setShowOwner(true);
// ===========Owner modal end==========

const handleClick = (id) => {
  navigate(`/User_profile?id=${id}`)
  window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  window.location.reload(false);

}
  return (
    <div>
      <Header />
      <section className="single_collection_div">
        <Container>
          <Row>
            <Col lg={12}>
            <button className='GoBack_btn fw-bold' onClick={() => navigate(-1)} style={{ backgroundColor: "transparent" }}><FaArrowLeft className='back-icon' />  Go Back</button>
            </Col>
          </Row>
          {load ? 
            <div className="loader_spiner_div  ">
              {/* <ImSpinner7 className='spin_icon' /> */}
            </div>
           : 
            <Row className="mt-3">
              <Col lg={4} md={12}>
                <div className="profile-detail text-center">
                  <div className="profileCover_image">
                    <div className="cover_img_div">
                      <img
                        src={
                          collectionDetail ? collectionDetail.bannerImage : ""
                        }
                        alt="cover_img"
                        className="img-fluid"
                      />
                    </div>
                    <img
                      src={collectionDetail ? collectionDetail.logo : ""}
                      alt="profile"
                      className="img-fluid"
                      style={{ cursor: "pointer" }}
                      onClick={handleShowProfile}
                    />
                  </div>

                  {/* <div className='border mt-3 rounded-pill py-1'>
                                        {walletAddress.slice(0, 4)}.......{walletAddress.slice(-4, walletAddress.length)}
                                        <CopyToClipboard text={walletAddress}
                                            onCopy={() => copyText()}>
                                            <span><FaRegCopy className='copy-icon ms-1' /></span>
                                        </CopyToClipboard>
                                    </div> */}
                  <span
                    style={{ color: "#0d6efd", display: "none" }}
                    className="copy"
                    id="copyAddress"
                  >
                    Copied.
                  </span>
                  <div className="mt-4 heading-name">
                    <h3>{collectionDetail ? collectionDetail.name : ""}</h3>
                    {/* <h6>username</h6> */}
                  </div>

                  <div className="Bio mt-4 text-start">
                    
                    <hr style={{ marginTop: "4px" }}></hr>
                  <div className={collectionDetail.description ? "bio_textarea mt-4" : "mt-4 "}>
                    <div className={collectionDetail ? collectionDetail.description : ""}>

                      {isReadMore ? (collectionDetail.description ? collectionDetail.description.slice(0, 160) : '-') : (collectionDetail.description ? collectionDetail.description : '-')}
                      {(collectionDetail.description && collectionDetail.description.length >= 160) ?
                        <span onClick={toggleReadMore} className="read-or-hide">
                          {isReadMore ? "...read more" : " show less"}
                        </span>
                        : <span></span>
                      }
                    </div>
                  </div>
                  </div>

                  <div className="link text-start mt-4">
                    <Form.Label>Links</Form.Label>

                    <ul className="list-unstyled mt-3">
                      <li className="d-flex editing_icon">
                       
                        <FaGlobe />
                        <InputGroup>
                          <FormControl
                            placeholder="Website Link"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            value={
                              collectionDetail
                                ? collectionDetail.website_link
                                : ""
                            }
                          />
                        </InputGroup>
                      </li>
                      <li className="d-flex editing_icon">
                  
                        <FaTwitter />
                        <InputGroup>
                          <FormControl
                            placeholder="Twitter Link"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            value={
                              collectionDetail ? collectionDetail.twitter : ""
                            }
                          />
                        </InputGroup>
                      </li>
                      <li className="d-flex editing_icon">
                     
                        <FaInstagram />
                        <InputGroup>
                          <FormControl
                            placeholder="Instagram Link"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            value={
                              collectionDetail ? collectionDetail.instagram : ""
                            }
                          />
                        </InputGroup>
                      </li>
                      <li className="d-flex editing_icon">
                 
                        <FaGithubAlt />
                        <InputGroup>
                          <FormControl
                            placeholder="Discord Link"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            value={
                              collectionDetail ? collectionDetail.discord : ""
                            }
                          />
                        </InputGroup>
                      </li>

                      <li className="d-flex editing_icon">
                    
                        <FaFacebook />
                        <InputGroup>
                          <FormControl
                            placeholder="Facebook Link"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            value={
                              collectionDetail ? collectionDetail.facebook : ""
                            }
                          />
                        </InputGroup>
                      </li>

                      <li className="d-flex editing_icon">
                    
                        <FaYoutube />
                        <InputGroup>
                          <FormControl
                            placeholder="Youtube Link"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            value={
                              collectionDetail ? collectionDetail.youtube : ""
                            }
                          />
                        </InputGroup>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={8} md={12}>
                <div className="total_owner_div">
                  <ul>
                  {
                        collectionDetail.total_owner == 0 ?
                            <li className="owners_li">
                              <span className="icons">
                                <HiUsers />
                              </span>{" "}
                              <span className="name">{collectionDetail.total_owner} Owners</span>
                            </li>
                            :
                            <li onClick={handleShowOwner} className="owners_li">
                              <span className="icons">
                                <HiUsers />
                              </span>{" "}
                              <span className="name">{collectionDetail.total_owner} Owners</span>
                            </li>
                            // <li onClick={handleShowFollowers} style={{ cursor: "pointer" }}><strong>{userData.followerCount}</strong> <span className='d-block'>Followers</span></li>

                    }
                    {/* <li className="owners_li">
                      <span className="icons">
                        <HiUsers />
                      </span>
                      <span className="name">{collectionDetail.total_owner} Owners</span>
                    </li> */}
                    <li>
                      <span className="icons">
                        <BsGrid3X2GapFill />
                      </span>
                      <span className="name">{collectionDetail.total_items} Items</span>
                    </li>
                  </ul>
                </div>
                <Tabs
                  defaultActiveKey="all_nft"
                  id="uncontrolled-tab-example"
                  className="mb-3 cus-tabs"
                >
                  <Tab eventKey="all_nft" title="All NFT">
                    <Row className="nft_row">
                      {allNFTOfCollection.length != 0 ? (
                        allNFTOfCollection.map((e) => {
                          var e_fileExtension = e.image
                            .split(/[#?]/)[0]
                            .split(".")
                            .pop()
                            .trim();
                            var link = `/prod_detail?id=${e.id}`
                          return (
                            <Col lg={4} md={6}>
                              <Card className="mt-4">
                                <div className="product_audio_btn_wrap">
                                  {audioStatus ? (
                                    <button
                                      onClick={pauseAudio}
                                      className={
                                        e_fileExtension == "mp3"
                                          ? "product_audio_btn_show"
                                          : "product_audio_btn_hide"
                                      }
                                    >
                                      <AiOutlinePause />
                                    </button>
                                  ) : (
                                    <button
                                      onClick={startAudio}
                                      className={
                                        e_fileExtension == "mp3"
                                          ? "product_audio_btn_show"
                                          : "product_audio_btn_hide"
                                      }
                                    >
                                      <BsFillPlayFill />
                                    </button>
                                  )}
                                </div>
                                <Link to={link}>
                                <div className="nft_img_div">
                                  {(() => {
                                    if (e_fileExtension == "mp3") {
                                      return (
                                        <div className="product_audio_wrap">
                                          <img
                                            src={e.preview_image}
                                            alt="featured"
                                            style={{
                                              height: "235px",
                                              width: "100%",
                                              borderRadius: "7px",
                                              objectFit: "fill",
                                            }}
                                          />
                                          <audio
                                            ref={myRef}
                                            controls
                                            controlsList="nodownload noplaybackrate"
                                            style={{ width: "280px" }}
                                          >
                                            <source
                                              src={e.image}
                                              type="audio/ogg"
                                            ></source>
                                            <source
                                              src={e.image}
                                              type="audio/mpeg"
                                            ></source>
                                            Your browser does not support the
                                            audio element.
                                          </audio>
                                        </div>
                                      );
                                    } else if (e_fileExtension == "mp4") {
                                      return (
                                        <video
                                          controls
                                          className="img-fluid"
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            backgroundColor: "#000",
                                            borderRadius: "7px",
                                          }}
                                          controlsList="nodownload"
                                          oncontextmenu="return false;"
                                        >
                                          <source
                                            src={e.image}
                                            type="video/mp4"
                                          ></source>
                                          Your browser does not support the
                                          audio element.
                                        </video>
                                      );
                                    } else {
                                      return e_fileExtension == "png" ||
                                        e_fileExtension == "jpg" ||
                                        e_fileExtension == "gif" ||
                                        e_fileExtension == "jpeg" ||
                                        e_fileExtension == "svg" ? (
                                        <Card.Img src={e.image} />
                                      ) : (
                                        <div className="threeD_custom_product">
                                          <model-viewer
                                            src={e.image}
                                            alt="VR Headset"
                                            auto-rotate
                                            camera-controls
                                            ar
                                            ios-src={e.image}
                                          ></model-viewer>
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                               
                                <div>
                                  <Card.Body>
                                    <div
                                      className="user-section d-flex justify-content-between"
                                      style={{ height: "85px" }}
                                    >
                                      <div>
                                        <td rowSpan="2" colSpan="2">
                                          <tr className="category_name">
                                            {e.category}
                                          </tr>
                                          <tr className="nft_name_user_profile">
                                            {e.name}
                                          </tr>
                                          <tr>
                                            <div className="erc_div mt-1">
                                              {e.create_mint_type}
                                            </div>
                                          </tr>
                                        </td>
                                      </div>
                                      <div>
                                        <div>
                                          <div className="nft_price">
                                          
                                        {
                                            (() => {
                                                if (e.mint_type === "2") {
                                                    return (
                                                        <img src={bharatToken} alt="img" className='img-fluid me-1' />
                                                    )
                                                }else{
                                                   return (
                                                            <img src={ethereum} alt="img" className='img-fluid me-1' />
                                                        )
                                                    {/* if (e.chain === "BNB") {
                                                        return (
                                                            <img src={bnb} alt="img" className='img-fluid me-1' />
                                                        )
                                                    } else {
                                                        return (
                                                            <img src={ethereum} alt="img" className='img-fluid me-1' />
                                                        )
                                                    } */}
                                                }
                                                
                                            })()
                                        }
                                        <strong>{e.price}</strong><br></br>
                                    
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </div>
                                </Link>
                              </Card>
                            </Col>
                          );
                        })
                      ) : (
                        <Row className="mt-4">
                          <Col lg={12} md={12}>
                            <div className="filter_data_card text-center py-5">
                              <p>No NFT's Available</p>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Row>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          }
        </Container>
      </section>
      <Footer />
      <Modal
                show={showProfile}
                onHide={handleCloseProfile}
                className="fullProfile_modal"
            >
                <Modal.Body>
                    <img src={collectionDetail.logo} className="img-fluid"></img>
                </Modal.Body>

            </Modal>


            {/* // ===========following modal start========== */}
      <Modal show={showOwner} onHide={handleCloseOwner} className="follower_profile_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Owner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="followers_modal">
                        {
                            collectionDetail.totalOwner_list ?

                            collectionDetail.totalOwner_list.map((e) => {

                                    return (

                                        <div className="followers_list" onClick={() => { handleClick(e.id) }}>
                                            <div className="follower_users">
                                                <div className="follower_img">
                                                    <img src={e.profileImage} alt="follower_img" className='img-fluid' />
                                                </div>
                                                <div className="follower_details">
                                                    <div className="follower_profile">
                                                        <div className="follower_name">{e.name ? e.name : "Unname"}</div>
                                                        <div className="follower_address">{e.address.slice(0, 4)}....{e.address.slice(-4, e.address.length)}</div>
                                                    </div>
                                                    <div className="follower_items">{e.totalItem} items</div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })
                                :
                                <div className='no_followers'>
                                    <h5>No Following</h5>
                                </div>
                        }



                    </div>

                </Modal.Body>

            </Modal>
            {/* // ===========following modal end========== */}
    </div>
  );
}

export default SingleCollection;
