import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Card } from 'react-bootstrap'
import { FaHourglassStart, FaTag } from 'react-icons/fa'
import ProductCard from './ProductCard'
import axios from 'axios';
import $ from 'jquery'
import { ImSpinner7 } from 'react-icons/im'

var BASE_URL = process.env.REACT_APP_BASE_URL


function Trending() {
    const [NftTrending, setTrending] = useState([]);
    const [image4, setImage4] = useState("")
    const [filteredData, setFilteredData] = useState([])
    const [show, setShow] = useState(true)
    const [load, setLoading] = useState(true)

    console.log("load", load)
    async function trending() {
        await axios.get(`${BASE_URL}/getTrending`)
            .then((data) => {
                if (data.data.result) {
                    console.log("loader1",data)
                    setTrending(data.data.result)
                    setLoading(false)
                }else{
                    console.log("loader2    ",data)
                    setTimeout(()=>{
                        setLoading(false) 
                    },1000)
                }


            }).catch(err => {
                console.log("tending err", err)
            })

    }

    useEffect(() => {
        $('.collection-btn').on('click', function () {
            $('.collection-btn').removeClass('activeBtn');
            $(this).addClass('activeBtn');
        });
    })

    useEffect(() => {
        setTimeout(()=>{
            setLoading(false) 
        },1000)
        trending()
    }, [])

    setTimeout(() => {
        trending()
    }, 60000)


    let timedAuction = NftTrending.filter(e => e.mint_type === "2")
    let fixedPrice = NftTrending.filter(e => e.mint_type === "1")

    const handleTimeAuction = () => {
        setFilteredData(timedAuction)
        setShow(false)
    }

    const handleFixedPrice = () => {
        setFilteredData(fixedPrice)
        setShow(false)
    }


    return (
        <section className='trending-section'>
            <Container>
                <Row>
                    <Col sm={2}>
                        <h4 className='collection-title'>Trending</h4>
                    </Col>
                    <Col sm={10} className='d-flex justify-content-end align-items-center'>
                        <div>
                            <Button size="sm" variant="outline-primary" className='collection-btn me-2 trending_btn' onClick={handleTimeAuction}><FaHourglassStart /> Timed Auctions</Button>
                            {/* <Button size="sm" variant="outline-primary" className='collection-btn me-2 trending_btn'><FaDoorOpen /> Open for bids</Button> */}
                            <Button size="sm" variant="outline-primary" className='collection-btn trending_btn' onClick={handleFixedPrice}><FaTag /> &nbsp;Fixed Price </Button>
                        </div>
                    </Col>
                </Row>
                <Row>

                    {show ?
                        (
                            load ?
                                <div className='loader_spiner_div  '>
                                    {/* <ImSpinner7 className='spin_icon' /> */}
                                </div>
                                :
                                (NftTrending.length === 0) ?
                                    <Row className='mt-4'>
                                        <Col lg={12} md={12}>
                                            <div className='filter_data_card text-center py-5'>
                                                <p>No NFT's Available</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    :

                                    NftTrending.map((e, index) => {

                                        if (index < 4) {
                                            return (
                                                <Col lg={3} md={6} key={e.id}>
                                                    <ProductCard
                                                        price={e.price}
                                                        image={e.image}
                                                        userFullName={e.userFullName}
                                                        name={e.name}
                                                        username={e.username}
                                                        id={e.id}
                                                        category={e.category}
                                                        userProfile={e.userProfile}
                                                        mint_type={e.mint_type}
                                                        auction_left_date={e.auction_left_date}
                                                        chain={e.chain}
                                                        preview_image={e.preview_image}
                                                        create_mint_type={e.create_mint_type}
                                                        aucation_date={e.aucation_date}
                                                    />
                                                </Col>
                                            )
                                        }


                                    })
                        )
                        :
                        (
                            (filteredData.length === 0) ?
                                <Row className='mt-4'>
                                    <Col lg={12} md={12}>
                                        <div className='filter_data_card text-center py-5'>
                                            <p>No NFT's Available</p>
                                        </div>
                                    </Col>
                                </Row>

                                :
                                filteredData.map((e) => {
                                    return (
                                        <Col lg={3} md={6} key={e.id}>
                                            <ProductCard
                                                price={e.price}
                                                image={e.image}
                                                userFullName={e.userFullName}
                                                name={e.name}
                                                username={e.username}
                                                id={e.id}
                                                category={e.category}
                                                userProfile={e.userProfile}
                                                mint_type={e.mint_type}
                                                auction_left_date={e.auction_left_date}
                                                chain={e.chain}
                                                preview_image={e.preview_image}
                                                create_mint_type={e.create_mint_type}
                                                aucation_date={e.aucation_date}
                                            />
                                        </Col>
                                    )
                                })

                        )
                    }

                </Row>

            </Container>


        </section>
    )
}

export default Trending