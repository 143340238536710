import React, {useState,useEffect} from 'react'
import { useParams, useLocation } from "react-router-dom";
import Footer from '../Footer'
import Header from '../Header'
import { Container, Row, Col } from 'react-bootstrap'
import { Link,useNavigate } from 'react-router-dom'
import {FaEllipsisV} from 'react-icons/fa'
import {AiTwotoneEdit} from 'react-icons/ai'
import {TbCurrencyDollar} from 'react-icons/tb'
import $ from 'jquery';
import axios from 'axios';
import { ImSpinner7 } from 'react-icons/im'
import bannerImageDemo from './../../assets/img/no_coverImage.jpeg'
import { FaArrowLeft } from 'react-icons/fa'
var BASE_URL = process.env.REACT_APP_BASE_URL
var loginUserAdd = sessionStorage.getItem("loginUserAdd");
var user = JSON.parse(sessionStorage.getItem("user"))
function Collections() {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams();
    // location.hash = 21
    console.log("111111111111111111111111xxxxxxxxxxxx",location);
    const [showDropdown, setShowDropdown] = useState(false)
    const [allCollections, setAllCollectionData] = useState("")
    const [show, setShow] = useState(true)
    const [load, setLoading] = useState(true)

    const handleShow = ()=>{
        setShowDropdown(!showDropdown) 
    }

 async function getCollectionList() {
        let newData = {"user_id":user.id}
        var getCollectionData = await axios.post(`${BASE_URL}/getUserCollection`, newData)
        if (getCollectionData.data.status === 200) {
            setAllCollectionData(getCollectionData.data.result)
            setLoading(false)
          }else{
            setTimeout(()=>{
                setLoading(false) 
            },2000)
          }
        }

    useEffect(() => {
        setTimeout(()=>{
            setLoading(false) 
        },2000)
        getCollectionList()
    }, [])

    console.log("allCollectionssssssssssss",allCollections)
    return (
        <div>
            <Header />
            <section className="collection_div">
                <Container>
                    <Row>
                        <Col lg={12}>
                        {/* <Link to='/' className='GoBack_btn fw-bold'><FaArrowLeft className='back-icon' />  Go Back</Link> */}
                            <button className='GoBack_btn fw-bold' onClick={() => navigate(-1)}  style={{backgroundColor:"transparent"}}><FaArrowLeft className='back-icon' />  Go Back</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="collection_content">
                                <div className="collection_heading">My Collections</div>
                                <div className="collection_para">Create, manage, and share collections of distinctive NFTs for sale.</div>
                                <div className="collection_btn">
                                    <Link to="/collection/create" className='create_collection_btn'>Create a collection</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                    {
                        load ? 
                        <div className='loader_spiner_div  '>
                                    {/* <ImSpinner7 className='spin_icon' /> */}
                                </div>
                                :
                        
                        allCollections?allCollections.map((e)=>{
                          
                            var collectionDetails = `/collection/${e.url_name}`
                            var collectionEdit = `/collection/${e.url_name}/edit`
                       console.log("bennerrrrrrrr",e.bannerImage)
                            return( <Col lg={3} md={6}>
                          
                          <div className="creators_collection_card">
                          <Link to={collectionDetails}>
                               <div className="creators_collection">
                                   <div className="banner_img">
                                       <img src={e.bannerImage} alt="banner_img" className='img-fluid' />
                                   </div>
                                   <div className="creator_bottom_collection">
                                       <div className="logo_img">
                                           <img src={e.logo} alt="logo_img" className='img-fluid' />
                                       </div>
                                       <div className="collection_name">{e.name}</div>
                                   </div>
                               </div>
                                  </Link>
                               <div className="creators_dropdown">
                                   <div className="creators_icon" onClick={handleShow}>
                                       <FaEllipsisV/>
                                   </div>
                                   {
                                       showDropdown ? 
                                       <div className="creators_dropdown_menu">
                                       <ul>
                                           <li>
                                               <Link to={collectionEdit} className='creators_menu'>
                                                   <div className="creators_svg"><AiTwotoneEdit/></div>
                                                   <div className="creators_menu_name">Edit</div>
                                               </Link>
                                           </li>
                                           {/* <li>
                                               <a href="#" className='creators_menu'>
                                                   <div className="creators_svg"><TbCurrencyDollar/></div>
                                                   <div className="creators_menu_name">Creator earning</div>
                                               </a>
                                           </li> */}
                                       </ul>
                                   </div>
                                   :
                                   ""
                                   }
                                   
                               </div>
                            
                           </div>
                         
                       </Col>
                       )
                           
                           })
                           :
                           ""
                    }
                        
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}

export default Collections
