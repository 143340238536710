import React, { useState, useEffect, useRef }  from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
var BASE_URL = process.env.REACT_APP_BASE_URL


function About() {
  var [aboutUs, setAboutUs] = useState("");
 
  useEffect(() => {
    async function check() {
      var about_us = await axios.get(BASE_URL + '/about_us');

      setAboutUs(about_us.data.result)
    }
  
    check()
  }, [])

  const about = useRef(null);

  useEffect(() => {
    about.current.innerHTML = `${aboutUs.description}`;
  }, [aboutUs]);

  return (
    <div>
        <Header/>
        <section className='min-hv'>
       <Container>
        <Row>
        <h2 className='text-center mb-3'>About Us</h2>
          <Col lg={12}>
          <div ref={about} ></div>
            
        </Col>
        </Row>
       </Container>
       </section>
        <Footer/>
    </div>
  )
}


export default About