import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button} from 'react-bootstrap'
import ProductCard from './ProductCard'
import { FaHourglassStart, FaTag} from 'react-icons/fa'
import axios from 'axios';
import Footer from './Footer'
import Header from './Header'
import { FaArrowLeft } from 'react-icons/fa'
import { Link} from 'react-router-dom'
import ReactPaginate from 'react-paginate';
// import { ImSpinner7 } from 'react-icons/im'
var BASE_URL = process.env.REACT_APP_BASE_URL

function AllNFT() {
    const [NftExplore, setExplore] = useState([]);
    const [filteredData, setFilteredData] = useState([])
    const [show, setShow] = useState(true)
    const [load, setLoading] = useState(true)
    
    async function explore() {
        await axios.get(`${BASE_URL}/getAllNFT`).then(async (data) => {
           
            if (data.data.result) {
                setExplore(data.data.result)
                setLoading(false)
            }else{
                console.log("loader2")
                    setTimeout(()=>{
                        setLoading(false) 
                    },1000)
            }
        })
    }

    useEffect(() => {
        setTimeout(()=>{
            setLoading(false) 
        },1000)
        explore()
    }, [show])

    setTimeout(() => {
        explore();
    }, 60000);
   
    let timedAuction = NftExplore.filter(e => e.mint_type === "2")
    let fixedPrice = NftExplore.filter(e => e.mint_type === "1")
    

    const handleTimeAuction = () => {
        setFilteredData(timedAuction)
        setShow(false)
    }

    const handleFixedPrice = () => {
        setFilteredData(fixedPrice)
        setShow(false)
    }

  
    // =============all nft pagination start===============
    const [currentItemsNFT, setCurrentItemsNFT] = useState(null);
    const [pageCountNFT, setPageCountNFT] = useState(0);

    const [itemOffsetNFT, setItemOffsetNFT] = useState(0);
    const itemsPerPageNFT = 12
    useEffect(() => {

        const endOffsetNFT = itemOffsetNFT + itemsPerPageNFT;

        setCurrentItemsNFT(NftExplore.slice(itemOffsetNFT, endOffsetNFT));
        setPageCountNFT(Math.ceil(NftExplore.length / itemsPerPageNFT));
    }, [itemOffsetNFT, itemsPerPageNFT, NftExplore]);

    const handlePageClickNFT = (event) => {
        const newOffsetNFT = (event.selected * itemsPerPageNFT) % NftExplore.length;
        setItemOffsetNFT(newOffsetNFT);
        console.log("event.selected",event+1 )
    };
    
    // =============all nft pagination end===============
    // =============filter data nft pagination start===============
    const [currentItemsFilterNFT, setCurrentItemsFilterNFT] = useState([]);
    const [pageCountFilterNFT, setPageCountFilterNFT] = useState(0);

    const [itemOffsetFilterNFT, setItemOffsetFilterNFT] = useState(0);
    const itemsPerPageFilterNFT = 12
    useEffect(() => {

        const endOffsetFilterNFT = itemOffsetFilterNFT + itemsPerPageFilterNFT;
        
        setCurrentItemsFilterNFT(filteredData.slice(itemOffsetFilterNFT, endOffsetFilterNFT));


        setPageCountFilterNFT(Math.ceil(filteredData.length / itemsPerPageFilterNFT));
    }, [itemOffsetFilterNFT, itemsPerPageFilterNFT, filteredData]);

    const handlePageClickFilterNFT = (event) => {
       
        const newOffsetFilterNFT = (event.selected * itemsPerPageFilterNFT) % filteredData.length;
        setItemOffsetFilterNFT(newOffsetFilterNFT);
        
    };
    // =============filter data nft pagination end===============
    
    
    return (
        <div>
            <Header />
            <section className='explore-section all_nft' style={{ backgroundColor: "transparent", minHeight: "90vh" }}>
                <Container>

                    <Row>
                        <Col lg={2}>
                            <Link to='/' className='GoBack_btn fw-bold'><FaArrowLeft className='back-icon' />  Go Back</Link>
                        </Col>
                        <Col sm={10} className='d-flex justify-content-end align-items-center'>
                        <div>
                            <Button size="sm" variant="outline-primary" className='collection-btn me-2 trending_btn' onClick={handleTimeAuction}><FaHourglassStart /> Timed Auctions</Button>
                            <Button size="sm" variant="outline-primary" className='collection-btn trending_btn' onClick={handleFixedPrice}><FaTag /> &nbsp;Fixed Price </Button>
                        </div>
                    </Col>
                    </Row>
                    <Row className='all_nft_row'>
                        { 
                         show ? 

                         load ?
                         <div className='loader_spiner'>
                             {/* <ImSpinner7 className='spin_icon' /> */}
                         </div>
                         :
                            (NftExplore.length === 0) ?
                                <Row className='mt-4'>
                                    <Col lg={12} md={12}>
                                        <div className='filter_data_card text-center py-5'>
                                            <p>No NFT's Available</p>
                                        </div>
                                    </Col>
                                </Row>

                                :

                                currentItemsNFT.map((e, index) => {
                                    return (
                                        <Col lg={3} md={6} key={index}>
                                            <ProductCard
                                                price={e.price}
                                                image={e.image}
                                                userFullName={e.userFullName}
                                                name={e.name}
                                                username={e.username}
                                                id={e.id}
                                                category={e.category}
                                                userProfile={e.userProfile}
                                                auction_left_date={e.auction_left_date}
                                                mint_type={e.mint_type}
                                                chain={e.chain}
                                                preview_image={e.preview_image}
                                                create_mint_type={e.create_mint_type}
                                                aucation_date = {e.aucation_date}
                                            />
                                        </Col>
                                    )
                                })

                                :

                                (filteredData.length === 0) ?
                                <Row className='mt-4'>
                                    <Col lg={12} md={12}>
                                        <div className='filter_data_card text-center py-5'>
                                            <p>No NFT's Available</p>
                                        </div>
                                    </Col>
                                </Row>

                                :

                                currentItemsFilterNFT.map((e, index) => {
                                    return (
                                        <Col lg={3} md={6} key={index}>
                                            <ProductCard
                                                price={e.price}
                                                image={e.image}
                                                userFullName={e.userFullName}
                                                name={e.name}
                                                username={e.username}
                                                id={e.id}
                                                category={e.category}
                                                userProfile={e.userProfile}
                                                auction_left_date={e.auction_left_date}
                                                mint_type={e.mint_type}
                                                chain={e.chain}
                                                preview_image={e.preview_image}
                                                create_mint_type={e.create_mint_type}
                                                aucation_date = {e.aucation_date}
                                            />
                                        </Col>
                                    )
                                })

                        }
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {
                                 !show ? 
                                 (() => {

                                    if (filteredData.length > 12) {
                                        return (
                                            <div className="paginate mt-4">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel=" >>"
                                                    onPageChange={handlePageClickFilterNFT}
                                                    pageRangeDisplayed={3}
                                                    marginPagesDisplayed={2}
                                                    pageCount={pageCountFilterNFT}
                                                    previousLabel="<< "
                                                    containerClassName='pagination justify-content-end'
                                                    pageClassName='page-item'
                                                    pageLinkClassName='page-link'
                                                    previousClassName='page-item'
                                                    previousLinkClassName='page-link'
                                                    nextClassName='page-item'
                                                    nextLinkClassName='page-link'
                                                    breakClassName='page-item'
                                                    breakLinkClassName='page-link'
                                                    activeClassName='active'

                                                />
                                            </div>
                                        )
                                    }
                                })()
                                :
                                (() => {

                                    if (NftExplore.length > 12) {
                                        return (
                                            <div className="paginate mt-4">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel=" >>"
                                                    onPageChange={handlePageClickNFT}
                                                    pageRangeDisplayed={3}
                                                    marginPagesDisplayed={2}
                                                    pageCount={pageCountNFT}
                                                    previousLabel="<< "
                                                    containerClassName='pagination justify-content-end'
                                                    pageClassName='page-item'
                                                    pageLinkClassName='page-link'
                                                    previousClassName='page-item'
                                                    previousLinkClassName='page-link'
                                                    nextClassName='page-item'
                                                    nextLinkClassName='page-link'
                                                    breakClassName='page-item'
                                                    breakLinkClassName='page-link'
                                                    activeClassName='active'

                                                />
                                            </div>
                                        )
                                    }
                                })()
                            }
                            

                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}

export default AllNFT