import React, { useState, useEffect, useRef } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
var BASE_URL = process.env.REACT_APP_BASE_URL


function Term() {
  const [terms, setTerms] = useState("");

  useEffect(() => {
    async function check() {
      var terms = await axios.get(BASE_URL + '/terms_conditions');

      setTerms(terms.data.result)
    }

    check()
  }, [])

  const term = useRef(null);

  useEffect(() => {
    term.current.innerHTML = `${terms.description}`;
  }, [terms]);


  return (
    <div>
      <Header />

      <section className='min-hv terms'>
        <Container>
          <Row>
            <h2 className='text-center mb-3' >Terms and Conditions</h2>
            <Col lg={12}>
              <div ref={term} ></div>
            </Col>

          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  )
}

export default Term