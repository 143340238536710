import React, { useState, useEffect, useRef } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
var BASE_URL = process.env.REACT_APP_BASE_URL


function Privacy() {

  const [privacy, setPrivacy] = useState("");

  useEffect(() => {
    async function check() {
      var privacy = await axios.get(BASE_URL + '/privacy_policy');

      setPrivacy(privacy.data.result)
    }

    check()
  }, [])

  const privacys = useRef(null);

  useEffect(() => {
    privacys.current.innerHTML = `${privacy.description}`;
  }, [privacy]);
  
  return (
    <div>
      <Header />

      <section className='min-hv privacy'>
        <Container>
          <Row>
            <h2 className='text-center mb-3'>Privacy Policy</h2>
            <Col lg={12}>
              <div ref={privacys} ></div>
            </Col>


          </Row>
        </Container>

      </section>

      <Footer />
    </div>
  )
}

export default Privacy