import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav, Form, FormControl } from 'react-bootstrap'
import logo from '../assets/img/bharat-token.png'
import { FaSearch, FaUserCircle, FaRegBell, FaUser, FaSignOutAlt } from 'react-icons/fa'
import { MdGridOn } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import bharatToken from '../assets/img/bharat-token-icon.png'

import { ERC721_CONTACT_ADDRESS, ERC721_Abi } from '../contract/erc721';
import { ERC1155_CONTACT_ADDRESS, ERC1155_Abi } from '../contract/erc1155';
import { MARKETPLACE_CONTACT_ADDRESS, MARKETPLACE_Abi } from '../contract/marketplace';
import { ERC20_CONTACT_ADDRESS, ERC20_Abi } from '../contract/erc20';

// import { CONTACT_ADDRESS, Abi } from '../contract/bhartnft';
import Web3 from "web3";
import axios from 'axios';
import $ from 'jquery'


var BASE_URL = process.env.REACT_APP_BASE_URL

const web3_Stake = new Web3(window.ethereum);

const utcStr = new Date().toUTCString();
console.log("utcStr",utcStr); // 👉️ "Sat, 15 Jan 2022 16:17:30 GMT"


function Header() {
  const [checkAddress, setcheckAddress] = useState("");
  const [eth_balance, setEthBalance] = useState("")
  const [logoutbtn, setLogoutbtn] = useState(false);
  const [search, setSearch] = useState("")
  const [data, setNftData] = useState([])
  const [userData, setUserData] = useState([])

  const [show, setShow] = useState(false);
  const [chain, setChainId] = useState("");
  const [chainNetwork, setChainNetwork] = useState("");
  const [count_notification, setCountNotification] = useState("");
  const [userAddressFourDegit, setUserAddFourDigit] = useState("");

  const ERC721ABiWthiCONTRACT = new web3_Stake.eth.Contract(ERC721_Abi, ERC721_CONTACT_ADDRESS);
  const ERC1155ABiWthiCONTRACT = new web3_Stake.eth.Contract(ERC1155_Abi, ERC1155_CONTACT_ADDRESS);
  const MarketplaceABiWthiCONTRACT = new web3_Stake.eth.Contract(MARKETPLACE_Abi, MARKETPLACE_CONTACT_ADDRESS);
  const ERC20ABiWthiCONTRACT = new web3_Stake.eth.Contract(ERC20_Abi, ERC20_CONTACT_ADDRESS);
  
  const [bharatTokenBalance, setBharatTokenBalance] = useState("");
  
  let navigate = useNavigate()
  const userDetail = JSON.parse(sessionStorage.getItem('user'))
  if (userDetail) {
    var user_id = userDetail.id;
  } else {
    var user_id = "";
  }
  async function openMetamask() {
    window.ethereum.enable().then(async (address) => {
      var loginUserAdd = address[0];
      sessionStorage.setItem("loginUserAdd", loginUserAdd);
      console.log("loginUserAddress1loginUserAddress1", loginUserAdd);
      var ethBalance = await (await web3_Stake.eth.getBalance(loginUserAdd))
      ethBalance = Web3.utils.fromWei(ethBalance, 'ether').slice(0, 6);
      console.log("ethBalance", ethBalance)
      if (ethBalance) {
        sessionStorage.setItem("ethBalance", ethBalance);
      }
      var chainId = window.ethereum.chainId;

      console.log("chainIdchainId", chainId)

      if (chainId === 0x4) {
        var chain = "ETH";
        setChainId(chain)
      } else if (chainId === 0x61) {
        var chain = "BNB";
        setChainId(chain)
      }
      if (chainId) {
        sessionStorage.setItem("chainNetwork", chain);
      }

      const formData = new FormData();
      formData.append("wallet_address", loginUserAdd);

      var signature = ""
      // var connect_user = await axios.post(`http://148.72.244.170:5001/user/connect`, {id:loginUserAdd, signature:signature})
      var connect_user = await axios.post(`${BASE_URL}/login`, formData)

      //  console.log("connect_user",connect_user.data.result)
      sessionStorage.setItem("user", JSON.stringify(connect_user.data.result));

      const userDetail = JSON.parse(sessionStorage.getItem('user'))
      window.location.reload()
    });
  }

  //   const networkChanged = (chainId) => {
  //     console.log("chainIdgghj", chainId);

  //     if (chainId == "4") {
  //         window.ethereum.enable().then((address) => {
  //             var loginUserAdd = address[0];
  //             sessionStorage.setItem("loginUserAdd", loginUserAdd);
  //         });

  //         window.location.reload();
  //     }
  // };


  useEffect(() => {
    const getethBalance = sessionStorage.getItem('ethBalance')

    // const userDetail = JSON.parse(sessionStorage.getItem('user'))

    // setAuthUserDetail(userDetail)
    if (getethBalance) {
      setEthBalance(getethBalance)
      setLogoutbtn(true)
    }
  }, [])


  useEffect(() => {
    // =============================== SHOW ADDRESS BY COOKIE ==============================================
    let checkAddress = sessionStorage.getItem('loginUserAdd')

    // setUserAddFourDigit(checkAddress.slice(38, checkAddress.length))
    setcheckAddress(checkAddress)

    var chainNetwork = sessionStorage.getItem('chainNetwork')
    setChainNetwork(chainNetwork)

  }, []);


  const countNotification = async () => {
    const res = await axios.get(BASE_URL + '/count_notification?id=' + user_id)

    setCountNotification(res.data.result.count_notifiation)
  }
  useEffect(() => {
    countNotification()
  }, [])


  async function logoutMetamask() {
    sessionStorage.removeItem("loginUserAdd")
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("loginUserAdd");
    navigate("/")
    window.location.reload()
  }

  async function walletBalanceUpdate() {
    let checkAddress = sessionStorage.getItem('loginUserAdd')
    if (checkAddress !== null && checkAddress !== "null") {
      var ethBalance = await (await web3_Stake.eth.getBalance(checkAddress))
      ethBalance = Web3.utils.fromWei(ethBalance, 'ether').slice(0, 6);
      if (ethBalance) {
        sessionStorage.setItem("ethBalance", ethBalance);
        setEthBalance(ethBalance)
      }
    }
  }

  async function tokenBalanceUpdate(){
    let checkAddress = sessionStorage.getItem('loginUserAdd')
    if (checkAddress !== null && checkAddress !== "null") {
      ERC20ABiWthiCONTRACT.methods.balanceOf(checkAddress)
        .call()
        .then(async function (tokenBalance) {
            var tokenBalance_bn = Web3.utils.fromWei(tokenBalance, 'ether')
            console.log('RahulllllllllltokenBalance_bn ', tokenBalance_bn);
            sessionStorage.setItem("tokenBalance", tokenBalance_bn);
            setBharatTokenBalance(tokenBalance_bn)
        }).catch((err) => {
            console.log('eror ', err);
        })
    }
  }

  async function addTokenMetamask() {
    let address = ERC20_CONTACT_ADDRESS;
    let symbol = "BHARAT";
    let decimals = 18;
    let image = "https://bharatnft.store/bharat_tokenIcon.png";
    const injectedProvider = (window).ethereum;
    // if (provider && account && window && injectedProvider) {
    if (address.toLowerCase() ) {
      const wasAdded = injectedProvider.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address,
                    symbol,
                    decimals,
                    image,
                },
            },
        });

        if (wasAdded) {
          console.log('Thanks for your interest!');
        } else {
          console.log('Your loss!');
        }
    }
        return true;
  }

  setTimeout(function () {
    walletBalanceUpdate();
    tokenBalanceUpdate();
  }, 3000);

  // async function updateNotifications() {
  //   var update_notification = await axios.get(BASE_URL + '/updateNotification?id=' + userDetail.id)
  //   navigate("/profile")
  //   window.location.reload()
  // }

  const onKeyUp = async (e) => {
    var query = e
    if (query.length === 0) {
      setNftData([])
      document.getElementById("search_box").style.display = "none"
    } else {

      const formData = new FormData();
      formData.append("word", query);

      var searchData = await axios.post(`${BASE_URL}/searchNFT`, formData)

      if (searchData.data.status === 200) {
        setNftData(searchData.data.result)
        document.getElementById("search_box").style.display = "block"
      }
      else {
        setNftData()
        document.getElementById("search_box").style.display = "block"
      }
    }
    console.log("data searched =====", searchData)
  }

  async function handleSearch(e) {
    navigate(`/prod_detail?id=${e.id}`)
    window.location.reload()
    window.scroll(0, 0)
  }

  async function handleSearchCreator(e) {
    navigate(`/User_profile?id=${e.id}`)
    window.location.reload()
    window.scroll(0, 0)
  }

  $(document).on('click', function (e) {
    $("#search_box").hide()
  })


  return (
    <div className='header'>
      <Navbar expand="lg" className='cus_navbar' variant="dark">
        <Container>
          <Link to='/'><img src={logo} alt="logo" className="logo" style={{ filter: "invert(1)" }} /></Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="header_right w-40">
              <div className="form-sec">
                <FaSearch />
                <FormControl
                  type="search"
                  placeholder="Search Items and Creators"
                  className="search-input me-2"
                  // onChange={(e) => setSearch(e.target.value)} 
                  onKeyUp={(e) => onKeyUp(e.target.value)}
                />

              </div>
              <div className='search_data_box' id='search_box'>

                {
                  ((data.item && data.item.length !== 0) || (data.creater && data.creater.length !== 0)) ?
                    <div>

                      {
                        (() => {
                          if (data.item && data.item.length !== 0) {
                            return (
                              <>
                                <div className="search_collections">
                                  <h6>ITEMS</h6>
                                </div>
                                {

                                  // (data.item && data.item.length !== 0) &&

                                  data.item.map((e) => {

                                    var fileExtension = e.image.split(/[#?]/)[0].split('.').pop().trim();
                                    return (
                                      <div className=' searched_data' onClick={() => handleSearch(e)} key={e.id}>
                                        <div className="preview_image">

                                          {(() => {
                                            if (e.image) {
                                              if (fileExtension === "mpeg" || fileExtension === "mp3") {
                                                return <img src={e.preview_image} alt="" className='img-fluid' style={{ width: "30px", height: "30px", borderRadius: "17%" }} />
                                              }
                                              if (fileExtension === "mp4") {
                                                return <img src="https://www.pngfind.com/pngs/m/57-576156_play-button-icon-transparent-video-ad-icon-hd.png" alt="" className='img-fluid' style={{ width: "30px", height: "30px", borderRadius: "17%" }} />
                                              }
                                              else {
                                                return fileExtension === "png" ||
                                                  fileExtension === "jpg" ||
                                                  fileExtension === "gif" ||
                                                  fileExtension === "jpeg" ||
                                                  fileExtension === "svg" ?
                                                  (
                                                    <img src={e.image} alt="featured" className='img-fluid' style={{ width: "30px", height: "30px", borderRadius: "17%" }} />
                                                  ) : (
                                                    <div className='threeD_custom_search'>
                                                      <model-viewer src={e.image} alt="VR Headset" auto-rotate camera-controls ar ios-src={e.image}>
                                                      </model-viewer>
                                                    </div>
                                                  );
                                              }

                                            }
                                          })()
                                          }

                                        </div>
                                        <div className='searched_userName' >{e.name}</div>
                                      </div>
                                    )
                                  })


                                }</>
                            )
                          }
                          else {
                            return (
                              <></>
                            )
                          }
                        })()
                      }

                      {
                        (() => {
                          if (data.creater && data.creater.length !== 0) {
                            return (
                              <>
                                <div className="search_collections">
                                  <h6>CREATORS</h6>
                                </div>
                                {
                                  // (data.creater && data.creater.length !== 0) &&
                                  data.creater.map((e) => {
                                    // console.log("eeee", e)
                                    return (
                                      <div className=' searched_data' onClick={() => handleSearchCreator(e)}>
                                        <div className="preview_image">
                                          <img src={e.userProfile} alt="" className='img-fluid' style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
                                        </div>
                                        <div className='' >{e.name}</div>
                                      </div>
                                    )
                                  })

                                }</>
                            )
                          }
                          else {
                            return (
                              <></>
                            )
                          }
                        })()
                      }
                    </div>
                    :
                    <div className=''>
                      <h5 className='text-center py-4' style={{ color: "#9f92fb" }}>No Results Found</h5>
                    </div>
                }
              </div>
            </div>
            <Nav>

              {logoutbtn ?

                <div className='header_login'>

                  <div className='header_wallet_adddress'>
                    <span> {checkAddress.slice(0, 4)}...{checkAddress.slice(-4, checkAddress.length)}</span>
                  </div>
                  <div> <Link type="button" className="btn btn-outline-secondary " to='/create'>Create</Link></div>
                  <div><button type="button" className="btn btn-outline-secondary" >{bharatTokenBalance?bharatTokenBalance:0} BHARAT</button></div>
                  {/* <div><button type="button" className="btn btn-outline-secondary" onClick={addTokenMetamask} >Import</button></div> */}
                  <div><button type="button" className="btn btn-outline-secondary" >{eth_balance} ETH</button></div>
                  {/* <div onClick={updateNotifications}>
                 <Noty width={"30px"} color={"#122C34"} count={count_notification} />
                 <FaRegBell count={count_notification} className="notification_bell"  />
               </div> */}
                  {/* <div><Link type="button" className='user-icon' to='/profile'> <FaUserCircle /></Link></div> */}
                  <div className='user_profile'>
                    <Link type="button" className='user-icon' to='/profile'>
                      <img src={userDetail ? userDetail.profileImage : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} alt="" className="img-fluid profile_nft_img" />
                    </Link>
                    <div className="user_profile_dropdown">
                      <ul>
                        <li>
                          <div onClick={addTokenMetamask} className="user_profile_menu">
                            <div className="user_profile_icon">
                              <img src={bharatToken} alt="" className='img-fluid' style={{width:"30px", marginLeft:"-5px"}} />
                            </div>
                            <div className="user_profile_text" style={{ marginLeft:"-7px"}}>Import Bharat</div>
                          </div>
                        </li>
                        <li>
                          <Link to='/profile' className="user_profile_menu">
                            <div className="user_profile_icon"><FaUser /></div>
                            <div className="user_profile_text">Profile</div>
                          </Link>
                        </li>
                        <li>
                          <Link to="/collections" className="user_profile_menu">
                            <div className="user_profile_icon"><MdGridOn /></div>
                            <div className="user_profile_text">My Collections</div>
                          </Link>
                        </li>
                        <li>
                          <div className="user_profile_menu" onClick={logoutMetamask}>
                            <div className="user_profile_icon"><FaSignOutAlt /></div>
                            <div className="user_profile_text">Logout</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* <img src={userProfile} alt=""className="img-fluid profile_nft_img" /> */}


                </div>
                :
                <div>
                  <button type="button" className="btn btn-outline-secondary connect_meta_btn" style={{ "backgroundColor": "#4b50e6", "color": " white" }} >Connect with Slice</button>
                  <button type="button" className="btn btn-outline-secondary connect_meta_btn" onClick={openMetamask} style={{ "backgroundColor": "#4b50e6", "color": " white" }}>Connect with Metamask</button>
                </div>
                }
            </Nav>

            <div className='login-btn'>




              {/*<Link type="button" class="btn btn-outline-secondary" to=''>Ethereum</Link>*/}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header