import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'
import { FaImages, FaAngleDown } from 'react-icons/fa'
import { ImSpinner7 } from 'react-icons/im'
import fixedPrice from '../assets/img/fixed-price.png'
import limitedAuction from '../assets/img/limited-auction.png'
import Web3 from "web3";
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import Select from 'react-select';
import { ERC721_CONTACT_ADDRESS, ERC721_Abi } from '../contract/erc721';
import { ERC1155_CONTACT_ADDRESS, ERC1155_Abi } from '../contract/erc1155';
import { MARKETPLACE_CONTACT_ADDRESS, MARKETPLACE_Abi } from '../contract/marketplace';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import axios from 'axios';
import {BsChevronDown} from 'react-icons/bs'

require('dotenv').config()


const web3_Stake = new Web3(window.ethereum);

const ERC721ABiWthiCONTRACT = new web3_Stake.eth.Contract(ERC721_Abi, ERC721_CONTACT_ADDRESS);
const ERC1155ABiWthiCONTRACT = new web3_Stake.eth.Contract(ERC1155_Abi, ERC1155_CONTACT_ADDRESS);
const MarketplaceABiWthiCONTRACT = new web3_Stake.eth.Contract(MARKETPLACE_Abi, MARKETPLACE_CONTACT_ADDRESS);


var BASE_URL = process.env.REACT_APP_BASE_URL



function CreatePost() {

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [chain, setChain] = useState('');
    const [category, setCategory] = useState('');
    const [image, setImageType] = useState('');
    const [royalty, setRoyalty] = useState('');
    const [priceInput, setPriceInput] = useState(false)
    const [price, setPrice] = useState();
    const [auctionInput, setAuctionInput] = useState(false)
    const [Unlockable, setUnlockable] = useState("");
    const [imageprev, setImageprev] = useState("");
    const [previewImage, setPreviewImageType] = useState('');
    const [audioPreviewImage, setAudioPreviewImage] = useState('');
    const [isActivePrice, setIsActivePrice] = useState(false)
    const [isActiveAuction, setIsActiveAuction] = useState(false)
    const [loading, setLoading] = useState(false)
    const [mint_type, setMintType] = useState('');
    const [auctionDate, setAuctionDate] = useState('');
    const userDetail = JSON.parse(sessionStorage.getItem('user'))

    const [platformFees, setPlatformFees] = useState('');

    const [fixedvalid, setfixedDiv] = useState('');
    const [auctionvalid, setAuctionDiv] = useState('');
    const [collection_id, setCollection] = useState('');
     // ====collection search data start=====
    const [getAllCollection, setGetAllCollection] = useState([]);
    const [searchCollection, setSearchCollection] = useState("")
    const [showSearch, setShowSearch] = useState(false)
    const [collectionId, setCollectionID] = useState(''); 
    const [toggleSearchBox, settoggleSearchBox] = useState(false)

    const handleSearch = () => {
        settoggleSearchBox(false)
        console.log('clicked Event');
        setShowSearch(!showSearch)

    }

    const handleSelectItem = (name, logoImg, id) => {

        if (name) {
            setSearchCollection(name)
            setCollectionID(id)
            document.getElementById("collection_img_id").style.display = "block"
            document.getElementById("collection_img_id").src = logoImg
        }
        setShowSearch(false)
    }

    const handleCollectionChange = (e) => {
        // console.log(e.target.value);
        settoggleSearchBox(true)
        setSearchCollection(e.target.value)
        if (e.target.value.length === 0) {
            document.getElementById("collection_img_id").style.display = "none"
        }
    }
    console.log("searchCollectionAAAAAAAAAAAAAAAAAAAAAAAAAAAA",searchCollection)
   
    // ====collection search data ends=====
   
    let navigate = useNavigate()

    function toTimestamp(strDate) {
        var datum = Date.parse(strDate);
        return datum / 1000;
    }

    const startDate = new Date()

    const [currentDate, setCurrentDate] = useState("")


    const auction_date = Moment(currentDate).format('DD MMMM YYYY h:mm A')
    const aucation_timestamp = toTimestamp(auction_date)


    const handleChange = (date) => {
        setCurrentDate(date)
    }



    var loginUserAdd = sessionStorage.getItem("loginUserAdd");
    var user = JSON.parse(sessionStorage.getItem("user"))
    console.log("user detail===========", user)
    useEffect(() => {
        const startDateERC721 = new Date()
        $("#royltycheck").hide();
        $("#namecheck").hide();
        $("#descriptioncheck").hide();
        $("#categorycheck").hide();
        $("#uploadImage").hide();
        $("#uploadPreviewImage1").hide();
        $("#fixedAuctionValid").hide();
        $("#auctionDateAndTime").hide();
        $("#pricecheckFixed").hide();
        $("#priceValidation").hide();
        $("#timeValidation").hide();
        $("#uploadImageType").hide();
        $("#checkVideoValidation").hide();
        $('#uploadPreviewImageType1').hide();
        $('#validCollection').hide();

        const userDetail = JSON.parse(sessionStorage.getItem('user'))
        if (userDetail == "" || userDetail == null) {
            navigate("/")
        }
    })

    // MarketplaceABiWthiCONTRACT.methods.platformFees()
    //     .call()
    //     .then(async function (platformFees) {
    //         console.log('platformFeesplatformFees ', platformFees);
    //         setPlatformFees(platformFees)
    //     }).catch((err) => {
    //         console.log('eror ', err);
    //     })

    $(".validate").focus(function () {
        $("#royltycheck").hide();
        $("#namecheck").hide();
        $("#descriptioncheck").hide();
        $("#categorycheck").hide();
        $("#uploadImage").hide();
        $("#uploadPreviewImage1").hide();
        $("#fixedAuctionValid").hide();
        $("#auctionDateAndTime").hide();
        $("#pricecheckFixed").hide();
        $("#priceValidation").hide();
        $("#timeValidation").hide();
        $("#uploadImageType").hide();
        $("#checkVideoValidation").hide();
        $('#uploadPreviewImageType1').hide();
        $('#validCollection').hide();

    })

    async function getcollection() {
        const formData = new FormData();
        formData.append("user_id", user.id);
        var getCollectionData = await axios.post(`${BASE_URL}/getUserCollection`, formData)
        if (getCollectionData.data.status === 200) {
            setGetAllCollection(getCollectionData.data.result)
        }
    }

    useEffect(() => {
        getcollection()
    }, [])
    console.log("getAllCollectionnnnnnnnnnnnnnnnnnnnn", getAllCollection)
    //  ================ Create NFT ======================
    const createnft = async () => {

        let currentDateValid = false;
        let currentTimeValid = false;
        let currentpriceValid = false;

        var type = 721;

        var date = Date.now()
        console.log("date", date)

        var imagetype = image.type

        console.log("imagetype", imagetype)

        if (!image) {
            $("#uploadImage").show();
            $('#checkVideoValidation').hide();
            $('#uploadImageType').hide();
        }

        if (image.type == "image/webp") {
            $('#uploadImageType').show();
        }

        if (image.type == "audio/mpeg") {


            if (!previewImage) {
                $("#uploadPreviewImage1").show();
                $('#uploadPreviewImageType1').hide();
            }
        }



        if (!royalty) {
            $("#royltycheck").show();
        }

        if (!name) {
            $("#namecheck").show();
        } else if (/^\s+/g.test(name)) {
            $("#namecheck").show();
            return
        }

        if (!description) {
            $("#descriptioncheck").show();
        } else if (/^\s+/g.test(description)) {
            $("#descriptioncheck").show();
            return
        }


        if (!category) {
            $("#categorycheck").show();
        }

        // if (!collection) {
        //     $("#validCollection").show();
        // }

        if (!fixedvalid && !auctionvalid) {
            $("#fixedAuctionValid").show();
        }

        if (auctionvalid) {

            const startDateERC721 = new Date()
            const todayDate = Moment(startDateERC721).format('DD MMMM YYYY h:mm A')
            const todayTimestamp = toTimestamp(todayDate)

            const getAuctionDate = Moment(currentDate).format('DD MMMM YYYY h:mm A')
            const getAuctionTimestamp = toTimestamp(getAuctionDate)
            if (parseFloat(getAuctionTimestamp) < parseFloat(todayTimestamp)) {
                $("#timeValidation").show();
                currentTimeValid = false;
            } else {
                currentTimeValid = true;
            }

            if (!currentDate) {
                $("#auctionDateAndTime").show();
                currentDateValid = false;
            } else {
                currentDateValid = true;
            }
        }

        if (!price) {
            $("#pricecheckFixed").show();
            currentpriceValid = false;
        } else if (price <= 0) {
            $("#priceValidation").show();
            currentpriceValid = false;

        } else {
            currentpriceValid = true;
        }

        if (mint_type == 2) {
            if (currentTimeValid === false || currentDateValid === false) {
                return;
            }
        }

        if (currentpriceValid === false) {
            return;
        }


        if (image && royalty && name && description && category) {
            console.log("rahulUng", image)
            // var platformFees_bn = Web3.utils.toBN(platformFees)
            const formData = new FormData();

            if (mint_type == 1) {
                var tokens = Web3.utils.toWei(price.toString(), 'ether')
                var bntokens = Web3.utils.toBN(tokens)

                console.log("tokenstokens", tokens)
                console.log("bntokensbntokens", bntokens)
                // setLoading(true)
                document.getElementById("overlay").style.display = "block";

                const formData = new FormData();
                formData.append("image", image, image.name);
                formData.append("name", name);
                formData.append("description", description);
                formData.append("type", type);

                var create_image_json = await axios.post(`${BASE_URL}/create_image_json`, formData)
                console.log("create_image_json", create_image_json);

                if (create_image_json.status == 200) {
                    var token_uri = create_image_json.data.result.json_url;
                    var image_json_url = create_image_json.data.result.json;

                    var contract_royalty = royalty * 100;


                    ERC721ABiWthiCONTRACT.methods
                        .mintNft(token_uri, loginUserAdd, contract_royalty)
                        .send({
                            from: loginUserAdd,
                            // gas: 2500000
                            // gas: gasPrice,
                            // gas: gasPrice, 
                            // gasPrice:40000000000
                            // gas: contract_royalty * 100,
                            // gas: estimatedGas 
                        })
                        .on('error', function (error) {
                            console.log("error", error)
                            // toast.error('NFT mint failed !', {
                            //     position: "top-center",
                            //     theme: "colored",
                            //     autoClose: 1000,
                            //     hideProgressBar: false,
                            //     closeOnClick: true,
                            //     pauseOnHover: true,
                            //     draggable: true,
                            //     progress: undefined,
                            // });
                            // document.getElementById("overlay").style.display = "none";

                        })
                        // .estimateGas({from: loginUserAdd})
                        .then(async function (info) {
                            console.log('success ', info);
                            var token_id = info.events.Transfer.returnValues.tokenId

                            console.log('token_idtoken_id ', token_id);

                            ERC721ABiWthiCONTRACT.methods.approve(MARKETPLACE_CONTACT_ADDRESS, token_id)
                                .send({
                                    from: loginUserAdd,
                                    // gas: 3000000
                                }).on('error', function (error) {
                                    console.log("error", error)
                                    // toast.error('NFT mint failed !', {
                                    //     position: "top-center",
                                    //     theme: "colored",
                                    //     autoClose: 2000,
                                    //     hideProgressBar: false,
                                    //     closeOnClick: true,
                                    //     pauseOnHover: true,
                                    //     draggable: true,
                                    //     progress: undefined,
                                    // });
                                    // document.getElementById("overlay").style.display = "none";

                                }).then(async function (info) {
                                    console.log('success ', info);

                                    MarketplaceABiWthiCONTRACT.methods.createMarketItem721(ERC721_CONTACT_ADDRESS,token_id, bntokens)
                                        .send({
                                            from: loginUserAdd,
                                            // gas: 3000000,
                                            // value: platformFees_bn

                                        }).on('error', function (error) {
                                            console.log("error", error)
                                            // toast.error('NFT mint failed !', {
                                            //     position: "top-center",
                                            //     theme: "colored",
                                            //     autoClose: 2000,
                                            //     hideProgressBar: false,
                                            //     closeOnClick: true,
                                            //     pauseOnHover: true,
                                            //     draggable: true,
                                            //     progress: undefined,
                                            // });
                                            // document.getElementById("overlay").style.display = "none";

                                        }).then(async function (info) {
                                            console.log('success ', info);
                                            // console.log('success ', info.events.TokenListed721);
                                            // console.log('success ', info.events.TokenListed721.returnValues);
                                            // console.log('success_returnValues ', info.events.TokenListed721.returnValues[0]);

                                            var transaction_id = info.transactionHash
                                            // var item_id = info.events.TokenListed721.returnValues[0]
                                            var item_id = 0

                                            const startDateERC721 = new Date()
                                            const auctionDate = Moment(startDateERC721).format('DD MMMM YYYY h:mm A')
                                            const aucationTimestamp = toTimestamp(auctionDate)
                                            console.log("formatDate", auctionDate)

                                            formData.append("image", image, image.name);
                                            formData.append("preview_image", previewImage);
                                            formData.append("name", name);
                                            formData.append("description", description);
                                            formData.append("collection_id", collectionId);
                                            formData.append("chain", 'ETH');
                                            formData.append("category", category);
                                            formData.append("royalty", royalty);
                                            formData.append("price", price);
                                            formData.append("user_id", loginUserAdd);
                                            formData.append("mint_type", mint_type);
                                            formData.append("auction_date", auctionDate);
                                            formData.append("token_id", token_id);
                                            formData.append("transaction_id", transaction_id);
                                            formData.append("image_json_url", image_json_url);
                                            formData.append("item_id", item_id);

                                            var creat_mint = await axios.post(`${BASE_URL}/single_mint`, formData)

                                            var mint_id = creat_mint.data.result.create_mint_id;
                                            console.log("single_mintsingle_mintfixxed", creat_mint.status)

                                            if (creat_mint.status == 200) {
                                                var ethBalance = await (await web3_Stake.eth.getBalance(loginUserAdd))
                                                ethBalance = Web3.utils.fromWei(ethBalance, 'ether').slice(0, 6);
                                                sessionStorage.setItem("ethBalance", ethBalance);

                                                toast.success('NFT mint Successfully !', {
                                                    position: "top-center",
                                                    theme: "colored",
                                                    autoClose: 1000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                });
                                                document.getElementById("overlay").style.display = "none";
                                                setTimeout(() => {
                                                    navigate(`/prod_detail?id=${mint_id}`)
                                                }, 2000)
                                            } else {
                                                console.log('eror ');
                                            }
                                        }).catch((err) => {
                                            console.log("err", err)
                                        })
                                }).catch((err) => {
                                    console.log("err", err)
                                })
                        }).catch((err) => {
                            console.log("err", err)
                            toast.error('Your mint nft failed !', {
                                position: "top-center",
                                theme: "colored",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            document.getElementById("overlay").style.display = "none";
                            setTimeout(() => {
                                // navigate(`/prod_detail?id=${nftInfo.id}`)
                                window.location.reload()
                            }, 2000)

                        })
                }
            } else if (mint_type == 2) {
                var tokens = Web3.utils.toWei(price.toString(), 'ether')
                var bntokens = Web3.utils.toBN(tokens)

                console.log("tokenstokens", tokens)
                console.log("bntokensbntokens", bntokens)
                // setLoading(true)
                document.getElementById("overlay").style.display = "block";

                const formData = new FormData();
                formData.append("image", image, image.name);
                formData.append("name", name);
                formData.append("description", description);
                formData.append("type", type);

                var create_image_json = await axios.post(`${BASE_URL}/create_image_json`, formData)
                console.log("create_image_json", create_image_json);

                if (create_image_json.status == 200) {
                    var token_uri = create_image_json.data.result.json_url;
                    var image_json_url = create_image_json.data.result.json;

                    var contract_royalty = royalty * 100;
                    ERC721ABiWthiCONTRACT.methods.mintNft(token_uri, loginUserAdd, contract_royalty)
                        .send({
                            from: loginUserAdd,
                            // gas: 3000000,
                        }).on('error', function (error) {
                            console.log("error", error)

                        }).then(async function (info) {
                            console.log('success ', info);
                            var token_id = info.events.Transfer.returnValues.tokenId

                            console.log('token_idtoken_id ', token_id);

                            ERC721ABiWthiCONTRACT.methods.approve(MARKETPLACE_CONTACT_ADDRESS, token_id)
                                .send({
                                    from: loginUserAdd,
                                    // gas: 3000000
                                }).on('error', function (error) {
                                    console.log("error", error)

                                    document.getElementById("overlay").style.display = "none";
                                    setTimeout(() => {
                                        window.location.reload()
                                    }, 5000)


                                }).then(async function (info) {
                                    console.log('success ', info);

                                    MarketplaceABiWthiCONTRACT.methods.createTokenAuction721(ERC721_CONTACT_ADDRESS,token_id, bntokens, aucation_timestamp)
                                        .send({
                                            from: loginUserAdd,
                                            // gas: 3000000,
                                            // value: platformFees_bn
                                        }).on('error', function (error) {
                                            console.log("error", error)

                                            document.getElementById("overlay").style.display = "none";

                                        }).then(async function (info) {

                                            // alert(auction_date)

                                            console.log('success ', info);
                                            var transaction_id = info.transactionHash
                                            // nftdata.tokenAddress = info.events.Transfer.address //contract_address
                                            formData.append("image", image, image.name);
                                            formData.append("preview_image", previewImage);
                                            formData.append("name", name);
                                            formData.append("description", description);
                                            formData.append("collection_id", collectionId);
                                            formData.append("chain", 'ETH');
                                            formData.append("category", category);
                                            formData.append("royalty", royalty);
                                            formData.append("price", price);
                                            formData.append("user_id", loginUserAdd);
                                            formData.append("mint_type", mint_type);
                                            formData.append("auction_date", auction_date);
                                            formData.append("token_id", token_id);
                                            formData.append("transaction_id", transaction_id);
                                            formData.append("image_json_url", image_json_url);

                                            var creat_mint = await axios.post(`${BASE_URL}/single_mint`, formData)

                                            var mint_id = creat_mint.data.result.create_mint_id;
                                            console.log("dataaaa", creat_mint.status)

                                            if (creat_mint.status == 200) {
                                                toast.success('NFT mint Successfully !', {
                                                    position: "top-center",
                                                    theme: "colored",
                                                    autoClose: 2000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                });
                                                // setLoading(false)
                                                document.getElementById("overlay").style.display = "none";
                                                setTimeout(() => {
                                                    navigate(`/prod_detail?id=${mint_id}`)
                                                }, 5000)
                                            }
                                        }).catch((err) => {
                                            console.log("err", err)
                                        })
                                }).catch((err) => {
                                    console.log("err", err)
                                })
                        }).catch((err) => {
                            console.log("err", err)
                            toast.error('Your mint nft failed !', {
                                position: "top-center",
                                theme: "colored",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            document.getElementById("overlay").style.display = "none";
                            setTimeout(() => {

                                window.location.reload()
                            }, 5000)

                        })
                }
            }

        }

    }


    /*Unlock Purchase */
    const [showPurched, setShowPurched] = useState(false)
    const showPurchedBox = () => {
        setShowPurched(!showPurched)
    }

    /*Market place */

    const [showMarketPlace, setShowMarketPlace] = useState(false)
    const showMarketPlaceBox = () => {
        setShowMarketPlace(!showMarketPlace)
        console.log("showMarketPlace", showMarketPlace)

    }


    const showPriceinput = () => {
        var fixed = $('#fixedValid').val();
        setfixedDiv(fixed)


        setPriceInput(true)
        setIsActivePrice(true)
        setAuctionInput(false)
        setIsActiveAuction(false)
        setMintType(1);
    }

    const showAuctioninput = () => {
        var auction = $('#auctionValid').val();
        setAuctionDiv(auction)
        setAuctionInput(true)
        setIsActiveAuction(true)
        setPriceInput(false)
        setIsActivePrice(false)
        setMintType(2)
    }

    const closeImg = () => {
        setImageprev("")
        setImageType("")
    }

    const closeAudioImg = () => {
        setAudioPreviewImage("")
        setPreviewImageType("")
    }

    const closeAudio = () => {
        setImageprev("");
        setImageType("")
        if (imageprev) {
            document.getElementById("audioImg_div").style.display = "block"
        } else {
            document.getElementById("audioImg_div").style.display = "none"
        }

    }

    const imageValidate = (e) => {

        setImageType(e.target.files[0]);
        setImageprev(URL.createObjectURL(e.target.files[0]));
        console.log("matchss", e.target.files[0].name)
        console.log("matchsgfds", e.target.files[0].type)
        console.log("matchsize", e.target.files[0].size)

        const fsize = e.target.files[0].size;
        const fileSize = Math.round((fsize / 1024));

        console.log("file sizwe", fileSize)

        if (e.target.files[0].name) {

            if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|gif|mp3|mp4|glb|svg|JPEG|PNG|JPG|GIF|MP3|MP4)$/)) {

                console.log("not match")
                console.log("Us", e.target.files[0].name)
                e.target.value = null;
                closeImg()
                $("#uploadImage").hide();
                $('#uploadImageType').show();
                $('#checkVideoValidation').hide();
            } else {

                if (fileSize > 102400) {
                    console.log("file size large")
                    e.target.value = null;
                    closeImg()
                    $("#uploadImage").hide();
                    $('#uploadImageType').hide();
                    $('#checkVideoValidation').show();
                }
            }
        }
    }

    const imagePreviewValidate = (e) => {

        setPreviewImageType(e.target.files[0]);
        setAudioPreviewImage(URL.createObjectURL(e.target.files[0]));

        console.log("audio preview image", e.target.files[0].name)

        if (e.target.files[0].name) {

            if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|gif|JPEG|PNG|JPG|GIF)$/)) {
                console.log("not preview match1")
                console.log("Us1", e.target.files[0].name)
                e.target.value = null;
                setAudioPreviewImage("")
                setPreviewImageType("")
                $("#uploadPreviewImage1").hide();
                $('#uploadPreviewImageType1').show();

            }
        }
    }


    return (
        <div>
            <div id="overlay">

                <div className='loader_spiner'>
                    <ImSpinner7 />
                    <div className="loader_text">
                        <strong>Please wait while complete your processing...</strong>
                    </div>
                </div>
            </div>
            <Header />
            <section className='create_post_section'>
                <Container>


                    <Row className='justify-content-center'>
                        <Col lg={10} className="create_item_div">

                            <Row>
                                <Col lg={12} className='Heading-Txt'>
                                    <Link to='/Create' className='GoBack_btn fw-bold'><FaArrowLeft className='back-icon' />  Go Back</Link>


                                </Col>
                            </Row>
                            <Row className='justify-content-center'>
                                <Col lg={6} md={6}>
                                    <h2 className="fw-bold mb-3 mt-3">Create New Item</h2>
                                    <h6>Upload Image,Video, Audio, GIF or 3D Model <span className='required-text'>*</span></h6>
                                    <div className='mt-1' style={{ wordBreak: "break-all", fontSize: "14px" }}>File type supported:JPG, JPEG, PNG, GIF, GLB, SVG, MP3, MP4. Max size: 100MB</div>
                                </Col>
                            </Row>
                            <Row className='justify-content-center'>

                                <Col lg={4} md={6}>


                                    <div className='card p-4 mt-3 text-center' style={{ border: "2px dashed" }}>

                                        {imageprev ?
                                            (image.type == "video/mp4") ?
                                                <div className="img_preview">
                                                    <video width="280" height="250" controls style={{ marginTop: "5px", backgroundColor: "#000" }}>
                                                        <source src={imageprev} type="video/mp4" />
                                                    </video>
                                                    <div className="img_close">
                                                        <button className='img_close_btn' onClick={closeImg}>X</button>
                                                    </div>
                                                </div>
                                                :
                                                (image.type == "audio/mpeg") ?
                                                    <div className="img_preview">
                                                        <audio controls={true} style={{ marginLeft: "-7px", padding: "3px" }}>
                                                            <source src={imageprev} type="audio/mpeg" />
                                                        </audio>

                                                        <div className="img_close">
                                                            <button className='img_close_btn1' onClick={closeAudio}>X</button>
                                                        </div>
                                                    </div>
                                                    :
                                                    (image.type == "image/jpg" || image.type == "image/png" || image.type == "image/gif" || image.type == "image/jpeg" || image.type == "image/svg+xml") ?
                                                        <div className="img_preview">
                                                            <img src={imageprev} alt="profile_pic" className='img-fluid ' />
                                                            <div className="img_close">
                                                                <button className='img_close_btn' onClick={closeImg}>X</button>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="img_preview">
                                                            <div className='threeD_custom_product'>
                                                                <model-viewer src={imageprev} alt="VR Headset" auto-rotate camera-controls ar ios-src={imageprev}>
                                                                </model-viewer>
                                                            </div>

                                                            <div className="img_close">
                                                                <button className='img_close_btn' onClick={closeImg}>X</button>
                                                            </div>
                                                        </div>
                                            :
                                            <div className="card-body customCardBody" style={{ height: "220px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <FaImages className='fs-1 mb-3' />

                                                <Form.Control type="file" className="uploadPostImage" onChange={imageValidate} />
                                            </div>
                                        }
                                    </div>
                                    <p className='required-text' id="uploadImage">Please Upload image </p>
                                    <p className='required-text' id="uploadImageType">Invalid File Type </p>
                                    <p className='required-text' id="checkVideoValidation">File size should be minimum 100MB </p>
                                </Col>


                            </Row>
                            {
                                (image.type == "audio/mpeg") ?
                                    <div id="audioImg_div">
                                        <Row className='justify-content-center mt-3'>
                                            <Col lg={6}>
                                                <strong>Preview image</strong>
                                                <p style={{ color: "gray", fontWeight: "500" }}>Because you’ve included multimedia, you’ll need to provide an image (PNG, JPG, or GIF) for the card display of your item.</p>
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <Col lg={4} md={6} >

                                                <div className='card p-4 mt-3 text-center' style={{ border: "2px dashed" }}>
                                                    {audioPreviewImage ?
                                                        <div className="img_preview">
                                                            <img src={audioPreviewImage} alt="profile_pic" className='img-fluid ' />
                                                            <div className="img_close">
                                                                <button className='img_close_btn' onClick={closeAudioImg}>X</button>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="card-body customCardBody" style={{ height: "220px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <FaImages className='fs-1 mb-3' />


                                                            <Form.Control type="file" className="uploadPostImage" onChange={imagePreviewValidate} accept="image/*" />


                                                        </div>
                                                    }

                                                </div>
                                                <p className='required-text' id="uploadPreviewImage1">Please Upload Preview image </p>
                                                <p className='required-text' id="uploadPreviewImageType1">Invalid File Type </p>
                                            </Col>

                                        </Row>
                                    </div>

                                    :

                                    ""
                            }

                            <Row className='justify-content-center'>

                                <Col lg={6}>
                                    <div className='form-section'>

                                        <Form.Group className="mt-4 mb-3" controlId="formBasicEmail">
                                            <Form.Label>Name <span className='required-text'>*</span></Form.Label>
                                            <Form.Control type="text" placeholder="Item Name" onChange={(e) => setName(e.target.value)} />

                                            <p className='required-text' id="namecheck">Please Enter NFT Name </p>

                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Description <span className='required-text'>*</span></Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Provide a detailed description of your item."
                                                style={{ height: '100px' }}
                                                onChange={(e) => setDescription(e.target.value)}
                                            />

                                            <p className='required-text' id="descriptioncheck">Please Enter NFT Description </p>

                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Blockchain</Form.Label>

                                            <Form.Select aria-label="Floating label select example" disabled>
                                                <option value="" >Select Chain</option>
                                                <option value="ETH" selected>Ethereum</option>
                                                <option value="BNB">Binance</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Category <span className='required-text'>*</span></Form.Label>
                                            <Form.Select aria-label="Floating label select example"
                                                onChange={(e) => setCategory(e.target.value)}
                                            >
                                                <option value="" selected>Select Category</option>
                                                <option value="artwork">Artwork</option>
                                                <option value="photography">Photography</option>
                                                <option value="audio">Audio</option>
                                                <option value="video">Video</option>
                                                <option value="collectibles">Collectibles</option>
                                            </Form.Select>
                                            <p className='required-text' id="categorycheck">Please Select Category </p>
                                        </Form.Group>

                                        <Form.Group className="mb-3 royalty_field" controlId="formBasicEmail">
                                            <Form.Label>Royalty (%) <span className='required-text'>*</span></Form.Label>
                                            <Form.Select aria-label="Floating label select example" className='validate'
                                                onChange={(e) => setRoyalty(e.target.value)}
                                            >
                                                <option value="" selected>Select Royalty (%)</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </Form.Select>


                                            <p className='required-text' id="royltycheck">Please Enter NFT Royalty </p>



                                        </Form.Group>

                                        <Form.Group className="mb-3 collection_field" controlId="formBasicEmail">
                                            <Form.Label>Collection<span className='required-text'>*</span></Form.Label>

                                            <div className="custom_dropdown" onClick={handleSearch}>
                                                <img src="" alt="" id='collection_img_id' className='img-fluid' />
                                                <Form.Control type="text"  placeholder="Select Collections" value={searchCollection} onChange={handleCollectionChange} />
                                                <div className="down_arrow"><BsChevronDown stroke-width="1" style={{ padding: "2px" }} /></div>
                                            </div>

                                            {
                                                showSearch ?
                                                    <div className='search_collection_div' id='collection_search'>
                                                        {
                                                            (getAllCollection.length != 0) ? getAllCollection.filter((val) => {
                                                                if (searchCollection === "") {
                                                                    return val
                                                                } else if (val.name.toLocaleLowerCase().includes(searchCollection.toLocaleLowerCase())) {
                                                                    return val
                                                                }
                                                            }).map((e) => {

                                                                return (
                                                                    <div className="search_collection_data" key={e.id} onClick={() => handleSelectItem(e.name, e.logo, e.id)}>
                                                                        <div className="collection_img">
                                                                            <img src={e.logo} alt="collection_img" className='img-fluid' />
                                                                        </div>
                                                                        <div className="collection_name">{e.name}</div>
                                                                    </div>
                                                                )
                                                            })
                                                                :
                                                                <h5 className='text-center py-4' style={{ color: "#9f92fb" }}>No Results Found</h5>
                                                        }
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            {
                                                showSearch ?
                                                    <div className='search_collection_div' id='collection_search' style={toggleSearchBox ? {display:"none"}: {display : 'block'}}>
                                                        {
                                                            (getAllCollection.length != 0) ? getAllCollection.map((e) => {
                                                                return (
                                                                    <div className="search_collection_data" key={e.id} onClick={() => handleSelectItem(e.name, e.logo, e.id)}>
                                                                        <div className="collection_img">
                                                                            <img src={e.logo} alt="collection_img" className='img-fluid' />
                                                                        </div>
                                                                        <div className="collection_name">{e.name}</div>
                                                                    </div>
                                                                )
                                                            })
                                                                :
                                                                <h5 className='text-center py-4' style={{ color: "#9f92fb" }}>No Results Found</h5>
                                                        }
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <p className='required-text' id="validCollection">Please Select Collection </p>
                                        </Form.Group>
{/* 
                                        <Form.Group className="mb-3 collection_field" controlId="formBasicEmail">
                                            <Form.Label>Collection<span className='required-text'>*</span></Form.Label>
                                           <div className="custom_dropdown" onClick={handleSearch}>
                                           <Form.Control type="text"  placeholder="Select Collections"  value={searchCollection} onChange={(e) => setSearchCollection(e.target.value)}  />
                                            <div className="down_arrow"><BsChevronDown stroke-width="1" style={{ padding:"2px"}} /></div>
                                           </div>
                                            {
                                                showSearch ? 
                                                <div className='search_collection_div' id='collection_search'>
                                                {
                                                   getAllCollection ? getAllCollection.filter((val) => {
                                                        if (searchCollection === "") {
                                                            return val
                                                        } else if (val.name.toLocaleLowerCase().includes(searchCollection.toLocaleLowerCase())) {
                                                            return val
                                                        }
                                                    }).map((e, i) => {
                                                        return (
                                                            <div className="search_collection_data" key={e.id} onClick={()=>handleSelectItem(e.name)}>
                                                                <div className="collection_img">
                                                                    <img src={e.logo} alt="collection_img" className='img-fluid' />
                                                                </div>
                                                                <div className="collection_name">{e.name}</div>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <h5 className='text-center py-4' style={{ color: "#9f92fb" }}>No Results Found</h5>
                                                }
                                            </div>
                                            :
                                            <div></div>
                                            }
                                           
                                            <p className='required-text' id="validCollection">Please Select Collection </p>
                                        </Form.Group> */}


                                        <div className='d-flex justify-content-between mb-4'>
                                            <div className='me-4 marketplace'>
                                                <div className={isActivePrice ? ' text-center active_card' : ' text-center'} onClick={showPriceinput}>
                                                    <input type="hidden" value="fixed" id="fixedValid" />
                                                    <img src={fixedPrice} alt="single" className='img-fluid w-50' />
                                                    <div><strong>Fixed price</strong></div>

                                                </div>

                                            </div>

                                            <div className='me-4 marketplace'>
                                                <div className={isActiveAuction ? ' text-center active_card' : ' text-center'} onClick={showAuctioninput}>
                                                    <input type="hidden" value="auction" id="auctionValid" />
                                                    <img src={limitedAuction} alt="single" className='img-fluid w-50' />
                                                    <div><strong>Limited-auction</strong></div>
                                                </div>
                                            </div>

                                        </div>
                                        <p className='required-text' id="fixedAuctionValid">Please Select Any One </p>


                                        {priceInput ?
                                            <div className='mb-3 price_field'>
                                                <Form.Label>Price</Form.Label>
                                                <Form.Control type="hidden" placeholder="" name="mint_type" value={mint_type} />
                                                <Form.Control className='validate' type="number" step=".01" placeholder="0.00 ETH" onChange={(e) => setPrice(e.target.value)} />
                                                <p className='required-text' id="pricecheckFixed">Please Enter Price </p>
                                                <p className='required-text' id="priceValidation">Price should be greater than zero</p>
                                            </div>
                                            :
                                            <div></div>
                                        }

                                        {auctionInput ?
                                            <div>
                                                <Form.Control type="hidden" placeholder="" name="mint_type" value={mint_type} />
                                                <div className='mb-3 price_field'>
                                                    <Form.Label>Duration</Form.Label>
                                                    <DatePicker
                                                        selected={currentDate ? currentDate : ""}
                                                        onChange={handleChange}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={20}
                                                        timeCaption="time"
                                                        dateFormat="dd MMMM, yyyy h:mm aa"
                                                        locale="en"
                                                        minDate={new Date()}
                                                        placeholderText={'01 Jan 1970 10:00 AM'}
                                                    />

                                                    <p className='required-text' id="auctionDateAndTime">Please Choose Duration </p>
                                                    <p className='required-text' id="timeValidation">You can not choose time less than the current time </p>
                                                </div>

                                                <div className='mb-3 price_field'>
                                                    <Form.Label>Price</Form.Label>
                                                    <Form.Control className='validate' type="number" step=".01" placeholder="0.00 Bharat Token" onChange={(e) => setPrice(e.target.value)} />
                                                    <p className='required-text' id="pricecheckFixed">Please Enter Price </p>
                                                    <p className='required-text' id="priceValidation">Price should be greater than zero</p>
                                                </div>
                                            </div>
                                            :
                                            <div></div>
                                        }

                                        <div className='fees_div'>
                                            <div className="fees_left">
                                                <h5>Fees</h5>
                                                <p>Service Fee</p>
                                            </div>
                                            <div className="fees_right">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="button-tooltip-2" className=''>Listing is free. Once sold, the following fees will be deducted.</Tooltip>}
                                                >
                                                    <Button variant="success"><AiOutlineExclamationCircle /></Button>
                                                </OverlayTrigger>

                                                <p>2%</p>
                                            </div>
                                        </div>
                                        <div className="create_btn">
                                            <button type="submit" className=' py-2 px-4 create_post_btn' onClick={createnft}>Create</button>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                </Container>
            </section>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Footer />
        </div>
    )
}

export default CreatePost