import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import ProductCard from './ProductCard'
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { ImSpinner7 } from 'react-icons/im'

const BASE_URL = process.env.REACT_APP_BASE_URL


function TopCreators() {
     const navigate = useNavigate()
     const [topUsers, setTopUsers] = useState("")
     const [load, setLoading] = useState(true)

     async function topCreators() {
          var creators = await axios.get(`${BASE_URL}/getAllTopCreator`)
          if (creators.data.result) {
               setTopUsers(creators.data.result)
               setLoading(false)
          }else{
               console.log("loader2")
                    setTimeout(()=>{
                        setLoading(false) 
                    },1000)
          }

     }

     useEffect(() => {
          setTimeout(()=>{
               setLoading(false) 
           },1000)
          topCreators()
     }, [])

     const handleAllUsers = () => {
          // setFilteredData(NftExplore)
          navigate('/all_users')
     }

     return (
          <section className='topcreators'>
               <Container>
                    <Row>
                         <Col sm={3}>
                              <h4 className='collection-title'>Top Creators</h4>
                         </Col>
                         <Col sm={9} className='d-flex justify-content-end align-items-center'>
                              <div>
                                   <Button size="sm" variant="outline-primary" className='collection-btn me-2 trending_btn' onClick={handleAllUsers}>All Creators</Button>
                              </div>
                         </Col>
                    </Row>

                    <Row>
                         {
                              load ?
                                   <div className='loader_spiner_div  '>
                                        {/* <ImSpinner7 className='spin_icon' /> */}
                                   </div>
                                   :
                                   (topUsers.length === 0) ?
                                        <Row className='mt-4'>
                                             <Col lg={12} md={12}>
                                                  <div className='filter_data_card text-center py-5'>
                                                       <p>No Creator's Available</p>
                                                  </div>
                                             </Col>
                                        </Row>
                                        :

                                        topUsers.map((e, index) => {
                                             var link = `/User_profile?id=${e.id}`
                                             var name = e.userFullName
                                             var image = e.image
                                             var username = e.username
                                             var bio = e.bio
                                             var nft_image = e.nft_image

                                             if (index < 8) {
                                                  return (
                                                       <Col lg={4} md={6} key={e.id}>
                                                            <Card className='mt-4 creators_wrap' >
                                                                 <Link to={link}>
                                                                      <div className="nft_img_div">
                                                                           <Card.Img src={nft_image} />
                                                                      </div>
                                                                      <div>
                                                                           <Card.Body>
                                                                                <div className="user_img">
                                                                                     <img src={image} alt="user_img" className='img-fluid' />
                                                                                </div>
                                                                                <div className='user-section'>
                                                                                     <h6>{username}</h6>
                                                                                     <p>{bio == "null" ? "" : bio}</p>
                                                                                </div>
                                                                           </Card.Body>
                                                                      </div>
                                                                 </Link>
                                                            </Card>
                                                       </Col>
                                                  )
                                             }

                                        })
                         }
                    </Row>
               </Container>
          </section>
     )
}

export default TopCreators