import React, { useState, useRef } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ethereum from '../assets/img/ether.svg'
import bnb from '../assets/img/bnb.png'
import bharatToken from '../assets/img/bharatToken(1).png'
import { BsFillPlayFill } from 'react-icons/bs';
import { AiOutlinePause } from 'react-icons/ai';
import Timer1 from './Timer1';
import Moment from 'moment';


function ProductCard(props) {
    const [audioStatus, changeAudioStatus] = useState(false);
    const myRef = useRef();


    var link = `/prod_detail?id=${props.id}`
    var name = props.name
    var image = props.image
    var price = props.price
    var category = props.category
    var auction_left_date = props.auction_left_date
    var mint_type = props.mint_type
    var chain = props.chain
    var create_mint_type = props.create_mint_type
    var aucation_date = props.aucation_date

    var e_fileName = props.image;
    var e_fileExtension = e_fileName.split(/[#?]/)[0].split('.').pop().trim();

    const startAudio = () => {
        myRef.current.play();

        changeAudioStatus(true);
    };

    const pauseAudio = () => {
        myRef.current.pause();
        changeAudioStatus(false);
    };

    // =========timer section start========
    function toTimestamp(strDate) {
        var datum = Date.parse(strDate);
        return datum / 1000;
    }

    const currentDate_time = new Date()

    const [currentDateTime, setCurrentDateTime] = useState(currentDate_time)


    const new_auction_date = Moment(currentDateTime).format('DD MMMM YYYY h:mm A')
    const new_aucation_timestamp = toTimestamp(new_auction_date)


    function toTimestamp(strDate) {
        var datum = Date.parse(strDate);
        return datum / 1000;
    }

    const auction_date = Moment(aucation_date).format('MMMM DD YYYY h:mm A')


    // =========timer section end========


    return (
        <>
           
            <Card className='mt-4'>
                <div className='product_audio_btn_wrap'>
                    {audioStatus ? (
                        <button onClick={pauseAudio} className={e_fileExtension == 'mp3' ? "product_audio_btn_show" : "product_audio_btn_hide"} ><AiOutlinePause /></button>
                    ) : (
                        <button onClick={startAudio} className={e_fileExtension == 'mp3' ? "product_audio_btn_show" : "product_audio_btn_hide"}><BsFillPlayFill style={{ marginLeft: "4px", marginTop: "-5px" }} /></button>
                    )}

                </div>

                <Link to={link}>
                    <div className="nft_img_div">
                       
                        {(() => {
                            if (e_fileExtension == 'mp3') {
                                return (

                                    <div className='product_audio_wrap'>
                                        <img src={props.preview_image} alt="featured" style={{ height: "235px", width: "100%", borderRadius: "7px", objectFit:"fill" }} />
                                        <audio ref={myRef} controls controlsList="nodownload noplaybackrate" style={{ width: "280px" }}>
                                            <source src={image} type="audio/ogg"></source>
                                            <source src={image} type="audio/mpeg"></source>
                                            Your browser does not support the audio element.
                                        </audio>
                                    </div>

                                )
                            } else if (e_fileExtension == 'mp4') {
                                return (
                                    <video controls className='img-fluid' style={{ height: "100%", width: "100%", backgroundColor: "#000", borderRadius: "7px" }} controlsList="nodownload" oncontextmenu="return false;">
                                        <source src={image} type="video/mp4" ></source>
                                        Your browser does not support the audio element.
                                    </video>
                                )
                            }
                            else {
                                return e_fileExtension == "png" ||
                                    e_fileExtension == "jpg" ||
                                    e_fileExtension == "gif" ||
                                    e_fileExtension == "jpeg" ||
                                    e_fileExtension == "svg" ? 
                                    (
                                    <Card.Img src={image} />
                                ) : (
                                    <div className='threeD_custom_product'>
                                        <model-viewer src={image} alt="VR Headset" auto-rotate camera-controls ar ios-src={image}>
                                        </model-viewer>
                                    </div>
                                );
                            }
                        })()}
                    </div>

                    <div>
                        <Card.Body>
                            <div className="nft_main_div">
                                <div className="nft_left_div">
                                    <div className="category_name">{category}</div>
                                    <div className="nft_name"><h6>{name}</h6></div>
                                    <div className='erc_div mt-2'>{create_mint_type}</div>
                                </div>
                                <div className="nft_right_div">
                                    <div className="price_text">Price</div>
                                    <div className="nft_price">
                                        {
                                            (() => {
                                                if (mint_type === "2") {
                                                    return (
                                                        <img src={bharatToken} alt="img" className='img-fluid me-1' />
                                                    )
                                                }else{
                                                    if (chain === "BNB") {

                                                        return (
                                                            <img src={bnb} alt="img" className='img-fluid me-1' />
                                                        )
                                                    } else {
                                                        return (
                                                            <img src={ethereum} alt="img" className='img-fluid me-1' />
                                                        )
                                                    }
                                                }
                                                
                                            })()
                                        }
                                        <strong>{price}</strong><br></br>
                                    </div>
                                    <div className="nft_auction_time">
                                        {
                                            (() => {
                                                if (mint_type === "2" && auction_left_date != "" && auction_left_date != " ") {
                                                    return (
                                                        <Timer1 currentTime={auction_date} />
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })()
                                        }
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </div>
                </Link>
            </Card>
         
        </>


    )
}

export default ProductCard